import { IconButton, Snackbar as SnackbarMaterial } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { FC, memo } from 'react';

type ISnackbarProps = {
  show: boolean;
  duration?: number;
  text: string | null;
  onClose: () => void;
};

const Snackbar: FC<ISnackbarProps> = ({ show, duration = 4000, text, onClose }): JSX.Element => {
  const handleCloseSnackbar = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    onClose();
  };

  return (
    <>
      {text !== null ? (
        <SnackbarMaterial
          open={show}
          autoHideDuration={duration}
          onClose={handleCloseSnackbar}
          message={text}
          action={
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleCloseSnackbar}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      ) : null}
    </>
  );
};

export default memo(Snackbar);
