import axios from 'axios';
import { OpenVidu, StreamManager } from 'openvidu-browser';
import { useEffect } from 'react';
import { ParticipantKind, ParticipantType } from '../../../../enum/participant-type.enum';
import { Participant, ParticipantData } from '../../../models/openvidu/participant.model';
import {
  RecordingListResponse,
  RecordingResponse,
  StartRecordingRequest
} from '../../../models/openvidu/recording.model';
import { useConfig } from '../../provider/ConfigProvider';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../../../../store/store';
import { selectParticipant } from '../../../../../store/participant/participantSelectors';
import { useOpenviduLocal } from '../../provider/openvidu/OpenviduLocalProvider';
import { setParticipant } from '../../../../../store/participant/participantReducer';
import _ from 'lodash';
export interface IOpenviduConnection {
  getToken: (room: string) => Promise<string>;
  getFirstDevice: (OV: OpenVidu) => Promise<string | undefined>;
  createParticipant: (manager: StreamManager, kind?: ParticipantKind) => Participant;
  startRecording: (body: StartRecordingRequest) => Promise<Readonly<RecordingResponse>>;
  stopRecording: (recordingId: string) => Promise<Readonly<RecordingResponse>>;
  getSessionRecording: (recordingId: string) => Promise<Readonly<RecordingResponse>>;
  getRecordingsOfSession: (sessionId: string) => Promise<Readonly<RecordingListResponse>>;
}

/**
 * Provide Openvidu connections
 * @returns Helpers to handle Openvidu Connection
 */
export function useProvideOpenviduConnection(): IOpenviduConnection {
  const { openvidu } = useConfig();
  const listParticipants = useAppSelector(selectParticipant);
  const { participants } = useOpenviduLocal();
  const dispatch: AppDispatch = useAppDispatch();
  /**
   * Start requested recording
   * @param body Body of recording
   * @returns Recording response
   */
  const startRecording = async (
    body: StartRecordingRequest
  ): Promise<Readonly<RecordingResponse>> => {
    try {
      const res = await axios.post<Readonly<RecordingResponse>>(
        `${openvidu.url}/openvidu/api/recordings/start`,
        body,
        {
          headers: {
            Authorization: 'Basic ' + btoa(`OPENVIDUAPP:${openvidu.secret}`),
            'Content-Type': 'application/json'
          }
        }
      );
      return res.data;
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Get all recording of session
   * @param sessionId Session of stream
   * @returns Recording response
   */
  const getRecordingsOfSession = async (
    sessionId: string
  ): Promise<Readonly<RecordingListResponse>> => {
    try {
      const res = await axios.get<Readonly<RecordingListResponse>>(
        `${openvidu.url}/openvidu/api/recordings/`,
        {
          headers: {
            Authorization: 'Basic ' + btoa(`OPENVIDUAPP:${openvidu.secret}`),
            'Content-Type': 'application/json'
          }
        }
      );
      return {
        ...res.data,
        items: [...res.data.items].filter(
          (i) => i.sessionId === sessionId && (i.status === 'started' || i.status === 'starting')
        )
      };
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Get recording info
   * @param recordingId Recording id
   * @returns Recording response
   */
  const getSessionRecording = async (recordingId: string): Promise<Readonly<RecordingResponse>> => {
    try {
      const res = await axios.post<Readonly<RecordingResponse>>(
        `${openvidu.url}/openvidu/api/recordings/${recordingId}`,
        {},
        {
          headers: {
            Authorization: 'Basic ' + btoa(`OPENVIDUAPP:${openvidu.secret}`),
            'Content-Type': 'application/json'
          }
        }
      );
      return res.data;
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Stop recording
   * @param recordingId Recording id
   * @returns Recording response
   */
  const stopRecording = async (recordingId: string): Promise<Readonly<RecordingResponse>> => {
    try {
      const res = await axios.post<Readonly<RecordingResponse>>(
        `${openvidu.url}/openvidu/api/recordings/stop/${recordingId}`,
        {},
        {
          headers: {
            Authorization: 'Basic ' + btoa(`OPENVIDUAPP:${openvidu.secret}`),
            'Content-Type': 'application/json'
          }
        }
      );
      return res.data;
    } catch (e) {
      console.error(e);
    }
  };

  /**
   * Create a new session on Openvidu
   * @param sessionId CustomSessionId
   * @returns Id of session
   */
  const createSession = async (sessionId: string): Promise<string | null> => {
    const data = JSON.stringify({ customSessionId: sessionId });

    try {
      const res = await axios.post(`${openvidu.url}/openvidu/api/sessions`, data, {
        headers: {
          Authorization: 'Basic ' + btoa(`OPENVIDUAPP:${openvidu.secret}`),
          'Content-Type': 'application/json'
        }
      });
      return res.data.id;
    } catch (e) {
      const error = Object.assign({}, e);
      if ((error as { response: { status: number } })?.response?.status === 409) {
        return sessionId;
      } else {
        console.error(error);
        console.warn(
          `No connection to OpenVidu Server. This may be a certificate error at ${openvidu.url}`
        );
        if (
          window.confirm(
            `No connection to OpenVidu Server. This may be a certificate error at ${openvidu.url}
                      \n\nClick OK to navigate and accept it. 
                      If no certificate warning is shown, 
                      then check that your OpenVidu Server is up and running at ${openvidu.url}`
          )
        ) {
          window.location.assign(`${openvidu.url}/accept-certificate`);
        }
      }
    }

    return null;
  };
  /**
   * Obtain remote token
   * @param sessionId Custom session id
   * @returns Token
   */
  const createToken = async (sessionId: string): Promise<string> => {
    try {
      const response = await axios.post(
        `${openvidu.url}/openvidu/api/sessions/${sessionId}/connection`,
        {},
        {
          headers: {
            Authorization: 'Basic ' + btoa(`OPENVIDUAPP:${openvidu.secret}`),
            'Content-Type': 'application/json'
          }
        }
      );
      return response.data.token;
    } catch (e) {
      console.error(e);
      throw Error('Unable to obtain token');
    }
  };

  /**
   * Wrap BL to retrieve remote token
   * @param room Custom session id
   * @returns Remote token
   */
  const getToken = async (room: string): Promise<string> => {
    const sessionId = await createSession(room);

    if (sessionId === null) {
      throw Error('Unable to create session');
    }

    return createToken(sessionId);
  };

  /**
   * Search first available video device
   * @param OV Openvidu instance
   * @returns id of device or undefined
   */
  const getFirstDevice = async (OV: OpenVidu): Promise<string | undefined> => {
    const devices = await OV.getDevices();
    const videoDevices = devices.filter((device) => device.kind === 'videoinput');
    return videoDevices.length > 0 && videoDevices[0].deviceId !== ''
      ? videoDevices[0].deviceId
      : undefined;
  };

  useEffect(() => {
    const test = listParticipantsUnion(listParticipants, participants, 'id')
      .filter((p) => p.kind === ParticipantKind.REMOTE)
      .sort((a, b) => a.name.localeCompare(b.name));
    dispatch(setParticipant(test));
  }, [participants]);

  const listParticipantsUnion = (p, u, id) => {
    const array = [...p, ...u];
    return _.uniqBy(array, id);
  };

  /**
   * Parse participant's info
   * @param data Data to parse
   * @returns Object {@link ParticipantData} with info of participant
   */
  const parseParticipantData = (data: string): ParticipantData => {
    const parsed = JSON.parse(data) as ParticipantData;
    return {
      id: null,
      type: ParticipantType.WEB,
      ...parsed
    };
  };

  /**
   * Create a {@link Participant} object with participant info
   * @param manager Stream manager
   * @param pinned If is pinned or not
   * @param kind Kind of participant
   * @returns Object created
   */
  const createParticipant = (
    manager: StreamManager,
    kind = ParticipantKind.REMOTE
  ): Participant => {
    const { stream } = manager;
    const data = parseParticipantData(stream.connection.data);
    return {
      kind: data.type === ParticipantType.SCREEN ? ParticipantKind.SCREEN : kind,
      id: data.id,
      roleId: data.roleId,
      connectionId: stream.connection.connectionId,
      type: data.type,
      name: data.clientData,
      projectId: data.projectId,
      manager,
      status: {
        localAudio: true
      }
    };
  };

  return {
    getToken,
    getFirstDevice,
    createParticipant,
    startRecording,
    stopRecording,
    getRecordingsOfSession,
    getSessionRecording
  };
}
