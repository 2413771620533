import FullscreenIcon from '@mui/icons-material/Fullscreen';
import PictureInPictureAltIcon from '@mui/icons-material/PictureInPictureAlt';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import React, { FC, useEffect, useState } from 'react';
import { IButtonElement } from '../../../../shared/core/models/props/button-element';
import CircleIconButton from '../../../../shared/ui/CircleIconButton/CircleIconButton';
import CameraFlipIcon from '../../../../shared/ui/Icons/CameraFlipIcon';
import './ParticipantControlsRemote.scss';

import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import SpeedDial from '@mui/material/SpeedDial';
import SpeedDialAction from '@mui/material/SpeedDialAction';
import SpeedDialIcon from '@mui/material/SpeedDialIcon';
import { ParticipantKind, ParticipantType } from '../../../../shared/enum/participant-type.enum';
import { useTranslation } from 'react-i18next';

type IParticipantControlsRemoteProps = {
  audio: boolean;
  miniature: boolean;
  hasPip: boolean;
  kind: ParticipantKind;
  type: ParticipantType;
  toggleRemoteMicrophone: () => void;
  toggleCamera: () => void;
  fullScreen: () => void;
  pictureInPicture: () => void;
  detectObject: () => void;
  attachFile: () => void;
};

const ACTIVE_PIP: IButtonElement = {
  icon: <PictureInPictureAltIcon />,
  description: 'close_picture',
  buttonType: 'dark'
};

const DISACTIVE_PIP: IButtonElement = {
  icon: <PictureInPictureAltIcon />,
  description: 'picture',
  buttonType: 'primary'
};

const ACTIVE_MIC: IButtonElement = {
  icon: <VolumeUpIcon />,
  description: 'mute',
  buttonType: 'primary'
};

const DISACTIVE_MIC: IButtonElement = {
  icon: <VolumeOffIcon />,
  description: 'unmute',
  buttonType: 'alert'
};

const ParticipantControlsRemote: FC<IParticipantControlsRemoteProps> = ({
  toggleRemoteMicrophone,
  toggleCamera,
  fullScreen,
  pictureInPicture,
  detectObject,
  attachFile,
  miniature,
  hasPip,
  audio,
  kind,
  type
}): JSX.Element => {
  const [pipElement, setPipElement] = useState<IButtonElement>(DISACTIVE_PIP);
  const [audioElement, setAudioElement] = useState<IButtonElement>(ACTIVE_MIC);
  const { t } = useTranslation();

  useEffect(() => {
    if (hasPip) {
      setPipElement(ACTIVE_PIP);
    } else {
      setPipElement(DISACTIVE_PIP);
    }
  }, [hasPip]);

  useEffect(() => {
    if (audio) {
      setAudioElement(ACTIVE_MIC);
    } else {
      setAudioElement(DISACTIVE_MIC);
    }
  }, [audio]);

  return (
    <div className="controls-remote__container">
      {miniature && (
        <SpeedDial
          ariaLabel="Action menu"
          className="controls-remote__fab"
          icon={<SpeedDialIcon openIcon={<MoreHorizIcon />} icon={<MoreVertIcon />} />}>
          <SpeedDialAction
            icon={<ImageSearchIcon />}
            tooltipTitle={t('PARTICIPANT_LIST:object_detection')}
            tooltipPlacement="right"
            onClick={detectObject}
          />
          {kind === ParticipantKind.REMOTE && (
            <SpeedDialAction
              icon={<AttachFileIcon />}
              tooltipTitle={t('PARTICIPANT_LIST:attach_file')}
              tooltipPlacement="right"
              onClick={attachFile}
            />
          )}
        </SpeedDial>
      )}
      <div className="controls-remote__container--grow"></div>
      {kind === ParticipantKind.REMOTE && (
        <>
          <CircleIconButton
            icon={audioElement.icon}
            type={audioElement.buttonType}
            description={t(`PARTICIPANT_LIST:${audioElement.description}`)}
            onClick={toggleRemoteMicrophone}
          />
          {(type === ParticipantType.GLASS || type === ParticipantType.MOBILE) && (
            <CircleIconButton
              icon={<CameraFlipIcon />}
              type="primary"
              description={t('PARTICIPANT_LIST:change_camera')}
              onClick={toggleCamera}
            />
          )}
        </>
      )}

      <CircleIconButton
        icon={<FullscreenIcon />}
        type="primary"
        description={t('PARTICIPANT_LIST:fullscreen')}
        onClick={fullScreen}
        disabled={hasPip}
      />
      <CircleIconButton
        icon={pipElement.icon}
        type={pipElement.buttonType}
        description={t(`PARTICIPANT_LIST:${pipElement.description}`)}
        onClick={pictureInPicture}
      />
    </div>
  );
};

export default React.memo(ParticipantControlsRemote);
