export enum RecordingOutputMode {
  COMPOSED = 'COMPOSED',
  INDIVIDUAL = 'INDIVIDUAL'
}

export interface StartRecordingRequest {
  session: string;
  name?: string;
  outputMode: RecordingOutputMode;
  hasAudio?: boolean;
  hasVideo?: boolean;
  resolution: string;
}

export interface RecordingResponse {
  id: string;
  sessionId: string;
  name: string;
  outputMode: string;
  hasAudio: boolean;
  hasVideo: boolean;
  createdAt: number;
  size: number;
  duration: number;
  url: string | null;
  recordingLayout?: string;
  resolution?: string;
  status: 'started' | 'stopped' | 'available' | 'starting' | 'ready' | 'failed';
}

export interface RecordingListResponse {
  count: number;
  items: Array<RecordingResponse>;
}

export type Recording = {
  id?: string;
  session: string;
  startedBy: string;
  files: Array<string>;
  toUploads: Array<string> | null;
};
