import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IGeo {
  readonly id: string;
  readonly location: ILocation;
  readonly user: string;
  readonly organization: string;
  readonly role: string;
  readonly userName?: string;
  readonly createdAt: Date | null;
}

export interface ILocation {
  readonly type: string;
  readonly coordinates: Array<string>;
}

export type GeoState = {
  all: IGeo[];
};

const initialStateGeo: GeoState = {
  all: []
};

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const geoSlice = createSlice({
  name: 'geos',
  initialState: initialStateGeo,
  reducers: {
    setGeos: (state, action: PayloadAction<IGeo[]>) => {
      state.all = action.payload;
    }
  }
});

export const { setGeos } = geoSlice.actions;
export default geoSlice.reducer;
