import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IOrganization {
  id: string;
  name: string;
  email: string;
}

export type OrganizationState = {
  all: IOrganization[];
  idSelectedNav: string;
};

const initialStateOrganization: OrganizationState = {
  all: [],
  idSelectedNav: ''
};

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const organizationSlice = createSlice({
  name: 'organizations',
  initialState: initialStateOrganization,
  reducers: {
    setOrganizations: (state, action: PayloadAction<IOrganization[]>) => {
      state.all = action.payload;
    },
    setSelectedOrganization: (state, idOrganization: PayloadAction<string>) => {
      state.idSelectedNav = idOrganization.payload;
    }
  }
});

export const { setOrganizations, setSelectedOrganization } = organizationSlice.actions;
export default organizationSlice.reducer;
