import { useState } from 'react';
import { CreateGeo, Geo } from '../../models/geo.model';
import { useCreateClient, useFetchListClient } from '../api.handle.call';

export function useGetRoomGeos(
  roomId: string,
  projectId: string,
  from: number,
  to: number
): {
  geos: Geo[] | [];
  fetchGeos: () => void;
  fetchGeosError: Error | null;
  pending: boolean;
} {
  const [geos, setGeos] = useState<Geo[]>();
  const [fetchGeosError, setError] = useState<Error | null>(null);

  const [fetchGeos, pending] = useFetchListClient<Geo>(
    `rooms/${roomId}/geos?project=${projectId}&from=${from}&to=${to}`,
    setGeos,
    setError
  );

  return { geos, fetchGeos, fetchGeosError, pending };
}

export function useCreateRoomGeos(): {
  createGeo: (body: CreateGeo) => void;
  createError: string | null;
} {
  const [_, setGeos] = useState<Geo>();
  const [createError, setError] = useState<string | null>(null);

  const [createGeo] = useCreateClient<Geo, CreateGeo>(`/geos/`, setGeos, setError);

  return { createGeo, createError };
}
