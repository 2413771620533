import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { FC, memo } from 'react';

type IObjectDetectionHeaderProps = {
  onExit: () => void;
};

const ObjectDetectionHeader: FC<IObjectDetectionHeaderProps> = ({ onExit }): JSX.Element => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      wrap="nowrap">
      <Grid item xs={11}>
        Objects detected
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={onExit} size="small" color="primary">
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default memo(ObjectDetectionHeader);
