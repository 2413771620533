import React, { createContext, useContext } from 'react';
import { IApiClientProvider } from '../../models/provider/client.provider.model';
import { useProvideApiClient } from './provide.client';

const APIClientContext = createContext<IApiClientProvider>({ client: null } as IApiClientProvider);

export const useApiClient = (): IApiClientProvider => {
  return useContext(APIClientContext);
};

export function ApiClientProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const client = useProvideApiClient();
  return <APIClientContext.Provider value={client}>{children}</APIClientContext.Provider>;
}
