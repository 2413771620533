import { useEffect, useState } from 'react';
import { selectProjectRoom, selectRoom } from '../../../../store/rooms/roomSelectors';
import { useAppSelector } from '../../../../store/store';
import { CreateInvite, User } from '../../models/user.model';
import { useCreateInviteUser, useFetchListClient } from '../api.handle.call';

export function useGetUsers(projectId: string): {
  users: User[] | [];
  fetchUsers: () => void;
  fetchUsersError: Error | null;
  pending: boolean;
} {
  const [users, setUsers] = useState<User[]>();
  const [fetchUsersError, setError] = useState<Error | null>(null);

  const [fetchUsers, pending] = useFetchListClient<User>(
    `users/?project=${projectId}`,
    setUsers,
    setError
  );

  return { users, fetchUsers, fetchUsersError, pending };
}

export function useGetUserAssociatedOrganization(organizationId: string): {
  usersOrganization: User[] | [];
  fetchUsersOrganization: () => void;
  fetchUsersError: Error | null;
  pending: boolean;
} {
  const [usersOrganization, setUsers] = useState<User[]>();
  const [fetchUsersError, setError] = useState<Error | null>(null);

  const [fetchUsersOrganization, pending] = useFetchListClient<User>(
    `users/?organization=${organizationId}`,
    setUsers,
    setError
  );

  return { usersOrganization, fetchUsersOrganization, fetchUsersError, pending };
}

export function useCreateInvite(): {
  createInvite: (body: CreateInvite) => void;
  createError: string | null;
} {
  const [_, setUser] = useState<User>();
  const [createError, setError] = useState<string | null>(null);
  const [createInvite] = useCreateInviteUser<User, CreateInvite>(
    `/users/invitations/`,
    setUser,
    setError
  );
  return { createInvite, createError };
}

export function useCreateInviatePossibleError(): {
  arrayUsers: Array<string>;
  sendInvite: (arrayGuest: Array<string>, arrayRegistered: Array<string>) => void;
} {
  const [arrayUsers, setCreateArrayError] = useState<string[]>([]);

  const [arrayUsersLocal, setArrayUsersLocal] = useState<string[]>([]);

  const { createInvite, createError } = useCreateInvite();

  const { id } = useAppSelector(selectRoom);
  const projects = useAppSelector(selectProjectRoom);
  const sendInvite = (arrayGuest: Array<string>, arrayRegistered: Array<string>) => {
    setArrayUsersLocal([]);
    arrayGuest.forEach((guest) => {
      createInvite({
        room: id,
        user: null,
        project: projects,
        guestEmail: guest
      });
    });

    arrayRegistered.forEach((userRegistered) => {
      createInvite({
        room: id,
        user: userRegistered,
        project: projects,
        guestEmail: null
      });
    });
  };

  useEffect(() => {
    if (createError !== null) {
      setArrayUsersLocal([...arrayUsersLocal, createError]);
    }
  }, [createError]);

  useEffect(() => {
    setCreateArrayError(arrayUsersLocal);
  }, [arrayUsersLocal]);

  return { arrayUsers, sendInvite };
}
