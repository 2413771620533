import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MessageChat } from '../../shared/core/models/message.model';

export type ChatState = {
  messages: MessageChat[];
};

const initialStateChat: ChatState = {
  messages: []
};

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const chatSlice = createSlice({
  name: 'chat',
  initialState: initialStateChat,
  reducers: {
    addMessage: (state, action: PayloadAction<MessageChat>) => {
      state.messages.push(action.payload);
    },
    setReadedAllPublicMessages: (state, action: PayloadAction<{ recipient: string }>) => {
      state.messages = state.messages.map((m) => {
        if (m.recipient === action.payload.recipient) {
          return { ...m, readed: true };
        }
        return m;
      });
    },
    setReadedAllPrivateMessages: (state, action: PayloadAction<{ recipient: string }>) => {
      const { recipient } = action.payload;
      state.messages = state.messages.map((m) => {
        if ((m.recipient === recipient || m.sender === recipient) && m.context !== m.recipient) {
          return { ...m, readed: true };
        }
        return m;
      });
    }
  }
});

export const { addMessage, setReadedAllPublicMessages, setReadedAllPrivateMessages } =
  chatSlice.actions;
export default chatSlice.reducer;
