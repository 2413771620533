import CircleIcon from '@mui/icons-material/Circle';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import LineWeightIcon from '@mui/icons-material/LineWeight';
import { SpeedDial, SpeedDialAction, SpeedDialIcon } from '@mui/material';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import TraitThickIcon from '../../../../shared/ui/Icons/TraitThickIcon';
import TraitThinIcon from '../../../../shared/ui/Icons/TraitThinIcon';
import './ParticipantControlsTelestrator.scss';

type IParticipantControlsTelestratorProps = {
  switchColor: (color: 'red' | 'green' | 'black') => void;
  switchTrait: (trait: 'thick' | 'thin') => void;
};

const ParticipantControlsTelestrator: FC<IParticipantControlsTelestratorProps> = ({
  switchColor,
  switchTrait
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="side-telestrator__container">
      <SpeedDial
        ariaLabel="Chose color menu"
        className="side-telestrator__fab"
        direction="right"
        icon={<SpeedDialIcon openIcon={<ColorLensIcon />} icon={<ColorLensIcon />} />}>
        <SpeedDialAction
          className="side-telestrator__action--color-green"
          icon={<CircleIcon />}
          tooltipTitle={t('TELESTRATOR:green')}
          onClick={() => switchColor('green')}
        />
        <SpeedDialAction
          className="side-telestrator__action--color-red"
          icon={<CircleIcon />}
          tooltipTitle={t('TELESTRATOR:red')}
          onClick={() => switchColor('red')}
        />
        <SpeedDialAction
          className="side-telestrator__action--color-black"
          icon={<CircleIcon />}
          tooltipTitle={t('TELESTRATOR:black')}
          onClick={() => switchColor('black')}
        />
      </SpeedDial>
      <SpeedDial
        ariaLabel="Chose trait menu"
        className="side-telestrator__fab"
        direction="right"
        icon={<SpeedDialIcon openIcon={<LineWeightIcon />} icon={<LineWeightIcon />} />}>
        <SpeedDialAction
          icon={<TraitThinIcon />}
          tooltipTitle={t('TELESTRATOR:thin')}
          onClick={() => switchTrait('thin')}
        />
        <SpeedDialAction
          icon={<TraitThickIcon />}
          tooltipTitle={t('TELESTRATOR:thick')}
          onClick={() => switchTrait('thick')}
        />
      </SpeedDial>
    </div>
  );
};

export default memo(ParticipantControlsTelestrator);
