export enum ParticipantKind {
  LOCAL = 'LOCAL',
  REMOTE = 'REMOTE',
  SCREEN = 'SCREEN'
}

export enum ParticipantType {
  MOBILE = 'SMARTPHONE',
  GLASS = 'GLASS',
  WEB = 'WEB',
  SCREEN = 'SCREEN'
}
