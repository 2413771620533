import axios, { AxiosInstance } from 'axios';

class VisionApiClient {
  private baseUrl = '';
  private key = '';

  private apiClient!: AxiosInstance;

  get client(): AxiosInstance {
    return this.apiClient;
  }

  constructor(baseUrl: string, key: string) {
    this.baseUrl = baseUrl;
    this.key = key;
    this.apiClient = axios.create({
      baseURL: `${this.baseUrl}/`,
      params: {
        key: this.key
      }
    });
  }
}

export default VisionApiClient;
