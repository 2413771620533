import { RootState } from '../store';
import { getUsersState } from '../user/userSelectors';
import { GeoState, IGeo } from './geoReducer';

export const getGeosState = (store: RootState): GeoState => store.geo;

/**
 * Select all geos
 *
 * @param state Store state
 * @returns List of geos {@link IGeo}
 */
export const selectAllGeos = (state: RootState): IGeo[] => getGeosState(state).all ?? [];

/**
 * Select all geos with user info
 *
 * @param state Store state
 * @returns List of geos {@link IGeo}
 */
export const selectAllGeosWithUser = (state: RootState): IGeo[] => {
  const geos = getGeosState(state).all ?? [];
  const users = getUsersState(state).all ?? [];

  return geos
    .map((g) => {
      const user = users.find((u) => u.id === g.user);
      if (user) {
        return { ...g, userName: `${user.firstName} ${user.lastName}` };
      }
      return null;
    })
    .filter((g) => g !== null);
};
