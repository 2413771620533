import { Divider, Grid } from '@material-ui/core';
import React, { FC, useMemo } from 'react';
import { useOpenviduLocal } from '../../shared/core/context/provider/openvidu/OpenviduLocalProvider';
import ParticipantList from './ParticipantList';
import ParticipantListCommand from './ParticipantListCommand';
import './ParticipantListContainer.scss';
import ParticipantListHeader from './ParticipantListHeader';
type IParticipantListContainerProps = {
  onExit: () => void;
  openChat: () => void;
};

const ParticipantListContainer: FC<IParticipantListContainerProps> = ({
  onExit,
  openChat
}): JSX.Element => {
  const { participants, firstParticipant, secondParticipant, toggleMicrophone, switchParticipant } =
    useOpenviduLocal();

  const sortedParticipant = useMemo(() => {
    return [...participants].sort((a, b) => a.name.localeCompare(b.name));
  }, [participants]);

  const toggleAudio = (connection: string) => {
    toggleMicrophone(connection);
  };

  const replaceParticipant = (connection: string) => {
    switchParticipant(connection);
  };

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      wrap="nowrap"
      className="participant-list__container">
      <Grid item>
        <ParticipantListHeader onExit={onExit} />
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item style={{ flexGrow: 2 }}>
        <ParticipantList
          participants={sortedParticipant}
          firstParticipant={firstParticipant?.connectionId ?? null}
          secondParticipant={secondParticipant?.connectionId ?? null}
          toggleAudio={toggleAudio}
          switchParticipant={replaceParticipant}
          openChat={openChat}
        />
      </Grid>
      <Grid item style={{ flexGrow: 1 }} className="participant-list__command">
        <ParticipantListCommand />
      </Grid>
    </Grid>
  );
};

export default React.memo(ParticipantListContainer);
