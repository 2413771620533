import { ButtonBase, Grid, Tooltip } from '@material-ui/core';
import { FC, HTMLAttributes, memo } from 'react';
import { MessageChat } from '../../shared/core/models/message.model';
import './ChatMessage.scss';

type IChatMessageProps = {
  message: MessageChat;
  itsMe: boolean;
  onClick: () => void;
};

const ChatMessage: FC<IChatMessageProps & HTMLAttributes<HTMLDivElement>> = ({
  message,
  itsMe,
  onClick
}): JSX.Element => {
  const { sender_display_name: displayName, message: body } = message;

  return (
    <ButtonBase onClick={onClick} className="chat-message__button">
      <Grid
        container
        direction="column"
        justifyContent="flex-start"
        alignContent={itsMe ? 'flex-end' : 'flex-start'}
        className="chat-message">
        <Grid item xs={11}>
          <Tooltip title={displayName}>
            <div
              className={
                'chat-message__participant-name ' +
                (itsMe ? 'chat-message__participant-name--me' : '')
              }>
              {displayName}
            </div>
          </Tooltip>
        </Grid>
        <Grid item xs={11}>
          <div className={'chat-message__body ' + (itsMe ? 'chat-message__body--me' : '')}>
            {body}
          </div>
        </Grid>
      </Grid>
    </ButtonBase>
  );
};

export default memo(ChatMessage);
