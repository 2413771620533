import { RootState } from '../store';
import { IParticipant, ParticipantState } from './participantReducer';

export const getParticipantState = (store: RootState): ParticipantState => store.participant;

/**
 * Select all timer
 *
 * @param state Store state
 * @returns List of timer {@link IParticipant}
 */
export const selectParticipant = (state: RootState): IParticipant[] =>
  getParticipantState(state).all;
