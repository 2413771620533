import { AxiosResponse } from 'axios';
import { useEffect, useState } from 'react';
import { useConfig } from '../../core/context/provider/ConfigProvider';
import { DETECT_IMAGE_FEATURES } from '../../core/models/vision-api/const/detect-image-features';
import { VisionApiAnnotateImageRequest } from '../../core/models/vision-api/entities/annotate-image-request.interface';
import { VisionApiBatchAnnotateImagesResponse } from '../../core/models/vision-api/entities/annotate-image-response.interface';
import { VisionApiFeature } from '../../core/models/vision-api/entities/feature.interface';
import VisionApiClient from './vision-api-client';

type IVisionApi = {
  detect: (image: string) => Promise<AxiosResponse<VisionApiBatchAnnotateImagesResponse>>;
};

export default function useVisionApi(): IVisionApi {
  const { visionApi } = useConfig();
  const [apiClient, setApiClient] = useState<VisionApiClient | null>();

  useEffect(() => {
    setApiClient(new VisionApiClient(visionApi.url, visionApi.key));
  }, []);

  const buildFeaturesDetectAnnotationImage = (maxResults: number): Array<VisionApiFeature> => {
    return DETECT_IMAGE_FEATURES.map((type) => ({
      type,
      maxResults
    }));
  };

  const buildImageRequest = (
    imageBase64: string,
    maxResults: number
  ): VisionApiAnnotateImageRequest => {
    return {
      image: {
        content: imageBase64
      },
      features: buildFeaturesDetectAnnotationImage(maxResults)
    };
  };

  /**
   * Perform object detection, looking for:
   *
   *  features: [
   *      LABEL_DETECTION,
   *      CROP_HINTS,
   *      OBJECT_LOCALIZATION
   * ]
   *
   * @param imageBase64 Immagine in base64
   */
  const detect = (image: string): Promise<AxiosResponse<VisionApiBatchAnnotateImagesResponse>> => {
    const strippedImage = image.split(',')[1];
    const request: VisionApiAnnotateImageRequest = buildImageRequest(
      strippedImage,
      visionApi.maxResults
    );
    return apiClient.client.post<VisionApiBatchAnnotateImagesResponse>('v1/images:annotate', {
      requests: [request]
    });
  };

  return {
    detect
  };
}
