import { FileType, SendFileEvent } from '../../core/models/socket/event.model';
// eslint-disable-next-line import/no-unresolved
import { sendFile } from '@cloudvision/um-global';
import moment from 'moment';
import { useState } from 'react';
import { selectId, selectName } from '../../../store/auth/authSelectors';
import { useAppSelector } from '../../../store/store';
import useChatMessages from './useChatMessages';

type Itype = {
  sendFileMessage: (
    file: { base64: string; mimeType: string; name: string },
    recipient: string
  ) => void;
  setContext: (context: string) => void;
};

export default function useFileMessages(context: string): Itype {
  const [chatContext, setChatContext] = useState<string>(context);
  const currentUserName = useAppSelector(selectName);
  const id = useAppSelector(selectId);
  const { sendChatMessage } = useChatMessages(context, false);

  const getTypeOfFile = (mimeType: string): FileType => {
    switch (true) {
      case mimeType.startsWith('image'):
        return FileType.IMAGE;
      case mimeType.startsWith('document'):
      case mimeType.startsWith('application'):
        return FileType.DOCUMENT;
      default:
        return FileType.UNKNOWN;
    }
  };

  const sendFileMessage = ({ base64, mimeType, name }, recipient: string) => {
    const contextualFileMessage: SendFileEvent = {
      context: chatContext,
      body: base64,
      mimeType,
      name,
      type: getTypeOfFile(mimeType),
      recipient,
      sender: id,
      sender_display_name: currentUserName,
      timestamp: moment().valueOf()
    };
    sendFile(contextualFileMessage);
    sendChatMessage(`File ${name} sent`, recipient, {
      url: base64,
      name: name.replace(/\.[^/.]+$/, '')
    });
  };

  const setContext = (content: string): void => {
    setChatContext(content);
  };

  return {
    sendFileMessage,
    setContext
  };
}
