import { Grid, GridSize, IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOpenviduLocal } from '../../shared/core/context/provider/openvidu/OpenviduLocalProvider';
import { useOpenviduScreenShare } from '../../shared/core/context/provider/openvidu/OpenviduScreenShareProvider';
import { CommandType } from '../../shared/core/models/socket/event.model';
import { VisionApiEntityAnnotation } from '../../shared/core/models/vision-api/entities/annotations/entity';
import useChatMessages from '../../shared/hooks/socket/useChatMessages';
import useRemoteCommand from '../../shared/hooks/socket/useRemoteCommand';
import SimpleBox from '../../shared/ui/SimpleBox/SimpleBox';
import { useAppSelector } from '../../store/store';
import { selectTelestrator } from '../../store/telestrator/telestratorSelector';
import ParticipantVideo from './ParticipantVideo/ParticipantVideo';
import SimpleVideo from './SimpleVideo';
import './Stream.scss';

type IStreamProps = {
  isParticipantListOpen: boolean;
  sessionId: string;
  roomId: string;
  sideComponent: JSX.Element | ReactNode | null;
  onObjectDetected: (items: Array<VisionApiEntityAnnotation>) => void;
};

const Stream: FC<IStreamProps> = ({
  isParticipantListOpen,
  roomId,
  sessionId,
  sideComponent,
  onObjectDetected
}) => {
  const {
    join,
    leave,
    removeStream,
    togglePin,
    toggleLocalCamera,
    toggleMicrophone,
    pinned,
    firstParticipant,
    secondParticipant,
    isSingleParticipant,
    participants
  } = useOpenviduLocal();

  const {
    publisher: screenShareParticipant,
    join: startScreenShare,
    leave: stopScreenShare
  } = useOpenviduScreenShare();

  const [gridStreamSize, setGridStreamSize] = useState<boolean | GridSize>(6);
  const [isMiniaturized, setIsMiniaturized] = useState<boolean>(false);

  const telestrator = useAppSelector(selectTelestrator);

  const { t } = useTranslation();

  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);

  const { sendRemoteCommand } = useRemoteCommand(roomId);
  useChatMessages(roomId);

  const handleCloseSnackbar = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbar(false);
  };

  useEffect(() => {
    join(sessionId).then();
    return () => {
      leave();
      stopScreenShare();
    };
  }, []);

  useEffect(() => {
    const handler = () => {
      leave();
      stopScreenShare();
    };

    window.addEventListener('beforeunload', handler);
    return () => window.removeEventListener('beforeunload', handler);
  }, []);

  useEffect(() => {
    if (sideComponent !== null) {
      setGridStreamSize(true);
    } else {
      setGridStreamSize(6);
    }

    setIsMiniaturized(sideComponent !== null && !isSingleParticipant);
  }, [isSingleParticipant, sideComponent]);

  const onClose = (connectionId: string) => {
    if (telestrator.isActive === false) {
      setShowSnackbar(false);
      removeStream(connectionId);
    } else {
      setShowSnackbar(true);
    }
  };

  const onTogglePin = (connectionId: string) => {
    togglePin(connectionId);
  };

  const onToggleCamera = (connectionId: string) => {
    toggleLocalCamera(connectionId);
  };

  const onToggleMicrophone = (connectionId: string) => {
    toggleMicrophone(connectionId);
  };

  const onStartScreenshare = () => {
    startScreenShare(sessionId).then();
  };

  const onStopScreenShare = () => {
    stopScreenShare();
  };

  const onRemoteCommand = (command: CommandType, recipient: string) => {
    sendRemoteCommand(command, recipient);
  };

  return (
    <div className="stream__container">
      {/* Grid container with participants and optional side component */}
      <Grid
        container
        spacing={3}
        direction="row"
        justifyContent="center"
        alignItems="stretch"
        className=" stream__grid stream__grid--height container-stream">
        {/* Grid with stream of participants */}
        <Grid
          item
          container
          xs={12}
          md
          spacing={2}
          direction={sideComponent === null ? 'row' : 'column'}
          justifyContent={sideComponent === null ? 'center' : 'flex-start'}
          alignItems="stretch">
          {firstParticipant !== null ? (
            <Grid item xs={12} md={gridStreamSize}>
              <ParticipantVideo
                participant={firstParticipant}
                isPinned={firstParticipant.connectionId === pinned}
                isSingleParticipant={isSingleParticipant}
                miniature={isMiniaturized}
                screenShareParticipant={screenShareParticipant}
                onClose={onClose}
                onTogglePin={onTogglePin}
                onToggleMicrophone={onToggleMicrophone}
                onToggleCamera={onToggleCamera}
                onStartScreenShare={onStartScreenshare}
                onStopScreenShare={onStopScreenShare}
                onObjectDetected={onObjectDetected}
                onRemoteCommand={onRemoteCommand}
              />
            </Grid>
          ) : null}
          {secondParticipant !== null ? (
            <Grid item xs={12} md={gridStreamSize}>
              <ParticipantVideo
                participant={secondParticipant}
                isPinned={secondParticipant.connectionId === pinned}
                isSingleParticipant={isSingleParticipant}
                miniature={isMiniaturized}
                screenShareParticipant={screenShareParticipant}
                onClose={onClose}
                onTogglePin={onTogglePin}
                onToggleMicrophone={onToggleMicrophone}
                onToggleCamera={onToggleCamera}
                onStartScreenShare={onStartScreenshare}
                onStopScreenShare={onStopScreenShare}
                onObjectDetected={onObjectDetected}
                onRemoteCommand={onRemoteCommand}
              />
            </Grid>
          ) : null}
        </Grid>
        {sideComponent !== null ? (
          <Grid item xs={12} md={5}>
            <SimpleBox>{sideComponent}</SimpleBox>
          </Grid>
        ) : null}
      </Grid>
      {/******************************************************************
       *
       * THIS RENDER IS MANDATORY TO SUBSCRIBE TO ALL PARTICIPANTS AUDIO
       *
       ******************************************************************/}
      {!isParticipantListOpen && (
        <div style={{ display: 'none' }}>
          {participants.map((p) => (
            <SimpleVideo key={p.id} hasVideo={false} manager={p.manager} mirror={false} />
          ))}
        </div>
      )}
      <Snackbar
        open={showSnackbar}
        autoHideDuration={2000}
        message={t('SNACKBAR:telestrator_error')}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
};

export default React.memo(Stream);
