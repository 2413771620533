import CallEndIcon from '@mui/icons-material/CallEnd';
import { Button, Grid, Typography } from '@mui/material';
import { FC, memo } from 'react';
import './StreamError.scss';

type IStreamErrorProps = {
  exitFromApp: () => void;
};

const StreamError: FC<IStreamErrorProps> = ({ exitFromApp }): JSX.Element => {
  return (
    <Grid
      container
      spacing={6}
      direction="column"
      alignItems="center"
      justifyContent="center"
      className="session-error__container">
      <Grid item xs={12} className="session-error__item">
        <Typography variant="h3" gutterBottom>
          Ooops!
        </Typography>
      </Grid>
      <Grid item xs={12} className="session-error__item">
        Room not exists or is invalid. Please contact an administrator to report the problem.
      </Grid>
      <Grid item xs={12} className="session-error__item">
        <Button onClick={exitFromApp} variant="contained" color="error" startIcon={<CallEndIcon />}>
          Close call
        </Button>
      </Grid>
    </Grid>
  );
};

export default memo(StreamError);
