import { Divider, Grid, List, ListItem, ListItemText } from '@material-ui/core';
import { FC, memo, useEffect } from 'react';
import { useGetAllMedia } from '../../shared/core/api/hook-call/media.hook';
import { Media } from '../../shared/core/models/vision-api/entities/media.interface';
import { setAllMedia } from '../../store/media/mediaReducer';
import { selectAllMedia } from '../../store/media/mediaSelectors';
import { useAppDispatch, useAppSelector } from '../../store/store';
import './Media.scss';
import MediaHeader from './MediaHeader';

type MediaContainerProps = {
  onExit: () => void;
  media: Array<Media>;
  storageId: string | null;
};
const MediaContainer: FC<MediaContainerProps> = ({ onExit, storageId }): JSX.Element => {
  const dispatcher = useAppDispatch();
  const countOfMedia = useAppSelector(selectAllMedia);
  const { allMedia, fetchMedia } = useGetAllMedia(storageId);

  useEffect(() => {
    if (allMedia && allMedia.length > 0) {
      dispatcher(setAllMedia(allMedia));
    }
  }, [allMedia]);

  useEffect(() => {
    if (storageId) {
      fetchMedia();
    }
  }, [storageId]);

  return (
    <Grid
      container
      spacing={1}
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      wrap="nowrap"
      className="object-detection__container">
      <Grid item>
        <MediaHeader onExit={onExit} />
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item style={{ flexGrow: 2 }}>
        <List className="object-detection__list">
          {countOfMedia.map((l) => (
            <a target="_blank" key={l.id} href={l.path} rel="noreferrer" className="media_list">
              <div>
                <ListItem component="div">
                  <ListItemText primary={l.name} />
                </ListItem>
                <Divider />
              </div>
            </a>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default memo(MediaContainer);
