/* eslint-disable @typescript-eslint/no-unused-vars */
import { StreamManager } from 'openvidu-browser';
import React, { RefObject, useEffect, useRef } from 'react';
import { ParticipantKind } from '../../shared/enum/participant-type.enum';
import useFullscreenStatus from '../../shared/hooks/useFullScreenStatus';
import usePipScreen from '../../shared/hooks/usePipScreen';
import UserPlaceholder from '../../shared/ui/UserPlaceholder/UserPlaceholder';
import './SimpleVideo.scss';

type ISimpleVideoProps = {
  manager: StreamManager;
  mirror: boolean;
  hasVideo: boolean;
  miniature?: boolean;
  requestPipScreen?: boolean;
  requestFullScreen?: boolean;
  videoRef?: RefObject<HTMLVideoElement>;
  type?: string;
  onFullScreenExit?: () => void;
  onPipScreenExit?: () => void;
  onClick?: () => void;
};

const SimpleVideo: React.FC<ISimpleVideoProps> = ({
  manager,
  type,
  mirror,
  hasVideo,
  miniature = false,
  requestPipScreen = false,
  requestFullScreen = false,
  videoRef = React.createRef<HTMLVideoElement>(),
  onFullScreenExit = () => ({}),
  onPipScreenExit = () => ({}),
  onClick = () => ({})
}) => {
  const videoElem = useRef(null);
  const [_isFullScreen, setFullscreen] = useFullscreenStatus(videoElem);
  const [isPipScreen, isSupported, setPipScreen, closePipScreen] = usePipScreen(videoRef);

  const onFullScreenChange = (_ev: Event) => {
    if (document.fullscreenElement) {
      onFullScreenExit();
    }
  };

  useEffect(() => {
    if (videoRef) {
      manager.addVideoElement(videoRef.current);
    }
  }, [manager]);

  useEffect(() => {
    if (requestFullScreen) {
      setFullscreen();
      document.addEventListener('fullscreenchange', onFullScreenChange);

      return () => {
        document.removeEventListener('fullscreenchange', onFullScreenChange);
      };
    }
  }, [requestFullScreen]);

  useEffect(() => {
    if (isSupported) {
      if (requestPipScreen) {
        setPipScreen();
      } else {
        if (isPipScreen) {
          closePipScreen();
        }
      }
    }
  }, [requestPipScreen, isPipScreen]);

  useEffect(() => {
    if (isSupported && !isPipScreen) {
      onPipScreenExit();
    }
  }, [isPipScreen]);

  return (
    <div
      className={
        'simple-video__container ' + (miniature ? 'simple-video__container--miniature' : '')
      }
      ref={videoElem}>
      <video
        className={`simple-video__item 
          ${mirror ? ' simple-video__item--mirror ' : ''}
          ${miniature ? 'simple-video__item--miniature' : ''}
          ${type === ParticipantKind.SCREEN ? 'width-resize' : ''}
          `}
        autoPlay={true}
        ref={videoRef}
        onClick={onClick}
      />
      {!hasVideo ? (
        <div className="simple-video__item simple-video__item--inactive" onClick={onClick}>
          <UserPlaceholder miniature={miniature} />
        </div>
      ) : null}
    </div>
  );
};

export default React.memo(SimpleVideo);
