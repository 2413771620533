import { RootState } from '../store';
import { IRoom, RoomState } from './roomReducer';

export const getRoomState = (store: RootState): RoomState => store.room;

/**
 * Select room
 *
 * @param state Store state
 * @returns single room
 */
export const selectRoom = (state: RootState): IRoom | null =>
  getRoomState(state).selectedRoom ?? null;

export const selectProjectRoom = (state: RootState): string | null =>
  getRoomState(state).selectedRoom.projects[0] ?? null;

export const selectStorage = (state: RootState): string | null =>
  getRoomState(state).selectedRoom?.storageId ?? null;

export const selectTag = (state: RootState): string | null => getRoomState(state).tag;
