import { RootState } from '../store';
import { TimerState } from './timerReducer';

export const getTimerState = (store: RootState): TimerState => store.timer;

/**
 * Select all timer
 *
 * @param state Store state
 * @returns List of timer {@link ITimer}
 */
export const selectSeconds = (state: RootState): string => getTimerState(state).timeValue.seconds;
export const selectMinutes = (state: RootState): string => getTimerState(state).timeValue.minutes;
export const selectHours = (state: RootState): string => getTimerState(state).timeValue.hours;
export const selectIsActive = (state: RootState): boolean => getTimerState(state).isActive;
export const selectShowButton = (state: RootState): boolean => getTimerState(state).showOption;
