import { Icon } from '@material-ui/core';
import PersonIcon from '@mui/icons-material/Person';
import { FC, HTMLAttributes, memo } from 'react';
import './UserPlaceholder.scss';

type IUserPlaceholderProps = {
  miniature?: boolean;
};

const UserPlaceholder: FC<IUserPlaceholderProps & HTMLAttributes<HTMLDivElement>> = ({
  miniature = false,
  className = ''
}): JSX.Element => {
  return (
    <div className={'user-placeholder ' + className}>
      <div
        className={
          'user-placeholder__container ' +
          (miniature ? 'user-placeholder__container--miniature' : '')
        }>
        <Icon>
          <PersonIcon />
        </Icon>
      </div>
    </div>
  );
};

export default memo(UserPlaceholder);
