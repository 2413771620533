import { RootState } from '../store';
import { IUser, UserState } from './userReducer';

export const getUsersState = (store: RootState): UserState => store.user;

/**
 * Select all users
 *
 * @param state Store state
 * @returns List of users {@link IUser}
 */
export const selectAllUsers = (state: RootState): IUser[] => getUsersState(state).all ?? [];
export const selectAllUsersOrganization = (state: RootState): IUser[] =>
  getUsersState(state).allOrganization ?? [];
