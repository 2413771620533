import { ThemeOptions } from '@mui/material';
import { orange } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';

/**
 * Refer to
 * https://mui.com/material-ui/customization/theming/#custom-variables
 * https://bareynol.github.io/mui-theme-creator/
 *
 * for theme customization
 */
declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

export const darkThemeDefinition: ThemeOptions = {
  status: {
    danger: orange[500]
  },
  palette: {
    mode: 'dark',
    primary: {
      main: '#FFF'
    },
    secondary: {
      main: '#95c11f'
    }
  }
};

const lightThemeDefinition: ThemeOptions = {
  ...darkThemeDefinition,
  palette: {
    ...darkThemeDefinition.palette,
    mode: 'light'
  }
};

export const themeDark = createTheme(darkThemeDefinition);
export const themeLight = createTheme(lightThemeDefinition);
