import { Button, Grid, IconButton, Tooltip, Zoom } from '@material-ui/core';
import MessageIcon from '@mui/icons-material/Message';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import { FC, memo, useEffect, useState } from 'react';
import { Participant } from '../../../shared/core/models/openvidu/participant.model';
import { IButtonElement } from '../../../shared/core/models/props/button-element';
import { ParticipantKind } from '../../../shared/enum/participant-type.enum';
import ParticipantName from '../../../shared/ui/ParticipantName/ParticipantName';
import SimpleVideo from '../../stream/SimpleVideo';
import './SingleParticipant.scss';
import { useTranslation } from 'react-i18next';

type ISingleParticipantProps = {
  participant: Participant;
  hasVideo: boolean;
  hasAudio: boolean;
  haveAudioControl: boolean;
  switchable: boolean;
  chattable: boolean;
  toggleAudio: () => void;
  switchParticipant: () => void;
  requestOpenChat: () => void;
};

const ACTIVE_MIC: IButtonElement = {
  icon: <VolumeUpIcon />,
  description: 'mute',
  buttonType: 'primary'
};

const DISACTIVE_MIC: IButtonElement = {
  icon: <VolumeOffIcon />,
  description: 'unmute',
  buttonType: 'alert'
};

const SingleParticipant: FC<ISingleParticipantProps> = ({
  participant,
  hasVideo,
  hasAudio,
  haveAudioControl,
  switchable,
  chattable,
  toggleAudio,
  switchParticipant,
  requestOpenChat
}): JSX.Element => {
  const [audioElement, setAudioElement] = useState<IButtonElement>(ACTIVE_MIC);
  const { t } = useTranslation();
  useEffect(() => {
    if (hasAudio) {
      setAudioElement(ACTIVE_MIC);
    } else {
      setAudioElement(DISACTIVE_MIC);
    }
  }, [hasAudio]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignContent="flex-start"
      alignItems="stretch"
      spacing={2}
      className="single-participant__container">
      <Grid item xs={3} className="single-participant__video-container">
        <SimpleVideo
          miniature={true}
          manager={participant.manager}
          hasVideo={hasVideo}
          mirror={participant.kind === ParticipantKind.LOCAL}
        />
      </Grid>
      <Grid
        item
        xs={6}
        container
        direction="column"
        justifyContent="space-between"
        alignContent="flex-start"
        alignItems="flex-start">
        <ParticipantName
          name={participant.name}
          type={participant.type}
          showAudioInfo={!participant.manager.stream.audioActive}
          className="single-participant__name--spacer"
        />
        {haveAudioControl && (
          <Tooltip
            TransitionComponent={Zoom}
            title={t(`PARTICIPANT_LIST:${audioElement.description}`)}
            placement="right">
            <IconButton size="small" onClick={toggleAudio}>
              {audioElement.icon}
            </IconButton>
          </Tooltip>
        )}
      </Grid>
      <Grid
        item
        xs={3}
        container
        direction="column"
        justifyContent="space-between"
        alignContent="flex-end"
        alignItems="flex-end">
        {chattable && (
          <Tooltip
            TransitionComponent={Zoom}
            title={t('PARTICIPANT_LIST:open_chat')}
            placement="left">
            <IconButton size="small" onClick={requestOpenChat}>
              <MessageIcon />
            </IconButton>
          </Tooltip>
        )}
        {switchable && (
          <Tooltip
            TransitionComponent={Zoom}
            title={t('PARTICIPANT_LIST:switch_participant')}
            placement="left">
            <Button
              startIcon={<MessageIcon />}
              size="small"
              color="secondary"
              onClick={switchParticipant}>
              {t('PARTICIPANT_LIST:watch')}
            </Button>
          </Tooltip>
        )}
      </Grid>
    </Grid>
  );
};

export default memo(SingleParticipant);
