import { Grid, IconButton, Input, InputAdornment, ThemeProvider, Tooltip } from '@material-ui/core';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SendIcon from '@mui/icons-material/Send';
import { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { themeLight } from '../../theme';
import './ChatInput.scss';
type IChatInputProps = {
  disabled: boolean;
  isActive: boolean;
  onSend: (body: string) => void;
  onAttachFile: () => void;
};

const ChatInput: FC<IChatInputProps> = ({
  disabled,
  onSend,
  onAttachFile,
  isActive
}): JSX.Element => {
  const [body, setBody] = useState<string>('');
  const { t } = useTranslation();
  const sendMessage = useCallback(() => {
    if (body !== '') {
      onSend(body);
      setBody('');
    }
  }, [body]);

  function _handleKeyDown(e) {
    if (e.key === 'Enter') {
      sendMessage();
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBody(event.target.value);
  };

  return (
    <ThemeProvider theme={themeLight}>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignContent="center"
        spacing={1}
        className="chat-input">
        <Grid item xs={11}>
          <Tooltip title={disabled ? 'Select a chat' : ''} placement="top">
            <Input
              placeholder={
                isActive ? t('CHAT_CONTAINER:messages_active') : t('CHAT_CONTAINER:type')
              }
              onKeyDown={_handleKeyDown}
              value={body}
              onChange={handleChange}
              fullWidth
              disabled={isActive}
              disableUnderline={true}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="Send file"
                    edge="end"
                    disabled={isActive}
                    onClick={onAttachFile}>
                    <AttachFileIcon />
                  </IconButton>
                  <IconButton
                    aria-label="Send message"
                    edge="end"
                    disabled={isActive}
                    onClick={sendMessage}>
                    <SendIcon />
                  </IconButton>
                </InputAdornment>
              }
            />
          </Tooltip>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

export default memo(ChatInput);
