// eslint-disable-next-line import/no-unresolved
import { changeTag } from '@cloudvision/um-global';
import moment from 'moment';
import { useState } from 'react';
import { selectId, selectName } from '../../../store/auth/authSelectors';
import { useAppSelector } from '../../../store/store';
import { ChangeTagEvent } from '../../core/models/socket/event.model';

type ITagEvent = {
  changeSessionTag: (tag: string | null) => void;
};

export default function useTagEvent(context: string): ITagEvent {
  const [tagContext] = useState<string>(context);
  const name = useAppSelector(selectName);
  const id = useAppSelector(selectId);

  const changeSessionTag = (tag: string): void => {
    const contextualMessage: ChangeTagEvent = {
      room: tagContext,
      tag,
      context: tagContext,
      recipient: tagContext,
      sender: id,
      sender_display_name: name,
      timestamp: moment().valueOf()
    };
    changeTag(contextualMessage);
  };

  return {
    changeSessionTag
  };
}
