import { Button, IconButton, Tooltip } from '@material-ui/core';
import CallEndIcon from '@mui/icons-material/CallEnd';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import MapIcon from '@mui/icons-material/Map';
import MessageIcon from '@mui/icons-material/Message';
import PermMediaIcon from '@mui/icons-material/PermMedia';
import PersonIcon from '@mui/icons-material/Person';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import SellOutlinedIcon from '@mui/icons-material/SellOutlined';
import React, { FC, Fragment, useCallback, useEffect, useState } from 'react';
import { useGeolocated } from 'react-geolocated';
import { useTranslation } from 'react-i18next';
import { useCreateRoomGeos } from '../../shared/core/api/hook-call/geo.hook';
import {
  useGetUserAssociatedOrganization,
  useGetUsers
} from '../../shared/core/api/hook-call/user.hook';
import { IButtonElement } from '../../shared/core/models/props/button-element';
import { Room } from '../../shared/core/models/room.model';
import { Media } from '../../shared/core/models/vision-api/entities/media.interface';
import useTagEvent from '../../shared/hooks/socket/useTagEvent';
import '../../shared/styles/_layout.scss';
import CircleIconButton from '../../shared/ui/CircleIconButton/CircleIconButton';
import Snackbar from '../../shared/ui/Snackbar/Snackbar';
import { selectSelectedOrganization } from '../../store/organizations/organizationSelectors';
import { selectRoom, selectTag } from '../../store/rooms/roomSelectors';
import { setSnackbar } from '../../store/snackbar/snackbarReducer';
import { selectSnackbar } from '../../store/snackbar/snackbarSelectors';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../store/store';
import { selectTelestrator } from '../../store/telestrator/telestratorSelector';
import { ITimer, setTimer } from '../../store/timer/timerReducer';
import { selectIsActive } from '../../store/timer/timerSelectors';
import { setUsers, setUsersOrganization } from '../../store/user/userReducer';
import MapDialog from '../map-dialog/map-dialog';
import TagDialog from '../tag-dialog/TagDialog';
import './stream-toolbar.scss';

type IToolbarProps = {
  openParticipants: () => void;
  openChat: () => void;
  onLeave: () => void;
  onStartRecording: () => void;
  onStopRecording: () => void;
  openMedia: (media: Array<Media>) => void;
  noMediaAttached: () => void;
  canRecordSession: boolean;
  notifyMessage: boolean;
  selectedRoom: Room;
  projectId: string;
  organizationId: string;
  isRecording: boolean;
};

const CLOSE_SESSION: IButtonElement = {
  icon: <CallEndIcon />,
  description: 'Close Session',
  buttonType: 'alert'
};

const StreamToolbar: FC<IToolbarProps> = ({
  openParticipants,
  openChat,
  onLeave,
  onStartRecording,
  onStopRecording,
  openMedia,
  noMediaAttached,
  canRecordSession,
  isRecording,
  notifyMessage,
  selectedRoom,
  projectId
}): JSX.Element => {
  const [microphoneElement] = useState<IButtonElement>(CLOSE_SESSION);
  const sessionTag = useAppSelector(selectTag);
  const { changeSessionTag } = useTagEvent(selectedRoom.id);
  const { createGeo } = useCreateRoomGeos();
  const currentRoom = useAppSelector(selectRoom);
  const { users, fetchUsers } = useGetUsers(projectId);
  const organizationId = useAppSelector(selectSelectedOrganization);
  const { usersOrganization, fetchUsersOrganization } =
    useGetUserAssociatedOrganization(organizationId);
  const dispatch: AppDispatch = useAppDispatch();
  const { coords, isGeolocationEnabled } = useGeolocated({
    positionOptions: {
      enableHighAccuracy: false
    },
    userDecisionTimeout: 5000
  });
  const [second, setSecond] = useState('00');
  const [minute, setMinute] = useState('00');
  const [hour, setHour] = useState('00');
  const [counter, setCounter] = useState(0);

  const getIsActive = useAppSelector(selectIsActive);
  const { t } = useTranslation();
  let intervalId;
  const valueStateSnackbar = useAppSelector(selectSnackbar);
  const [valueSnackbar, setValueSnackbar] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const telestrator = useAppSelector(selectTelestrator);

  useEffect(() => {
    if (getIsActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60) % 60;
        const hoursCounter = Math.floor(counter / 3600) % 60;

        const computedSecond =
          String(secondCounter).length === 1 ? `0${secondCounter}` : secondCounter;
        const computedMinute =
          String(minuteCounter).length === 1 ? `0${minuteCounter}` : minuteCounter;
        const computedHour = String(hoursCounter).length === 1 ? `0${hoursCounter}` : hoursCounter;

        setSecond('' + computedSecond);
        setMinute('' + computedMinute);
        setHour('' + computedHour);
        setCounter((count) => count + 1);

        const timer: ITimer = { minutes: minute, seconds: second, hours: hour };

        dispatch(setTimer(timer));
      }, 1000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [getIsActive, counter]);

  useEffect(() => {
    fetchUsers();
    fetchUsersOrganization();
  }, []);

  useEffect(() => {
    if (usersOrganization && usersOrganization.length > 0) {
      dispatch(setUsersOrganization(usersOrganization));
    }
  }, [usersOrganization]);

  useEffect(() => {
    if (valueStateSnackbar) {
      setValueSnackbar(true);
    } else {
      setValueSnackbar(false);
    }
  }, [valueStateSnackbar]);

  useEffect(() => {
    if (coords && isGeolocationEnabled) {
      createGeo({
        location: {
          type: 'Point',
          coordinates: [coords.longitude.toString(), coords.latitude.toString()]
        },
        source: 'desktop'
      });
    }
  }, [coords, isGeolocationEnabled]);

  useEffect(() => {
    if (users) {
      dispatch(setUsers(users));
    }
  }, [users]);

  const toggleRecording = useCallback(() => {
    if (isRecording) {
      onStopRecording();
    } else {
      onStartRecording();
    }
  }, [isRecording]);

  const [openMapStatus, setMapOpen] = useState(false);
  const [openTagDialogStatus, setTagDialogOpen] = useState(false);

  const openMap = () => {
    if (telestrator.isActive === false) {
      setShowSnackbar(false);
      setMapOpen(true);
    } else {
      setShowSnackbar(true);
    }
  };

  const closeMap = () => {
    setMapOpen(false);
  };

  const openTagDialog = useCallback(() => {
    setTagDialogOpen(true);
  }, []);

  const closeTagDialog = useCallback(() => {
    setTagDialogOpen(false);
  }, []);

  const configTagDialog = (tag: string | null) => {
    changeSessionTag(tag);
    closeTagDialog();
  };

  return (
    <>
      <div className="stream-toolbar__container">
        <div className="stream-toolbar__main">
          <CircleIconButton
            icon={microphoneElement.icon}
            type={microphoneElement.buttonType}
            description={t(`TOOLBAR:close_session`)}
            onClick={onLeave}
          />
          <div className="stream-toolbar__main__room-name">{selectedRoom.name}</div>
          <div className="stream-toolbar__main__separator"></div>
          <div className="stream-toolbar__main__tag">
            <Button startIcon={<SellOutlinedIcon />} size="medium" onClick={openTagDialog}>
              Tag: <span className="stream-toolbar__main__tag__tags">{sessionTag}</span>
            </Button>
          </div>
          {canRecordSession && (
            <Fragment>
              <div className="stream-toolbar__main__separator"></div>
              <div className="stream-toolbar__main__status">
                <Tooltip
                  title={isRecording ? t(`TOOLBAR:stop_recording`) : t(`TOOLBAR:start_recording`)}
                  placement="bottom">
                  <Button
                    size="medium"
                    startIcon={isRecording ? <FiberManualRecordIcon /> : <RadioButtonCheckedIcon />}
                    className={
                      'text-transform--none ' +
                      (isRecording ? 'stream-toolbar__button--recording' : '') +
                      (currentRoom.expiredAt !== null ? 'hidden-recording' : '')
                    }
                    onClick={toggleRecording}>
                    {isRecording ? t(`TOOLBAR:stop_recording`) : t(`TOOLBAR:record_meeting`)}
                  </Button>
                </Tooltip>
              </div>
            </Fragment>
          )}
        </div>
        <div className="stream-toolbar__tools">
          <Tooltip title={t('TOOLTIP:open_map')} placement="bottom">
            <IconButton className="stream-toolbar__tools__button">
              <MapIcon fontSize="small" onClick={openMap} />
            </IconButton>
          </Tooltip>
          <Tooltip
            title={t('TOOLTIP:media')}
            placement="bottom"
            className={currentRoom.expiredAt !== null ? 'hidden-recording' : ''}>
            <div>
              <IconButton
                className="stream-toolbar__tools__button"
                onClick={() =>
                  selectedRoom.storageId === null ? noMediaAttached() : openMedia([])
                }>
                <PermMediaIcon fontSize="small" />
              </IconButton>
            </div>
          </Tooltip>
          <Tooltip title={t('TOOLTIP:open_chat')} placement="bottom">
            <IconButton className="stream-toolbar__tools__button">
              {notifyMessage && <div className="notification-dot"></div>}
              <MessageIcon fontSize="small" onClick={openChat} />
            </IconButton>
          </Tooltip>
          <Tooltip title={t('TOOLTIP:show_participants')} placement="bottom">
            <IconButton className="stream-toolbar__tools__button" onClick={openParticipants}>
              <PersonIcon fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
        <Snackbar
          show={showSnackbar}
          duration={2000}
          text={t('SNACKBAR:telestrator_error')}
          onClose={() => setShowSnackbar(false)}
        />
      </div>
      <MapDialog
        open={openMapStatus}
        onClose={closeMap}
        roomId={selectedRoom.id}
        projectId={projectId}
      />
      <TagDialog
        open={openTagDialogStatus}
        tag={sessionTag}
        onClose={closeTagDialog}
        onConfirm={configTagDialog}
      />
      <div className="div-snackbar">
        <Snackbar
          show={valueSnackbar}
          text={t('SNACKBAR:' + valueStateSnackbar)}
          onClose={() => dispatch(setSnackbar(''))}
          duration={2000}
        />
      </div>
    </>
  );
};

export default React.memo(StreamToolbar);
