import { FC, memo, ReactNode, Suspense, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetAllOrganization } from '../../shared/core/api/hook-call/organization.hook';
import { useGetRoomByCode } from '../../shared/core/api/hook-call/room.hook';
import { useConfig } from '../../shared/core/context/provider/ConfigProvider';
import { useOpenviduLocal } from '../../shared/core/context/provider/openvidu/OpenviduLocalProvider';
import { useOpenviduScreenShare } from '../../shared/core/context/provider/openvidu/OpenviduScreenShareProvider';
import { VisionApiEntityAnnotation } from '../../shared/core/models/vision-api/entities/annotations/entity';
import { Media } from '../../shared/core/models/vision-api/entities/media.interface';
import useSocket from '../../shared/hooks/socket/useSocket';
import Snackbar from '../../shared/ui/Snackbar/Snackbar';
import { selectCountUnreaded } from '../../store/chat/chatSelectors';
import { setSessionId } from '../../store/openvidu/openviduReducer';
import { selectCanRecordSession, selectIsRecording } from '../../store/openvidu/openviduSelectors';
import {
  setOrganizations,
  setSelectedOrganization
} from '../../store/organizations/organizationReducer';
import { selectSelectedOrganization } from '../../store/organizations/organizationSelectors';
import { setRoom } from '../../store/rooms/roomReducer';
import { selectRoom, selectStorage } from '../../store/rooms/roomSelectors';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../store/store';
import { setTelestrator } from '../../store/telestrator/telestratorReducer';
import { selectTelestrator } from '../../store/telestrator/telestratorSelector';
import ChatContainer from '../chat/ChatContainer';
import MediaContainer from '../media/MediaContainer';
import Nav from '../nav/nav';
import ObjectDetectionContainer from '../object-detection/ObjectDetectionContainer';
import ParticipantListContainer from '../participants/ParticipantListContainer';
import StreamError from '../stream-error/StreamError';
import StreamToolbar from '../stream-toolbar/stream-toolbar';
import Stream from '../stream/Stream';
import './Main.scss';

type IMainProps = {
  sessionId: string;
  projectId: string;
};

const Main: FC<IMainProps> = ({ sessionId, projectId }): JSX.Element => {
  const { url } = useConfig();
  const storageId = useAppSelector(selectStorage);
  const { organizations, fetchOrganizations } = useGetAllOrganization();
  const { room, fetchRoom, fetchRoomError } = useGetRoomByCode(sessionId, projectId);
  const { startRecording, stopRecording, leave } = useOpenviduLocal();
  const currentRoom = useAppSelector(selectRoom);
  const canRecordSession = useAppSelector(selectCanRecordSession);
  const countOfUnreadedMessages = useAppSelector(selectCountUnreaded);
  const { leave: stopScreenShare } = useOpenviduScreenShare();
  const { join: joinSocket, leave: leaveSocket } = useSocket();
  const dispatch: AppDispatch = useAppDispatch();
  const [component, setComponent] = useState<JSX.Element | ReactNode | null>(null);
  const [isParticipantListOpen, setIsParticipantListOpen] = useState<boolean>(false);
  const [annotations, setAnnotations] = useState<Array<VisionApiEntityAnnotation> | null>(null);
  const isRecording = useAppSelector(selectIsRecording);
  const [rendered, setRendered] = useState(false);
  const [snackBarText, setSnackbarText] = useState<string | null>(null);
  const [media, setMedia] = useState<Array<Media> | null>(null);
  const organizationId = useAppSelector(selectSelectedOrganization);
  const [showSnackbar, setShowSnackbar] = useState<boolean>(false);
  const telestrator = useAppSelector(selectTelestrator);
  const { t } = useTranslation();

  useEffect(() => {
    fetchOrganizations();
    fetchRoom();
    dispatch(setSessionId(sessionId));
    dispatch(setRoom());
    setRendered(true);

    const idOrganization = JSON.parse(
      window.atob(sessionStorage.getItem('organizations'))
    ).idSelectedNav;
    dispatch(setSelectedOrganization(idOrganization));
  }, []);

  useEffect(() => {
    if (currentRoom !== null && currentRoom) {
      joinSocket(currentRoom.id);
      return () => {
        leaveSocket(currentRoom.id);
      };
    } else {
      return;
    }
  }, [currentRoom]);

  useEffect(() => {
    if (organizations && organizations.length > 0) {
      dispatch(setOrganizations(organizations));
    }
  }, [organizations]);

  useEffect(() => {
    if (room) {
      dispatch(setTelestrator({ isActive: false }));
      dispatch(setRoom(room));
    }
  }, [room]);

  useEffect(() => {
    if (fetchRoomError !== null) {
      setSnackbarText('Error occurs while fetching room');
    }
  }, [fetchRoomError]);

  useEffect(() => {
    if (rendered) {
      if (isRecording) {
        setSnackbarText(t('TOOLBAR:recording_started'));
      } else {
        setSnackbarText(t('TOOLBAR:recording_stopped'));
      }
    }
  }, [isRecording]);

  const closeComponent = useCallback(() => {
    setComponent(null);
    setIsParticipantListOpen(false);
  }, []);

  const leaveSession = useCallback(() => {
    leave();
    stopScreenShare();
    if (currentRoom) {
      leaveSocket(currentRoom.id);
    }
    window.location.replace(`${url.base}remote/${projectId}`);
  }, [currentRoom]);

  const chatComponent = useMemo(() => {
    return (
      <Suspense fallback={<div>Loading</div>}>
        <ChatContainer onExit={closeComponent} />
      </Suspense>
    );
  }, []);

  const openChat = () => {
    if (telestrator.isActive === false) {
      setShowSnackbar(false);
      setComponent(chatComponent);
      setIsParticipantListOpen(false);
    } else {
      setShowSnackbar(true);
    }
  };

  const participantComponent = useMemo(() => {
    return (
      <Suspense fallback={<div>Loading</div>}>
        <ParticipantListContainer onExit={closeComponent} openChat={openChat} />
      </Suspense>
    );
  }, []);

  const objectDetectionComponent = useMemo(() => {
    return <ObjectDetectionContainer onExit={closeComponent} labels={annotations} />;
  }, [annotations]);

  useEffect(() => {
    if (annotations !== null) {
      setComponent(objectDetectionComponent);
      setIsParticipantListOpen(false);
    }
  }, [annotations]);

  const mediaComponent = useMemo(() => {
    return <MediaContainer onExit={closeComponent} media={media} storageId={storageId} />;
  }, [media, storageId]);

  useEffect(() => {
    if (media !== null) {
      setComponent(mediaComponent);
      setIsParticipantListOpen(false);
    }
  }, [media]);

  const openObjectDetection = (labels: Array<VisionApiEntityAnnotation>) => {
    setAnnotations(labels);
  };

  const startOpenviduRecording = useCallback(() => {
    startRecording();
  }, []);

  const stopOpenviduRecording = useCallback(() => {
    stopRecording();
  }, []);

  const openMedia = (ArrayMedia: Array<Media>) => {
    if (telestrator.isActive === false) {
      setShowSnackbar(false);
      setMedia(ArrayMedia);
    } else {
      setShowSnackbar(true);
    }
  };

  const openParticipants = () => {
    if (telestrator.isActive === false) {
      setShowSnackbar(false);
      setComponent(participantComponent);
      setIsParticipantListOpen(true);
    } else {
      setShowSnackbar(true);
    }
  };

  return (
    <div className="container">
      <div className="main-container">
        <Nav onLogout={leaveSession} />
        {fetchRoomError && <StreamError exitFromApp={leaveSession} />}
        {currentRoom && (
          <>
            <Stream
              isParticipantListOpen={isParticipantListOpen}
              roomId={currentRoom.id}
              sessionId={sessionId}
              sideComponent={component}
              onObjectDetected={openObjectDetection}
            />
            <StreamToolbar
              openParticipants={openParticipants}
              openChat={openChat}
              onLeave={leaveSession}
              openMedia={openMedia}
              notifyMessage={countOfUnreadedMessages > 0}
              selectedRoom={currentRoom}
              projectId={projectId}
              organizationId={organizationId}
              isRecording={isRecording}
              canRecordSession={canRecordSession}
              onStartRecording={startOpenviduRecording}
              onStopRecording={stopOpenviduRecording}
              noMediaAttached={() => setSnackbarText('No media attached to room')}
            />
          </>
        )}
        <Snackbar
          show={snackBarText !== null}
          text={snackBarText}
          onClose={() => setSnackbarText(null)}
        />
        <Snackbar
          show={showSnackbar}
          duration={2000}
          text={t('SNACKBAR:telestrator_error')}
          onClose={() => setShowSnackbar(false)}
        />
      </div>
    </div>
  );
};

export default memo(Main);
