import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ISnackbar {
  text: string;
}

export type SnackbarState = {
  all: string;
};

const initialStateSnackbar: SnackbarState = {
  all: ''
};

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState: initialStateSnackbar,
  reducers: {
    setSnackbar: (state, action: PayloadAction<string>) => {
      state.all = action.payload;
    }
  }
});

export const { setSnackbar } = snackbarSlice.actions;

export default snackbarSlice.reducer;
