import { Divider, Grid, IconButton, Tab, Tabs } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import './ChatContainerHeader.scss';

type IChatContainerHeaderProps = {
  onExit: () => void;
  onSelectChat: (value: number) => void;
  selectedChat?: number;
  hasPublicUnread: boolean;
  hasPrivateUnread: boolean;
};

const ChatContainerHeader: FC<IChatContainerHeaderProps> = ({
  selectedChat = 0,
  hasPublicUnread,
  hasPrivateUnread,
  onSelectChat,
  onExit
}): JSX.Element => {
  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    onSelectChat(newValue);
  };
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      alignContent="stretch"
      wrap="nowrap">
      <Grid item xs={11}>
        <Tabs
          value={selectedChat}
          onChange={handleChange}
          aria-label="Select chat"
          centered
          className="chat-header__tabs">
          <Tab
            label={
              <span className="chat-header__tab">
                {hasPublicUnread && <div className="notification-dot"></div>}
                {t('CHAT_CONTAINER:room_chat')}
              </span>
            }
            wrapped={true}
            className="chat-header__label"
          />
          <Tab
            label={
              <span className="chat-header__tab">
                {hasPrivateUnread && <div className="notification-dot"></div>}
                {t('CHAT_CONTAINER:private_chat')}
              </span>
            }
            wrapped={true}
            className="chat-header__label"
          />
        </Tabs>
        <Divider />
      </Grid>
      <Grid item xs={1} container direction="column" alignContent="stretch" justifyContent="center">
        <Grid item>
          <IconButton onClick={onExit} size="small" color="primary">
            <CloseIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default memo(ChatContainerHeader);
