import CloseIcon from '@mui/icons-material/Close';
import { ChangeEvent, FC, Fragment, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useOpenviduLocal } from '../../shared/core/context/provider/openvidu/OpenviduLocalProvider';
import TabPanel from '../../shared/ui/TabPanel/TabPanel';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../store/store';
import ChatContainerHeader from './ChatContainerHeader';
import ChatParticipantList from './ChatParticipantList';

import { Grid, IconButton, Snackbar } from '@material-ui/core';
import { triggerBase64Download } from 'react-base64-downloader';
import { useTranslation } from 'react-i18next';
import { MessageChat } from '../../shared/core/models/message.model';
import useChatMessages from '../../shared/hooks/socket/useChatMessages';
import useFile from '../../shared/hooks/useFile';
import { selectId } from '../../store/auth/authSelectors';
import {
  setReadedAllPrivateMessages,
  setReadedAllPublicMessages
} from '../../store/chat/chatReducer';
import {
  selectCountPrivateUnread,
  selectCountPublicUnreaded
} from '../../store/chat/chatSelectors';
import { selectParticipant } from '../../store/participant/participantSelectors';
import { selectRoom, selectTag } from '../../store/rooms/roomSelectors';
import { getState } from '../../store/storeSelectors';
import './ChatContainer.scss';
import ChatInput from './ChatInput';
import ChatMessageList from './ChatMessageList';

type IChatContainerProps = {
  onExit: () => void;
};

const ChatContainer: FC<IChatContainerProps> = ({ onExit }): JSX.Element => {
  const fileInputRef = useRef<HTMLInputElement>();

  const { id: roomId } = useAppSelector(selectRoom);

  const me = useAppSelector(selectId);

  const { t } = useTranslation();

  const dispatch: AppDispatch = useAppDispatch();
  const store = useAppSelector(getState);
  const countOfUnreadPublic = selectCountPublicUnreaded(store, roomId);
  const countOfUnreadPrivate = selectCountPrivateUnread(store, roomId);
  const sessionTag = useAppSelector(selectTag);

  const listParticipants = useAppSelector(selectParticipant);

  const { participants } = useOpenviduLocal();
  const { sendFile } = useFile();
  const { messages, sendChatMessage } = useChatMessages(roomId, false);

  const [chatTab, setChatTab] = useState<number>(0);
  const [selectedChat, setSelectedChat] = useState<string | null>(roomId);
  const [acceptType] = useState<string>('image/*,application/*,text/*');
  const [showSnackbarFile, setShowSnackbarFile] = useState<boolean>(false);

  const [showSnackbarFileError, setShowSnackbarFileError] = useState<boolean>(false);

  const [activeUser, setActiveUser] = useState<boolean>(false);

  useEffect(() => {
    setSelectedChat(chatTab === 0 ? roomId : null);
  }, [chatTab]);

  const readAllMessages = () => {
    if (selectedChat !== null) {
      if (chatTab === 0) {
        dispatch(setReadedAllPublicMessages({ recipient: selectedChat }));
      } else {
        dispatch(setReadedAllPrivateMessages({ recipient: selectedChat }));
      }
    }
  };

  useEffect(() => {
    readAllMessages();
  }, [chatTab, selectedChat]);

  const messagesOfCurrentPublicChat = useMemo(() => {
    return [...messages].filter((m) => m.recipient === selectedChat);
  }, [messages, selectedChat]);

  const messagesOfCurrentPrivateChat = useMemo(() => {
    return [...messages].filter(
      (m) =>
        (m.recipient === selectedChat && m.sender === me) ||
        (m.recipient === me && m.sender === selectedChat)
    );
  }, [messages, selectedChat]);

  const onParticipantSelect = (participant: string) => {
    if (participants.find((p) => p.id === participant)) {
      setActiveUser(false);
    } else {
      setActiveUser(true);
    }
    setSelectedChat(participant);
  };

  const onSendMessage = (body: string) => {
    if (selectedChat !== null) {
      sendChatMessage(body, selectedChat);
    }
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;
    if (files.length > 0 && files[0].size < 5e6) {
      sendFile(files[0], selectedChat, chatTab === 0, sessionTag)
        .then(() => {
          setShowSnackbarFile(true);
        })
        .catch((err) => {
          console.error(err);
        });
    } else {
      setShowSnackbarFileError(true);
    }
  };

  const attachFile = () => {
    fileInputRef.current.click();
  };

  const handleCloseSnackbar = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbarFile(false);
  };

  const handleCloseSnackbarFileError = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setShowSnackbarFileError(false);
  };

  const handleMessageClick = (message: MessageChat) => {
    if (message.attachment) {
      triggerBase64Download(message.attachment.url, message.attachment.name);
    }
  };

  return (
    <Fragment>
      <Grid
        container
        spacing={1}
        direction="column"
        justifyContent="space-between"
        alignItems="stretch"
        wrap="nowrap"
        className="chat-container">
        <Grid item>
          <ChatContainerHeader
            onExit={onExit}
            onSelectChat={setChatTab}
            selectedChat={chatTab}
            hasPublicUnread={countOfUnreadPublic > 0}
            hasPrivateUnread={countOfUnreadPrivate > 0}
          />
        </Grid>
        <Grid item style={{ flexGrow: 2 }}>
          <TabPanel value={chatTab} index={0} className="chat-tab__panel">
            <ChatMessageList
              messages={messagesOfCurrentPublicChat}
              onMessageClick={handleMessageClick}
            />
          </TabPanel>
          <TabPanel value={chatTab} index={1} className="chat-tab__panel">
            <ChatParticipantList
              participants={listParticipants}
              onParticipantSelect={onParticipantSelect}
            />
            <ChatMessageList
              messages={messagesOfCurrentPrivateChat}
              onMessageClick={handleMessageClick}
            />
          </TabPanel>
        </Grid>
        <Grid item>
          <ChatInput
            isActive={activeUser}
            disabled={selectedChat === null}
            onSend={onSendMessage}
            onAttachFile={attachFile}
          />
        </Grid>
      </Grid>
      <input
        type="file"
        accept={acceptType}
        onChange={onFileChange}
        className="input__file--hidden"
        ref={fileInputRef}
      />
      <Snackbar
        open={showSnackbarFile}
        autoHideDuration={6000}
        message={t('CHAT_CONTAINER:file_sent')}
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      <Snackbar
        open={showSnackbarFileError}
        autoHideDuration={6000}
        message={t('CHAT_CONTAINER:error_file')}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbarFileError}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Fragment>
  );
};

export default memo(ChatContainer);
