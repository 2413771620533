import React, { FC } from 'react';
import { CommandType } from '../../../../shared/core/models/socket/event.model';
import { ParticipantKind, ParticipantType } from '../../../../shared/enum/participant-type.enum';
import './ParticipantControlsFooter.scss';
import ParticipantControlsLocal from './ParticipantControlsLocal';
import ParticipantControlsRemote from './ParticipantControlsRemote';
import ParticipantTelestratorFooterControls from './ParticipantTelestratorFooterControls';

type IParticipantControlsFooterProps = {
  kind: ParticipantKind;
  type: ParticipantType;
  hasAudio: boolean;
  hasVideo: boolean;
  hasTelestrator: boolean;
  hasPip: boolean;
  hasScreenShare: boolean;
  miniature?: boolean;
  toggleMicrophone: () => void;
  toggleCamera: () => void;
  fullScreen: () => void;
  pictureInPicture: () => void;
  toggleScreenShare: () => void;
  telestratorClose: () => void;
  telestratorClear: () => void;
  telestratorSend: () => void;
  detectObject: () => void;
  attachFile: () => void;
  onRemoteCommand: (command: CommandType) => void;
};

const ParticipantControlsFooter: FC<IParticipantControlsFooterProps> = ({
  type,
  kind,
  hasAudio,
  hasVideo,
  hasPip,
  hasScreenShare,
  hasTelestrator,
  miniature = false,
  toggleMicrophone,
  toggleCamera,
  toggleScreenShare,
  fullScreen,
  pictureInPicture,
  telestratorClear,
  telestratorClose,
  telestratorSend,
  detectObject,
  attachFile,
  onRemoteCommand
}): JSX.Element => {
  const renderControls = (participantKind: ParticipantKind): JSX.Element | null => {
    switch (participantKind) {
      case ParticipantKind.LOCAL:
        return (
          <ParticipantControlsLocal
            audio={hasAudio}
            video={hasVideo}
            screenShare={hasScreenShare}
            isScreenCasting={false}
            toggleMicrophone={toggleMicrophone}
            toggleCamera={toggleCamera}
            toggleScreenShare={toggleScreenShare}
          />
        );
      case ParticipantKind.SCREEN:
      case ParticipantKind.REMOTE:
        return hasTelestrator ? (
          <ParticipantTelestratorFooterControls
            cancel={telestratorClose}
            restore={telestratorClear}
            send={telestratorSend}
          />
        ) : (
          <ParticipantControlsRemote
            type={type}
            kind={kind}
            audio={hasAudio}
            hasPip={hasPip}
            miniature={miniature}
            toggleRemoteMicrophone={toggleMicrophone}
            toggleCamera={() => onRemoteCommand(CommandType.SWITCH_CAMERA)}
            pictureInPicture={pictureInPicture}
            fullScreen={fullScreen}
            detectObject={detectObject}
            attachFile={attachFile}
          />
        );
      default:
        return null;
    }
  };

  return <React.Fragment>{renderControls(kind)}</React.Fragment>;
};

export default React.memo(ParticipantControlsFooter);
