import { last } from 'lodash';
import { useEffect } from 'react';
import { selectMe } from '../../store/auth/authSelectors';
import { selectRoom } from '../../store/rooms/roomSelectors';
import { useAppSelector } from '../../store/store';
import { UserProvider } from '../enum/user-provider.enum';
import useFileMessages from './socket/useFileMessages';
import useUploader from './uploader/useUploader';

type IUseFile = {
  sendFile: (file: File, recipient: string, persist: boolean, tag: string | null) => Promise<void>;
  sendSnapshot: (base64: string, recipient: string, tag: string | null) => Promise<void>;
};

export default function useFile(): IUseFile {
  const room = useAppSelector(selectRoom);
  const { sendFileMessage, setContext } = useFileMessages(room.id);
  const me = useAppSelector(selectMe);
  const { uploadFile } = useUploader();

  useEffect(() => {
    if (room) {
      setContext(room.id);
    }
  }, [room]);

  const toBase64 = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const extractSnapshotName = (base64: string): string => {
    const splittedMimeArray = base64.split(';')[0].split('/');
    const fileName = `snapshot_`
      .concat(new Date().toLocaleString())
      .concat('.' + last(splittedMimeArray));
    return fileName.replace('/', '_').replace('/', '_').replace(', ', '_');
  };

  const toFile = async (dataUrl: string): Promise<File> => {
    const fileName = extractSnapshotName(dataUrl);
    let mime: string | undefined;
    const arr = dataUrl.split(',');
    const mimeMatch = arr[0].match(/:(.*?);/);
    if (mimeMatch) {
      mime = mimeMatch[1];
    }
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: mime ?? '*/*' });
  };

  const sendFile = async (file: File, recipient: string, persist: boolean, tag: string | null) => {
    const base64 = await toBase64(file);
    sendFileMessage(
      {
        base64,
        name: file.name,
        mimeType: file.type
      },
      recipient
    );
    if (me.provider === UserProvider.GOOGLE && room.expiredAt === null && persist) {
      uploadFile(file, tag);
    }
  };

  const sendSnapshot = async (base64: string, recipient: string, tag: string | null) => {
    const file = await toFile(base64);
    sendFile(file, recipient, true, tag);
  };

  return {
    sendFile,
    sendSnapshot
  };
}
