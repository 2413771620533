import { OpenVidu, Publisher, Session } from 'openvidu-browser';
import { useEffect, useState } from 'react';
import { selectId, selectMyRole, selectName } from '../../../../../store/auth/authSelectors';
import { useAppSelector } from '../../../../../store/store';
import { ParticipantType } from '../../../../enum/participant-type.enum';
import { IOpenviduScreenShareProvider } from '../../../models/provider/openvidu.provider.model';
import { useProvideOpenviduConnection } from './useProvideOpenviduConnection';

/**
 * Provides of business logic to handle screen share with openvidu.
 *
 * Special thanks to @Mirko_Pensato
 *
 * In this splace we rembember his pain and his blood, while he was fighting with old Angular project, to save us
 * from this monstrous feature.
 * Here we are going to honor his work. Time to implement this feat: 30minutes
 *
 * @param OV OpenVidu Instance
 * @returns Features
 */
export function useProvideOpenviduScreenShare(
  OV: OpenVidu,
  projectId: string
): IOpenviduScreenShareProvider {
  const { getToken } = useProvideOpenviduConnection();
  const publisherName = useAppSelector(selectName);
  const id: string | null = useAppSelector(selectId);
  const roleId: string | null = useAppSelector(selectMyRole);
  const [session, setSession] = useState<{ current: Session; id: string } | null>(null);

  const [publisher, setPublisher] = useState<Publisher | null>(null);

  /**
   * Stop current screenshare if 'Stop sharing' is clicked on browser
   */
  useEffect(() => {
    if (publisher !== null) {
      publisher.once('accessAllowed', () => {
        publisher.stream
          .getMediaStream()
          .getVideoTracks()[0]
          .addEventListener('ended', () => {
            leave();
          });
      });
    }
  }, [publisher]);

  /**
   * Start of screenshare
   */
  useEffect(() => {
    async function init() {
      if (session !== null) {
        try {
          const me = OV.initPublisher('', {
            audioSource: false,
            videoSource: 'screen',
            publishAudio: false,
            publishVideo: true,
            resolution: '640x480',
            frameRate: 30,
            insertMode: 'APPEND',
            mirror: false
          });

          const token = await getToken(session?.id);
          await session?.current.connect(token, {
            clientData: `${publisherName} screen share`,
            id,
            roleId,
            type: ParticipantType.SCREEN,
            projectId
          });
          await session?.current.publish(me);

          setPublisher(me);
        } catch (e) {
          console.error('There was an error connecting to the session:', e);
        }
      }
    }

    init();
  }, [session]);

  /**
   * Join to a session
   * @param room Id of room to join
   */
  const join = async (room: string) => {
    setSession({
      current: OV.initSession(),
      id: room
    });
  };

  /**
   * Leave current session
   */
  const leave = () => {
    session?.current.disconnect();
    setPublisher(null);
  };

  return {
    publisher,
    join,
    leave
  };
}
