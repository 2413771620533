import { Button, Menu, MenuItem, MenuProps, Toolbar } from '@material-ui/core';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import LogoutIcon from '@mui/icons-material/Logout';
import { styled } from '@mui/material/styles';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import packageJson from '../../../package.json';
import i18n from '../../assets/style/assets/i18n/i18n';
import { useGetRoles } from '../../shared/core/api/hook-call/role.hook';
import useAssetUrl from '../../shared/hooks/useAssetUrl';
import { selectMe } from '../../store/auth/authSelectors';
import { setRole } from '../../store/role/roleReducer';
import { selectAllRole } from '../../store/role/roleSelectors';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../store/store';
import GenericDialog from '../generic-dialog/genericDialog';
import './nav.scss';
type INavProps = {
  onLogout: () => void;
};

export interface SimpleDialogProps {
  text: string;
  subText: string;
  open: boolean;
  onClose: () => void;
}

const Nav: FC<INavProps> = ({ onLogout }) => {
  const version = packageJson.version;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { roles, fetchRoles } = useGetRoles();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
    localStorage.setItem('language', lng);
    handleClose();
  };

  const logout = () => {
    onLogout();
    sessionStorage.removeItem('coreAuth');
    navigate('/');
  };

  const { getAsset } = useAssetUrl();
  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      transformOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      {...props}
    />
  ))(() => ({}));
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const dispatch: AppDispatch = useAppDispatch();
  const allRoles = useAppSelector(selectAllRole);
  const [roleUser, setUserRole] = React.useState(null);
  const currentUser = useAppSelector(selectMe);

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClickOpen = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    i18n.changeLanguage(localStorage.getItem('language'));
  }, []);

  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    if (roles) {
      dispatch(setRole(roles));
    }
  }, [roles]);

  useEffect(() => {
    if (currentUser) {
      allRoles.map((role) => {
        if (role.id === currentUser.role) {
          setUserRole(role.name);
        }
      });
    }
  });

  return (
    <div className="container-toolbar">
      <Toolbar>
        <div className="container-toolbar__logo">
          <img
            src={getAsset('logo_augmentya.png')}
            alt="logo cloudvision"
            className="logo-cloudvision-toolbar"
          />
        </div>

        <div>
          <Button onClick={handleClick}>
            <img
              src={getAsset('avatar.svg')}
              alt="avatar image"
              className="user-menu__avatar-icon"
            />
            <img
              src={getAsset('drawer_user.svg')}
              alt="drawer icon"
              className="user-menu__drawer-icon"
            />
          </Button>
          <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
            <div className="container-menu-info-user">
              <span className="span-item-role">{roleUser}</span>
              <div className="div-container-first-last-name">
                <span className="span-item-first-name">{currentUser.firstName}</span>
                <span className="span-item-last-name">{currentUser.lastName}</span>
              </div>
              <span className="span-item-email">{currentUser.email}</span>
            </div>
            <div className="container-select-language">
              <div className="container-icon-item">
                <img src={getAsset('globe.svg')} alt="globe" className="globe-icon" />
                <MenuItem className="menu-item menu-item-select-language" onClick={handleClose}>
                  {t('DROPDOWN:select_language')}
                </MenuItem>
              </div>
              <div
                className="container-icon-item"
                onClick={() => {
                  changeLanguage('en');
                }}>
                <img src={getAsset('english_flag.png')} alt="english flag" className="flag-icon" />
                <MenuItem className="menu-item">{t('DROPDOWN:english')}</MenuItem>
              </div>
              <div
                className="container-icon-item"
                onClick={() => {
                  changeLanguage('it');
                }}>
                <img src={getAsset('italian_flag.png')} alt="italian flag" className="flag-icon" />
                <MenuItem className="menu-item">{t('DROPDOWN:italian')}</MenuItem>
              </div>
            </div>
            <div className="container-info-logout">
              <div className="container-icon-item" onClick={handleClickOpen}>
                <HelpOutlineIcon className="icon" />
                <MenuItem className="menu-item" onClick={handleClose}>
                  {t('DROPDOWN:info')}
                </MenuItem>
              </div>
              <div className="container-icon-item" onClick={logout}>
                <LogoutIcon className="icon" />
                <MenuItem className="menu-item">{t('DROPDOWN:logout')}</MenuItem>
              </div>
            </div>
          </StyledMenu>
          <GenericDialog
            open={openDialog}
            text={`MF-Stream-${version}`}
            subText={`${t('DIALOG_VERSION:support')}support@augmentya.com`}
            onClose={handleCloseDialog}
          />
        </div>
      </Toolbar>
    </div>
  );
};

export default React.memo(Nav);
