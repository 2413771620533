import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import './ParticipantListCommand.scss';

const ParticipantListCommand: FC<unknown> = (): JSX.Element => {
  const { t } = useTranslation();
  return <div className="command-list">{t('PARTICIPANT_LIST:command')}</div>;
};

export default memo(ParticipantListCommand);
