import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React, { FC } from 'react';

const TelestratorIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="-4 -4 42 42">
      <path d="M3.55555556,0 C1.58222222,0 0,1.58222222 0,3.55555556 L0,28.4444444 C0,30.4081236 1.59187645,32 3.55555556,32 L28.4444444,32 C30.4081236,32 32,30.4081236 32,28.4444444 L32,16 L28.4444444,16 L28.4444444,28.4444444 L3.55555556,28.4444444 L3.55555556,3.55555556 L16,3.55555556 L16,0 L3.55555556,0 M26.2755556,1.77777778 C25.9733333,1.77777778 25.6533333,1.90222222 25.4222222,2.13333333 L23.2533333,4.28444444 L27.6977778,8.72888889 L29.8666667,6.57777778 C30.3288889,6.11555556 30.3288889,5.33333333 29.8666667,4.88888889 L27.1111111,2.13333333 C26.88,1.90222222 26.5777778,1.77777778 26.2755556,1.77777778 M21.9911111,5.54666667 L8.88888889,18.6666667 L8.88888889,23.1111111 L13.3333333,23.1111111 L26.4355556,9.99111111 L21.9911111,5.54666667 Z" />
    </SvgIcon>
  );
};

export default React.memo(TelestratorIcon);
