import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  TextField,
  Typography
} from '@material-ui/core';
import { FC, memo, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

type ITagDialogProps = {
  tag: string | null;
  open: boolean;
  onClose: () => void;
  onConfirm: (tag: string | null) => void;
};

const TagDialog: FC<ITagDialogProps> = ({ tag, open, onClose, onConfirm }): JSX.Element => {
  const [maxWidth] = useState<DialogProps['maxWidth']>('sm');
  const [newTag, setNewTag] = useState<string | null>(tag);

  const { t } = useTranslation();

  const handleClose = useCallback(() => {
    onClose();
  }, []);

  const handleConfirm = useCallback(() => {
    onConfirm(newTag);
  }, [newTag]);

  const handleTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length === 0) {
      setNewTag(tag);
    } else {
      setNewTag(value);
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth={maxWidth} fullWidth={true}>
      <DialogTitle>{t('DIALOG_INVITATION:change_tag')}</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('DIALOG_INVITATION:change_tag_text')}
          <Typography variant="body2">{t('DIALOG_INVITATION:change_tag_subtext')}</Typography>
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="tag"
          label={t('DIALOG_INVITATION:tag')}
          type="text"
          fullWidth
          variant="standard"
          defaultValue={tag}
          onChange={handleTextChange}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>{t('DIALOG_INVITATION:cancel')}</Button>
        <Button onClick={handleConfirm} variant="contained">
          {t('DIALOG_INVITATION:confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(TagDialog);
