import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type OpenviduState = {
  sessionId: string | null;
  recordingId: string | null;
};

const initialStateOpenvidu: OpenviduState = {
  sessionId: null,
  recordingId: null
};

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const openviduSlice = createSlice({
  name: 'openvidu',
  initialState: initialStateOpenvidu,
  reducers: {
    setSessionId: (state, action: PayloadAction<string>) => {
      state.sessionId = action.payload;
    },
    startRecording: (state, action: PayloadAction<string>) => {
      state.recordingId = action.payload;
    },
    stopRecording: (state) => {
      state.recordingId = null;
    }
  }
});

export const { setSessionId, startRecording, stopRecording } = openviduSlice.actions;
export default openviduSlice.reducer;
