import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PolicyType } from '../../shared/enum/policies.enum';
import { UserProvider } from '../../shared/enum/user-provider.enum';

export interface IUser {
  readonly id: string | null;
  readonly email: string | null;
  readonly username: string | null;
  readonly lastName: string | null;
  readonly enabled: boolean;
  readonly firstName: string | null;
  readonly provider: UserProvider;
  readonly organization: string | null;
  readonly policies: Array<PolicyType>;
  readonly role: string | null;
  readonly lastLogin: string | null;
  readonly expiresAt: Date | null;
}

export type UserState = {
  all: IUser[];
  allOrganization: IUser[];
};

const initialStateUser: UserState = {
  all: [],
  allOrganization: []
};

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const userSlice = createSlice({
  name: 'users',
  initialState: initialStateUser,
  reducers: {
    setUsers: (state, action: PayloadAction<IUser[]>) => {
      state.all = action.payload;
    },
    setUsersOrganization: (state, action: PayloadAction<IUser[]>) => {
      state.allOrganization = action.payload;
    }
  }
});

export const { setUsers, setUsersOrganization } = userSlice.actions;
export default userSlice.reducer;
