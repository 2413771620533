import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React, { FC } from 'react';

const TraitThickIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 25 21">
      <g id="03_Remote_Telestrator" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-643.000000, -406.000000)" fill="#000000" fillRule="nonzero">
          <g transform="translate(643.781114, 406.136894)">
            <g transform="translate(11.795628, 10.066725) rotate(106.000000) translate(-11.795628, -10.066725) translate(4.218886, -0.031664)">
              <path d="M13.8731529,13.4647224 C12.3745097,11.3598865 10.3033511,9.91175936 8.21535389,8.5141483 C6.73354939,7.5711818 5.3696157,6.39247367 4.27510101,5.04537867 C3.80361776,4.48970198 2.84381257,3.46254205 3.82045645,3.2604778 C4.81393901,3.05841355 6.53148514,4.03505742 7.40709689,4.40550855 C8.93941745,5.04537867 10.4548993,5.78628092 11.8693491,6.66189267 C13.1806002,6.82911049 13.9572541,6.60640855 14.199311,5.99378685 C14.4476874,5.41502246 14.2379359,4.68353211 13.5700565,3.7993158 C10.9432213,2.06493098 7.57548377,0.532610419 4.44348789,0.0779658563 C2.65858701,-0.191453144 0.772654011,0.178997981 0.16646126,2.11544705 C-0.37237674,3.78247711 0.486396323,5.46634586 1.4630402,6.77976349 C3.76994039,9.8612433 7.35658083,11.3430478 10.0339321,14.0035604 C10.6064475,14.5592371 11.2968337,15.2159459 11.6336075,15.9905256 C11.9872199,16.7314278 11.9030265,16.7819439 11.1116081,16.7819439 C9.02361089,16.7819439 6.41361433,15.1485912 4.71290689,14.0709152 C3.67513309,13.8537776 2.98576557,14.0514505 2.64480432,14.663934 C2.24497275,15.2324697 2.36743779,15.9889891 3.01219945,16.9334921 C5.58851864,18.5163287 9.89922264,20.9916157 13.0480572,19.9476171 C16.0453436,18.768909 15.3886348,15.5190423 13.8731529,13.4647224 Z"></path>
            </g>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default React.memo(TraitThickIcon);
