import { Dialog, IconButton } from '@material-ui/core';
import CancelIcon from '@mui/icons-material/Cancel';
import { Icon, LatLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import moment from 'moment';
import React, { FC, useEffect, useState } from 'react';
import { MapContainer, Marker, Popup, TileLayer } from 'react-leaflet';
import { useGetRoomGeos } from '../../shared/core/api/hook-call/geo.hook';
import { useGetUsers } from '../../shared/core/api/hook-call/user.hook';
import useAssetUrl from '../../shared/hooks/useAssetUrl';
import { IGeo, setGeos } from '../../store/geo/geoReducer';
import { selectAllGeosWithUser } from '../../store/geo/geoSelectors';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../store/store';
import { setUsers } from '../../store/user/userReducer';
import './map-dialog.scss';

type IMapDialogProps = {
  open: boolean;
  roomId: string;
  projectId: string;
  onClose: () => void;
};

const MapDialog: FC<IMapDialogProps> = ({ roomId, projectId, open, onClose }) => {
  const startOfDay = moment().startOf('d').unix();
  const endOfDay = moment().endOf('d').unix();

  const dispatch: AppDispatch = useAppDispatch();
  const { getAsset } = useAssetUrl();
  const { geos, fetchGeos } = useGetRoomGeos(roomId, projectId, startOfDay, endOfDay);
  const { users, fetchUsers } = useGetUsers(projectId);
  const markers = useAppSelector(selectAllGeosWithUser);
  const [stateMarkers, setStateMarkers] = useState<IGeo[]>([]);

  useEffect(() => {
    if (open) {
      fetchGeos();
      fetchUsers();
    }
  }, [open]);

  useEffect(() => {
    if (geos) {
      dispatch(setGeos(geos));
    }
  }, [geos]);

  useEffect(() => {
    if (users) {
      dispatch(setUsers(users));
    }
  }, [users]);

  useEffect(() => {
    setStateMarkers(markers);
  }, [markers]);

  const markerIcon = new Icon({
    iconUrl: getAsset('map-marker.svg'),
    iconRetinaUrl: getAsset('map-marker.svg'),
    popupAnchor: [-0, -0],
    iconSize: [44, 44]
  });

  const handleClose = () => {
    onClose();
  };

  const position = new LatLng(41.781311, 12.269538);
  return (
    <Dialog onClose={handleClose} open={open} maxWidth="lg" fullWidth={true}>
      <div className="map-dialog">
        <MapContainer center={position} zoom={6}>
          <TileLayer
            url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
            maxZoom={30}
          />
          {stateMarkers.map((marker) => (
            <Marker
              key={marker.id}
              position={
                new LatLng(
                  parseFloat(marker.location.coordinates[1]),
                  parseFloat(marker.location.coordinates[0])
                )
              }
              icon={markerIcon}>
              <Popup>{marker.userName}</Popup>
            </Marker>
          ))}
        </MapContainer>
        <IconButton size="small" className="close-map" onClick={onClose}>
          <CancelIcon fontSize="large" />
        </IconButton>
      </div>
    </Dialog>
  );
};
export default React.memo(MapDialog);
