import React, { FC, useEffect, useState } from 'react';
import CircleIconButton from '../../../../shared/ui/CircleIconButton/CircleIconButton';
import './ParticipantControlsLocal.scss';
import MicOffIcon from '@mui/icons-material/MicOff';
import MicIcon from '@mui/icons-material/Mic';
import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffOutlinedIcon from '@mui/icons-material/VideocamOffOutlined';
import { IButtonElement } from '../../../../shared/core/models/props/button-element';
import ScreenShareIcon from '../../../../shared/ui/Icons/ScreenShareIcon';
import ScreenShareOffIcon from '../../../../shared/ui/Icons/ScreenShareOffIcon';
import { useTranslation } from 'react-i18next';

type IParticipantControlsLocalProps = {
  audio: boolean;
  video: boolean;
  screenShare: boolean;
  isScreenCasting: boolean;
  toggleMicrophone: () => void;
  toggleCamera: () => void;
  toggleScreenShare: () => void;
};

const ACTIVE_MIC: IButtonElement = {
  icon: <MicIcon />,
  description: 'disable_microphone',
  buttonType: 'primary'
};

const DISACTIVE_MIC: IButtonElement = {
  icon: <MicOffIcon />,
  description: 'enable_microphone',
  buttonType: 'alert'
};

const ACTIVE_CAM: IButtonElement = {
  icon: <VideocamIcon />,
  description: 'disable_camera',
  buttonType: 'primary'
};

const DISACTIVE_CAM: IButtonElement = {
  icon: <VideocamOffOutlinedIcon />,
  description: 'enable_camera',
  buttonType: 'alert'
};

const ACTIVE_SCREEN_SHARE: IButtonElement = {
  icon: <ScreenShareIcon />,
  description: 'share_screen',
  buttonType: 'primary'
};

const DISACTIVE_SCREEN_SHARE: IButtonElement = {
  icon: <ScreenShareOffIcon />,
  description: 'stop_share_screen',
  buttonType: 'alert'
};

const ParticipantControlsLocal: FC<IParticipantControlsLocalProps> = ({
  audio,
  video,
  screenShare,
  toggleMicrophone,
  toggleCamera,
  toggleScreenShare
}): JSX.Element => {
  const [microphoneElement, setMicrophoneElement] = useState<IButtonElement>(ACTIVE_MIC);
  const [cameraElement, setCameraElement] = useState<IButtonElement>(ACTIVE_CAM);
  const [screenShareElement, setScreenShareElement] = useState<IButtonElement>(ACTIVE_SCREEN_SHARE);
  const { t } = useTranslation();

  useEffect(() => {
    if (audio) {
      setMicrophoneElement(ACTIVE_MIC);
    } else {
      setMicrophoneElement(DISACTIVE_MIC);
    }
  }, [audio]);

  useEffect(() => {
    if (video) {
      setCameraElement(ACTIVE_CAM);
    } else {
      setCameraElement(DISACTIVE_CAM);
    }
  }, [video]);

  useEffect(() => {
    if (screenShare) {
      setScreenShareElement(DISACTIVE_SCREEN_SHARE);
    } else {
      setScreenShareElement(ACTIVE_SCREEN_SHARE);
    }
  }, [screenShare]);

  return (
    <div className="controls-local__container">
      <CircleIconButton
        icon={microphoneElement.icon}
        type={microphoneElement.buttonType}
        description={t(`PARTICIPANT_LIST:${microphoneElement.description}`)}
        onClick={toggleMicrophone}
      />
      <CircleIconButton
        icon={cameraElement.icon}
        type={cameraElement.buttonType}
        description={t(`PARTICIPANT_LIST:${cameraElement.description}`)}
        onClick={toggleCamera}
      />
      <CircleIconButton
        icon={screenShareElement.icon}
        type={screenShareElement.buttonType}
        description={t(`PARTICIPANT_LIST:${screenShareElement.description}`)}
        onClick={toggleScreenShare}
      />
    </div>
  );
};

export default React.memo(ParticipantControlsLocal);
