import { UserProvider } from '../../shared/enum/user-provider.enum';
import { getAuthState } from '../auth/authSelectors';
import { RootState } from '../store';
import { OpenviduState } from './openviduReducer';

export const getOpenviduState = (store: RootState): OpenviduState => store.openvidu;

/**
 * Select current session id
 *
 * @param state Store state
 * @returns Current session id
 */
export const selectSessionId = (state: RootState): string | null =>
  getOpenviduState(state).sessionId;

/**
 * Select if session have recording or not
 * @param state Store state
 * @returns if is recording or not
 */
export const selectIsRecording = (state: RootState): boolean =>
  getOpenviduState(state).recordingId !== null;

/**
 * Select recording id
 * @param state Store state
 * @returns Recording id or null
 */
export const selectRecording = (state: RootState): string | null =>
  getOpenviduState(state).recordingId;

export const selectCanRecordSession = (state: RootState): boolean =>
  getAuthState(state).me?.provider === UserProvider.GOOGLE;
