import { IconButton, Snackbar } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { Publisher } from 'openvidu-browser';
import { ChangeEvent, FC, ReactNode, memo, useCallback, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group'; // ES6
import { Participant } from '../../../shared/core/models/openvidu/participant.model';
import { CommandType } from '../../../shared/core/models/socket/event.model';
import { VisionApiEntityAnnotation } from '../../../shared/core/models/vision-api/entities/annotations/entity';
import { ParticipantKind } from '../../../shared/enum/participant-type.enum';
import useCanvas from '../../../shared/hooks/useCanvas';
import useFile from '../../../shared/hooks/useFile';
import usePipScreenSupported from '../../../shared/hooks/usePipScreenSupported';
import useTelestrator from '../../../shared/hooks/useTelestrator';
import useVisionApi from '../../../shared/hooks/vision-api/useVisionApi';
import { selectTag } from '../../../store/rooms/roomSelectors';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../../store/store';
import { setTelestrator } from '../../../store/telestrator/telestratorReducer';
import SimpleVideo from '../SimpleVideo';
import ParticipantControlsFooter from './ParticipantControlsFooter/ParticipantControlsFooter';
import ParticipantControlsHeader from './ParticipantControlsHeader/ParticipantControlsHeader';
import ParticipantControlsSideLeft from './ParticipantControlsSide/ParticipantControlsSideLeft';
import ParticipantControlsTelestrator from './ParticipantControlsSide/ParticipantControlsTelestrator';
import './ParticipantVideo.scss';

type IParticipantVideoProps = {
  participant: Participant;
  isPinned: boolean;
  isSingleParticipant: boolean;
  miniature?: boolean;
  screenShareParticipant: Publisher | null;
  onClose: (connectionId: string) => void;
  onTogglePin: (connectionId: string) => void;
  onToggleCamera: (connectionId: string) => void;
  onToggleMicrophone: (connectionId: string) => void;
  onStartScreenShare: () => void;
  onStopScreenShare: () => void;
  onObjectDetected: (items: Array<VisionApiEntityAnnotation>) => void;
  onRemoteCommand: (command: CommandType, recipient: string) => void;
};

const ParticipantVideo: FC<IParticipantVideoProps> = ({
  participant,
  isPinned,
  isSingleParticipant,
  miniature = false,
  screenShareParticipant,
  onClose,
  onTogglePin,
  onToggleCamera,
  onToggleMicrophone,
  onStartScreenShare,
  onStopScreenShare,
  onObjectDetected,
  onRemoteCommand
}): JSX.Element => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const objectDetectionRef = useRef<HTMLCanvasElement>(null);
  const fileInputRef = useRef<HTMLInputElement>();
  const { sendFile, sendSnapshot } = useFile();
  const sessionTag = useAppSelector(selectTag);
  const [requestFullScreen, setRequestFullScreen] = useState<boolean>(false);
  const [requestPipScreen, setRequestPipScreen] = useState<boolean>(false);
  const [isHovering, setIsHovering] = useState<boolean>(false);
  const [displayBottomControls, setDisplayBottomControls] = useState<boolean>(false);
  const [displaySideControls, setDisplaySideControls] = useState<boolean>(false);

  const [sideComponent, setSideComponent] = useState<JSX.Element | ReactNode | null>(null);
  const [acceptType] = useState<string>('image/*,application/*,text/*');

  const [hasScreenShare, setHasScreenShare] = useState<boolean>(false);
  const [hasTelestrator, setHasTelestrator] = useState<boolean>(false);
  const [showSnackbarFile, setShowSnackbarFile] = useState<boolean>(false);

  const [isPipScreenSupported] = usePipScreenSupported();

  const dispatch: AppDispatch = useAppDispatch();

  const {
    startDrawing,
    changeColor,
    changeTrait,
    destroy: destroyTelestrator,
    cancelDraw,
    getImage
  } = useTelestrator(canvasRef, videoRef);

  const { draw, snapshot } = useCanvas(objectDetectionRef, videoRef);
  const { detect } = useVisionApi();

  useEffect(() => {
    setHasScreenShare(screenShareParticipant !== null);
  }, [screenShareParticipant]);

  useEffect(() => {
    if (snapshot !== null) {
      detect(snapshot)
        .then((response) => {
          if (response.data.responses.length > 0) {
            onObjectDetected(response.data.responses[0].labelAnnotations);
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  }, [snapshot]);

  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const enableFullScreen = () => {
    setRequestFullScreen(true);
  };

  const togglePictureInPicture = () => {
    if (isPipScreenSupported) {
      setRequestPipScreen(!requestPipScreen);
    } else {
      alert(
        `Your browser not support native Picture-in-picture feature. Please use your browsers extension.`
      );
    }
  };

  const toggleVisibility = () => {
    setIsHovering(!isHovering);
  };

  const toggleScreenShare = () => {
    if (hasScreenShare) {
      onStopScreenShare();
    } else {
      onStartScreenShare();
    }
  };

  useEffect(() => {
    if (hasTelestrator) {
      dispatch(setTelestrator({ isActive: true }));
      startDrawing();
      setSideComponent(
        <ParticipantControlsTelestrator switchColor={changeColor} switchTrait={changeTrait} />
      );
    } else {
      destroyTelestrator();
      setSideComponent(
        <ParticipantControlsSideLeft
          kind={participant.kind}
          telestrator={openTelestrator}
          telestratorEnabled={participant.manager.stream.videoActive}
          detectObject={detectObjects}
          attachFile={attachFile}
        />
      );
    }
  }, [hasTelestrator, participant.manager.stream.videoActive, participant.kind]);

  const openTelestrator = useCallback(() => {
    setHasTelestrator(true);
  }, []);

  const restoreImage = () => {
    cancelDraw();
  };

  const closeTelestrator = useCallback(() => {
    dispatch(setTelestrator({ isActive: false }));
    setHasTelestrator(false);
  }, []);

  const sendImage = () => {
    dispatch(setTelestrator({ isActive: false }));
    const image = getImage();
    if (image !== null) {
      sendSnapshot(image, participant.id, sessionTag)
        .then(() => {
          setShowSnackbarFile(true);
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          closeTelestrator();
        });
    }
  };

  const detectObjects = () => {
    draw();
  };

  const onFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.currentTarget.files;
    if (files !== null && files.length > 0) {
      sendFile(files[0], participant.id, false, sessionTag)
        .then(() => {
          setShowSnackbarFile(true);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };

  const attachFile = () => {
    fileInputRef.current?.click();
  };

  const handleCloseSnackbar = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowSnackbarFile(false);
  };

  const sendRemoteCommand = (command: CommandType) => {
    onRemoteCommand(command, participant.id);
  };

  return (
    <div
      className="video__container"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <Snackbar
        open={showSnackbarFile}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message="File sent"
        action={
          <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      {!hasTelestrator ? (
        <div className="video__participant-header">
          <ParticipantControlsHeader
            name={participant.name}
            type={participant.type}
            showAudioInfo={
              participant.kind === ParticipantKind.REMOTE && !participant.manager.stream.audioActive
            }
            haveControls={!isSingleParticipant && !hasTelestrator}
            pinned={isPinned}
            onClose={() => onClose(participant.connectionId)}
            onTogglePin={() => onTogglePin(participant.connectionId)}
          />
        </div>
      ) : null}
      {!miniature ? (
        <CSSTransition
          in={isHovering}
          timeout={250}
          classNames="controls-left"
          onEnter={() => setDisplaySideControls(true)}
          onExited={() => setDisplaySideControls(false)}>
          <div className="video__participant-side--left">
            {displaySideControls && sideComponent}
          </div>
        </CSSTransition>
      ) : null}
      <CSSTransition
        in={isHovering}
        timeout={250}
        classNames="controls-up"
        onEnter={() => setDisplayBottomControls(true)}
        onExited={() => setDisplayBottomControls(false)}>
        <div className="video__participant-footer">
          {displayBottomControls && (
            <ParticipantControlsFooter
              kind={participant.kind}
              type={participant.type}
              hasPip={requestPipScreen}
              hasAudio={participant.status.localAudio}
              hasVideo={participant.manager.stream.videoActive}
              hasScreenShare={hasScreenShare}
              hasTelestrator={hasTelestrator}
              miniature={miniature}
              toggleMicrophone={() => onToggleMicrophone(participant.connectionId)}
              toggleCamera={() => onToggleCamera(participant.connectionId)}
              toggleScreenShare={toggleScreenShare}
              fullScreen={enableFullScreen}
              pictureInPicture={togglePictureInPicture}
              telestratorClear={restoreImage}
              telestratorClose={closeTelestrator}
              telestratorSend={sendImage}
              detectObject={detectObjects}
              attachFile={attachFile}
              onRemoteCommand={sendRemoteCommand}
            />
          )}
        </div>
      </CSSTransition>
      <SimpleVideo
        type={participant.kind}
        manager={participant.manager}
        hasVideo={participant.manager.stream.videoActive}
        mirror={participant.kind === ParticipantKind.LOCAL}
        videoRef={videoRef}
        requestPipScreen={requestPipScreen}
        requestFullScreen={requestFullScreen}
        onFullScreenExit={() => setRequestFullScreen(false)}
        onPipScreenExit={() => setRequestPipScreen(false)}
        onClick={toggleVisibility}
      />
      {hasTelestrator ? (
        <div className="video__canvas-container">
          <canvas className="video__canvas-item" ref={canvasRef}></canvas>
        </div>
      ) : null}
      <div className="video__canvas-container video__canvas-container--low">
        <canvas className="video__canvas-detection" ref={objectDetectionRef}></canvas>
      </div>
      <input
        type="file"
        accept={acceptType}
        onChange={onFileChange}
        className="input__file--hidden"
        ref={fileInputRef}
      />
    </div>
  );
};

export default memo(ParticipantVideo);
