import { FC, memo } from 'react';
import { Tooltip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { MessageChat } from '../../shared/core/models/message.model';
import useAssetUrl from '../../shared/hooks/useAssetUrl';
import useContainerScroll from '../../shared/hooks/useContainerScroll';
import { selectId } from '../../store/auth/authSelectors';
import { selectRoom } from '../../store/rooms/roomSelectors';
import { useAppSelector } from '../../store/store';
import ChatMessage from './ChatMessage';
import './ChatMessageList.scss';

type IChatMessageListProps = {
  messages: MessageChat[];
  onMessageClick: (message: MessageChat) => void;
};

const ChatMessageList: FC<IChatMessageListProps> = ({ messages, onMessageClick }): JSX.Element => {
  const scrollRef = useContainerScroll(messages);
  const me = useAppSelector(selectId);
  const { t } = useTranslation();
  const room = useAppSelector(selectRoom);
  const { getAsset } = useAssetUrl();
  return (
    <div ref={scrollRef} className="chat-message-list">
      {messages.length > 0 ? (
        messages.map((m) => (
          <div key={m.timestamp} className="message_error">
            <ChatMessage
              message={m}
              key={m.sender + m.recipient + m.timestamp}
              itsMe={m.sender === me}
              onClick={() => onMessageClick(m)}
            />
            {room.storageId === null ? (
              <Tooltip title={t('TOOLTIP:error_file')} placement="top">
                <div>
                  <img className="icon-error" src={getAsset('warning.svg')} />
                </div>
              </Tooltip>
            ) : (
              <div></div>
            )}
          </div>
        ))
      ) : (
        <div>{t('CHAT_CONTAINER:messages')}</div>
      )}
    </div>
  );
};

export default memo(ChatMessageList);
