import { OpenVidu } from 'openvidu-browser';
import React, { createContext, useContext } from 'react';
import { IOpenviduLocalProvider } from '../../../models/provider/openvidu.provider.model';
import { useProvideOpenviduLocalStream } from '../../hooks/openvidu/useProvideOpenviduLocalStream';

export const OpenviduLocalContext = createContext<IOpenviduLocalProvider>({
  leave: () => {
    console.error('Not implemented');
  },
  join: async () => {
    console.error('Not implemented');
  },
  removeStream: (connectionId: string) => {
    console.error(`Not implemented yet ${connectionId}`);
  },
  togglePin: (connectionId: string) => {
    console.error(`Not implemented yet ${connectionId}`);
  },
  toggleLocalCamera: (connectionId: string) => {
    console.error(`Not implemented yet ${connectionId}`);
  },
  toggleMicrophone: (connectionId: string) => {
    console.error(`Not implemented yet ${connectionId}`);
  },
  switchParticipant: (connectionId: string) => {
    console.error(`Not implemented yet ${connectionId}`);
  },
  startRecording: async () => {
    console.error('Not implemented');
  },
  stopRecording: async () => {
    console.error('Not implemented');
  },
  pinned: null,
  participants: [],
  publisher: null,
  firstParticipant: null,
  secondParticipant: null,
  isSingleParticipant: false
});

export const useOpenviduLocal = (): IOpenviduLocalProvider => {
  return useContext(OpenviduLocalContext);
};

export function OpenviduLocalProvider({
  children,
  OV,
  projectId
}: {
  children: React.ReactNode;
  OV: OpenVidu;
  projectId: string;
}): JSX.Element {
  const openvidu = useProvideOpenviduLocalStream(OV, projectId);
  return <OpenviduLocalContext.Provider value={openvidu}>{children}</OpenviduLocalContext.Provider>;
}
