import { useEffect, useState } from 'react';
import * as tus from 'tus-js-client';
import { selectId, selectToken } from '../../../store/auth/authSelectors';
import { selectRoom } from '../../../store/rooms/roomSelectors';
import { useAppSelector } from '../../../store/store';
import { useConfig } from '../../core/context/provider/ConfigProvider';

type Itype = {
  uploadFile: (file: File, tag: string | null) => void;
};

const TUS_METADATA = {
  FILE_NAME: 'filename',
  FILE_MIMETYPE: 'mimeType',
  FILE_UPLOADED_BY: 'uploadedby',
  ORIGIN: 'origin',
  ORIGIN_TYPE: 'desktop',
  TAG: 'tag'
};

export default function useUploader(): Itype {
  const { tus: tusConfig } = useConfig();
  const token = useAppSelector(selectToken);
  const me = useAppSelector(selectId);
  const room = useAppSelector(selectRoom);
  const [uploader, setUploader] = useState<tus.Upload | null>(null);

  useEffect(() => {
    if (uploader !== null) {
      uploader.start();
    }
  }, [uploader]);

  const uploadFile = (file: File, tag: string | null) => {
    if (room.storageId !== null) {
      setUploader(
        new tus.Upload(file, {
          endpoint: `${tusConfig.url}/${room.storageId}`,
          headers: {
            Authorization: `Bearer ${token}`
          },
          metadata: {
            [TUS_METADATA.FILE_NAME]: file.name,
            [TUS_METADATA.FILE_MIMETYPE]: file.type ?? '*/*',
            [TUS_METADATA.FILE_UPLOADED_BY]: me,
            [TUS_METADATA.ORIGIN]: TUS_METADATA.ORIGIN_TYPE,
            [TUS_METADATA.TAG]: tag
          },
          onSuccess: () => {
            console.debug('Success');
          },
          onError: function (err) {
            console.error('Error', err);
          }
        })
      );
    }
  };

  return {
    uploadFile
  };
}
