import React, { FC } from 'react';
import { IChildrenProps } from '../../core/models/props/children';
import './SimpleBox.scss';

type ISimpleBoxProps = IChildrenProps;

const SimpleBox: FC<ISimpleBoxProps> = ({ children }) => {
  return <div className="simple-box">{children}</div>;
};

export default React.memo(SimpleBox);
