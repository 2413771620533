import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import it from './it.json';

const resources = {
  en,
  it
};

i18n.use(initReactI18next).init({
  resources,
  defaultNS: 'common',
  lng: localStorage.getItem('language')
});

export default i18n;
