/* eslint-disable @typescript-eslint/no-restricted-imports */

import { configureStore } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import authReducer from './auth/authReducer';
import chatReducer from './chat/chatReducer';
import geoReducer from './geo/geoReducer';
import mediaReducer from './media/mediaReducer';
import openviduReducer from './openvidu/openviduReducer';
import organizationReducer from './organizations/organizationReducer';
import roomReducer from './rooms/roomReducer';
import userReducer from './user/userReducer';
import timerReducer from './timer/timerReducer';
import roleReducer from './role/roleReducer';
import participantReducer from './participant/participantReducer';
import telestratorReducer from './telestrator/telestratorReducer';
import snackbarReducer from './snackbar/snackbarReducer';

const store = configureStore({
  reducer: {
    organizations: organizationReducer,
    media: mediaReducer,
    auth: authReducer,
    openvidu: openviduReducer,
    chat: chatReducer,
    room: roomReducer,
    geo: geoReducer,
    user: userReducer,
    timer: timerReducer,
    participant: participantReducer,
    role: roleReducer,
    telestrator: telestratorReducer,
    snackbar: snackbarReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }),
  devTools: {
    name: 'Stream'
  }
});

// Strongly typed Store
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
