import { useState } from 'react';
import { useFetchListClient } from '../api.handle.call';
import { Organization } from '../../models/organization.model';

export function useGetAllOrganization(): {
  organizations: Organization[] | null;
  fetchOrganizations: () => void;
  fetchOrganizationsError: Error | null;
  pending: boolean;
} {
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [fetchOrganizationsError, setError] = useState<Error | null>(null);

  const [fetchOrganizations, pending] = useFetchListClient<Organization>(
    'organizations/',
    setOrganizations,
    setError
  );

  return { organizations, fetchOrganizations, fetchOrganizationsError, pending };
}
