import { OpenVidu } from 'openvidu-browser';
import { createContext, useContext } from 'react';
import { IOpenviduScreenShareProvider } from '../../../models/provider/openvidu.provider.model';
import { useProvideOpenviduScreenShare } from '../../hooks/openvidu/useProvideOpenviduScreenShare';

const OpenviduScreenShareContext = createContext<IOpenviduScreenShareProvider>({
  leave: () => {
    console.error('Not implemented');
  },
  join: async () => {
    console.error('Not implemented');
  },
  publisher: null
});

export const useOpenviduScreenShare = (): IOpenviduScreenShareProvider => {
  return useContext(OpenviduScreenShareContext);
};

export function OpenviduScreenShareProvider({
  children,
  OV,
  projectId
}: {
  children: React.ReactNode;
  OV: OpenVidu;
  projectId: string;
}): JSX.Element {
  const openvidu = useProvideOpenviduScreenShare(OV, projectId);
  return (
    <OpenviduScreenShareContext.Provider value={openvidu}>
      {children}
    </OpenviduScreenShareContext.Provider>
  );
}
