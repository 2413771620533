import { Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';

type MediaProps = {
  onExit: () => void;
};

const MediaHeader: FC<MediaProps> = ({ onExit }): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      wrap="nowrap">
      <Grid item xs={11}>
        {t('MEDIA:room_media')}
      </Grid>
      <Grid item xs={1}>
        <IconButton onClick={onExit} size="small" color="primary">
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default memo(MediaHeader);
