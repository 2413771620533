import { RootState } from '../store';
import { ITelestrator, TelestratorState } from './telestratorReducer';

export const getTelestratorState = (store: RootState): TelestratorState => store.telestrator;

/**
 * Select telestrator
 *
 * @param state Store state
 * @returns single telestrator
 */
export const selectTelestrator = (state: RootState): ITelestrator =>
  getTelestratorState(state).all ?? null;
