import { createSelector } from '@reduxjs/toolkit';
import { MessageChat } from '../../shared/core/models/message.model';
import { RootState } from '../store';
import { ChatState } from './chatReducer';

export const getChatState = (store: RootState): ChatState => store.chat;

export const selectMessages = (state: RootState): MessageChat[] => getChatState(state).messages;

export const selectCountUnreaded = (state: RootState): number =>
  [...selectMessages(state)].filter((m) => !m.readed).length;

const selectContext = (_state: RootState, context: string) => context;
const selectRecipient = (_state: RootState, recipient: string) => recipient;
const selectRecipientSender = (_state: RootState, recipient: string, sender: string) => ({
  recipient,
  sender
});

export const selectCountPublicUnreaded = createSelector(
  [selectMessages, selectRecipient],
  (messages, recipient) => messages.filter((m) => !m.readed && m.recipient === recipient).length
);

export const selectCountPrivateUnread = createSelector(
  [selectMessages, selectContext],
  (messages, context) =>
    messages.filter((m) => !m.readed && m.context === context && m.context !== m.recipient).length
);

export const selectPrivateUnread = createSelector(
  [selectMessages, selectContext],
  (messages, context) =>
    messages.filter((m) => !m.readed && m.context === context && m.context !== m.recipient)
);

export const selectCountPrivateUnreadOfSender = createSelector(
  [selectMessages, selectRecipientSender],
  (messages, { recipient, sender }) =>
    messages.filter(
      (m) =>
        !m.readed &&
        ((m.recipient === recipient && m.sender === sender) ||
          (m.recipient === sender && m.sender === recipient))
    ).length
);

export const selectOrderedMessages = (state: RootState): MessageChat[] =>
  [...selectMessages(state)].sort((a, b) => a.timestamp - b.timestamp);
