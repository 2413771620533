import { RootState } from '../store';
import { AuthState, IAuth } from './authReducer';

export const getAuthState = (store: RootState): AuthState => store.auth;

/**
 * Select current auth token
 *
 * @param state Store state
 * @returns Auth token or null
 */
export const selectToken = (state: RootState): string | null =>
  getAuthState(state).me?.token ?? null;

/**
 * Select all info of current user
 * @param state Store state
 * @returns IAuth info
 */
export const selectMe = (state: RootState): IAuth | null => getAuthState(state).me ?? null;

/**
 * Select current user id
 * @param state Store state
 * @returns Id of current user or null
 */
export const selectId = (state: RootState): string | null => selectMe(state)?.id ?? null;

/**
 * Select current user role
 * @param state Store state
 * @returns Role of current user or null
 */
export const selectMyRole = (state: RootState): string | null => selectMe(state)?.role ?? null;

/**
 * Select current user pretty name
 * @param state Store state
 * @returns Name of current user
 */
export const selectName = (state: RootState): string => {
  const me = selectMe(state);
  if (me === null) {
    return '';
  }
  const { firstName, lastName, username } = me;
  return firstName === '' && lastName === '' ? `${username}` : `${firstName} ${lastName}`;
};
