import AttachFileIcon from '@mui/icons-material/AttachFile';
import ImageSearchIcon from '@mui/icons-material/ImageSearch';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ParticipantKind } from '../../../../shared/enum/participant-type.enum';
import CircleIconButton from '../../../../shared/ui/CircleIconButton/CircleIconButton';
import TelestratorIcon from '../../../../shared/ui/Icons/TelestratorIcon';
import './ParticipantControlsSideLeft.scss';

type IParticipantControlsSideLeftProps = {
  kind: ParticipantKind;
  telestratorEnabled: boolean;
  telestrator: () => void;
  detectObject: () => void;
  attachFile: () => void;
};

const ParticipantControlsSideLeft: FC<IParticipantControlsSideLeftProps> = ({
  kind,
  telestratorEnabled,
  telestrator,
  detectObject,
  attachFile
}): JSX.Element => {
  const { t } = useTranslation();

  return kind !== ParticipantKind.LOCAL ? (
    <div className="side-controls-left__container">
      <CircleIconButton
        icon={<TelestratorIcon />}
        type="dark"
        description="Telestrator"
        descriptionPlacement="right"
        onClick={telestrator}
        disabled={!telestratorEnabled}
      />
      <CircleIconButton
        icon={<ImageSearchIcon />}
        type="dark"
        description={t('PARTICIPANT_LIST:object_detection')}
        descriptionPlacement="right"
        onClick={detectObject}
      />
      {kind === ParticipantKind.REMOTE && (
        <CircleIconButton
          icon={<AttachFileIcon />}
          type="dark"
          description={t('PARTICIPANT_LIST:attach_file')}
          descriptionPlacement="right"
          onClick={attachFile}
        />
      )}
    </div>
  ) : null;
};

export default React.memo(ParticipantControlsSideLeft);
