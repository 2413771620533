import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IRole {
  id: string;
  name: string;
}

export type RoleState = {
  all: IRole[];
};

const initialRole: RoleState = {
  all: []
};

export const roleSlice = createSlice({
  name: 'role',
  initialState: initialRole,
  reducers: {
    setRole: (state, action: PayloadAction<IRole[]>) => {
      state.all = action.payload;
    }
  }
});

export const { setRole } = roleSlice.actions;
export default roleSlice.reducer;
