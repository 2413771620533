import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import UndoIcon from '@mui/icons-material/Undo';
import { FC, memo } from 'react';
import { useTranslation } from 'react-i18next';
import CircleIconButton from '../../../../shared/ui/CircleIconButton/CircleIconButton';
import './ParticipantTelestratorFooterControls.scss';

type IParticipantTelestratorFooterControlsProps = {
  cancel: () => void;
  restore: () => void;
  send: () => void;
};

const ParticipantTelestratorFooterControls: FC<IParticipantTelestratorFooterControlsProps> = ({
  cancel,
  restore,
  send
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <div className="controls-footer-telestrator__container">
      <div className="controls-footer-telestrator__container--grow"></div>
      <CircleIconButton
        icon={<CloseIcon />}
        type="primary"
        description={t('TELESTRATOR:cancel')}
        onClick={cancel}
      />
      <CircleIconButton
        icon={<UndoIcon />}
        type="primary"
        description={t('TELESTRATOR:clear')}
        onClick={restore}
      />
      <CircleIconButton
        icon={<SendIcon />}
        type="secondary"
        description={t('TELESTRATOR:send')}
        onClick={send}
      />
    </div>
  );
};

export default memo(ParticipantTelestratorFooterControls);
