import { useState } from 'react';
import { Media } from '../../models/vision-api/entities/media.interface';
import { useFetchListClient } from '../api.handle.call';

export function useGetAllMedia(storageId: string): {
  allMedia: Media[] | null;
  fetchMedia: () => void;
  fetchMediaError: Error | null;
  pending: boolean;
} {
  const [allMedia, setMedia] = useState<Media[]>([]);
  const [fetchMediaError, setError] = useState<Error | null>(null);
  const [fetchMedia, pending] = useFetchListClient<Media>(
    `media/storages/${storageId}/files`,
    setMedia,
    setError
  );

  return { allMedia, fetchMedia, fetchMediaError, pending };
}
