import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React, { FC } from 'react';

const ScreenShareIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="-4 -8 42 42">
      <path
        d="M32,2.90909091 L32,20.3636364 C32,21.9781818 30.7054545,23.2727273 29.0909091,23.2727273 L20.3636364,23.2727273 L20.3636364,20.3636364 L29.0909091,20.3636364 L29.0909091,2.90909091 L2.90909091,2.90909091 L2.90909091,20.3636364 L11.6363636,20.3636364 L11.6363636,23.2727273 L2.90909091,23.2727273 C1.30909091,23.2727273 0,21.9781818 0,20.3636364 L0,2.90909091 C0,1.29454545 1.29454545,0 2.90909091,0 L29.0909091,0 C30.6909091,0 32,1.29454545 32,2.90909091 M17.4545455,16 L21.8181818,16 L16,10.1818182 L10.1818182,16 L14.5454545,16 L14.5454545,26.1818182 L10.1818182,26.1818182 L10.1818182,29.0909091 L21.8181818,29.0909091 L21.8181818,26.1818182 L17.4545455,26.1818182 L17.4545455,16 Z"
        transform="translate(0 1)"
      />
    </SvgIcon>
  );
};

export default React.memo(ScreenShareIcon);
