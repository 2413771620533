import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React, { FC } from 'react';

const PinIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="-4 -4 42 42">
      <polygon
        points="16 16 16 3.2 17.6 3.2 17.6 0 1.6 0 1.6 3.2 3.2 3.2 3.2 16 0 19.2 0 22.4 8.32 22.4 8.32 32 10.88 32 10.88 22.4 19.2 22.4 19.2 19.2"
        transform="translate(6)"
      />
    </SvgIcon>
  );
};

export default React.memo(PinIcon);
