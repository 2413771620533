import { useState } from 'react';
import { Room } from '../../models/room.model';
import { useFetchSingleClient } from '../api.handle.call';

export function useGetRoomByCode(
  code: string,
  projectId: string
): {
  room: Room | null;
  fetchRoom: () => void;
  fetchRoomError: Error | null;
  pending: boolean;
} {
  const [room, setRoom] = useState<Room>();
  const [fetchRoomError, setError] = useState<Error | null>(null);

  const [fetchRoom, pending] = useFetchSingleClient<Room>(
    `rooms/code/${code}?project=${projectId}`,
    setRoom,
    setError
  );

  return { room, fetchRoom, fetchRoomError, pending };
}
