import axios, { AxiosInstance } from 'axios';
import { AuthRefreshDTO, AuthStream } from '../models/auth.model';

class ApiClient {
  getLocalStorageAuth: AuthStream | null = sessionStorage.getItem('coreAuth')
    ? JSON.parse(window.atob(sessionStorage.getItem('coreAuth')))
    : null;

  private buildClientIstance = (): AxiosInstance => {
    const instance = axios.create({
      baseURL: `${process.env.REACT_APP_API_URL}/api/`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json'
      }
    });

    instance.interceptors.request.use(
      (config) => {
        config.headers['Authorization'] = 'Bearer ' + this.getLocalStorageAuth?.token ?? '';
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    instance.interceptors.response.use(
      (res) => {
        return res;
      },
      async (err) => {
        const originalConfig = err.config;
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            await this.executeRefreshToken();

            this.getLocalStorageAuth = sessionStorage.getItem('coreAuth')
              ? JSON.parse(window.atob(sessionStorage.getItem('coreAuth')))
              : null;

            originalConfig.headers['Authorization'] =
              'Bearer ' + this.getLocalStorageAuth?.token ?? '';

            return instance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }

        return Promise.reject(err);
      }
    );

    return instance;
  };

  private executeRefreshToken = async (): Promise<void> => {
    const rs = await this.fireBaseAxiosIstance.post(process.env.REACT_APP_FIREBASE_API_KEY, {
      refreshToken: JSON.parse(window.atob(sessionStorage.getItem('coreAuth'))).refreshToken,
      grant_type: 'refresh_token'
    });

    const responseAuth: AuthRefreshDTO = rs.data;

    this.setNewAuthDataOnSessionStorage(responseAuth);

    this.client.interceptors.request.use(
      (config) => {
        config.headers['Authorization'] = 'Bearer ' + this.getLocalStorageAuth?.token ?? '';
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  };

  private setNewAuthDataOnSessionStorage = (responseAuth: AuthRefreshDTO): void => {
    const newDataAuth = {
      ...this.getLocalStorageAuth,
      token: responseAuth.access_token,
      refreshToken: responseAuth.refresh_token
    };
    sessionStorage.setItem('coreAuth', window.btoa(JSON.stringify(newDataAuth)));
  };

  private fireBaseAxiosIstance: AxiosInstance = axios.create({
    baseURL: `${process.env.REACT_APP_FIREBASE_BASE_URL}`,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  private apiClient: AxiosInstance = this.buildClientIstance();

  get client(): AxiosInstance {
    return this.apiClient;
  }
}

export default ApiClient;
