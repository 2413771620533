import { IconButton, Tooltip, Zoom } from '@material-ui/core';
import React, { FC, ReactNode, useEffect, useState } from 'react';
import './CircleIconButton.scss';

type ICircleIconButtonProps = {
  icon: JSX.Element | ReactNode;
  size?: 'small' | 'medium';
  type?: 'primary' | 'alert' | 'dark' | 'dark-opacity' | 'secondary';
  onClick?: () => void;
  disabled?: boolean;
  description?: string;
  descriptionPlacement?:
    | 'bottom-end'
    | 'bottom-start'
    | 'bottom'
    | 'left-end'
    | 'left-start'
    | 'left'
    | 'right-end'
    | 'right-start'
    | 'right'
    | 'top-end'
    | 'top-start'
    | 'top';
};

const CircleIconButton: FC<ICircleIconButtonProps> = ({
  icon,
  size = 'medium',
  type = 'primary',
  description = '',
  descriptionPlacement = 'top',
  disabled = false,
  onClick = () => {
    console.debug('no action');
  }
}): JSX.Element => {
  const ICON_CLASS_PREFIX = 'circle-icon__button';
  const [iconClass, setIconClass] = useState<string>(`${ICON_CLASS_PREFIX}--primary`);

  useEffect(() => {
    setIconClass(`${ICON_CLASS_PREFIX}--${type}`);
  }, [type]);

  return (
    <Tooltip TransitionComponent={Zoom} title={description} placement={descriptionPlacement}>
      <span>
        <IconButton
          className={iconClass}
          aria-label={description}
          onClick={onClick}
          disabled={disabled}
          size={size}>
          {icon}
        </IconButton>
      </span>
    </Tooltip>
  );
};

export default React.memo(CircleIconButton);
