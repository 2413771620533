export interface BaseEvent {
  sender: string;
  sender_display_name: string;
  recipient: string;
  context: string;
  timestamp: number;
}

export interface SendMessageChat extends BaseEvent {
  message: string;
  attachment?: {
    url: string;
    name: string;
  };
}

export enum FileType {
  IMAGE = 'image',
  DOCUMENT = 'document',
  VIDEO = 'video',
  UNKNOWN = 'unknown'
}

export interface SendFileEvent extends BaseEvent {
  body: string;
  mimeType: string;
  name: string;
  type: FileType;
}

export enum CommandType {
  SWITCH_ZOOM = 'SWITCH_ZOOM',
  SWITCH_CAMERA = 'SWITCH_CAMERA'
}

export interface RemoteCommandEvent extends BaseEvent {
  command: CommandType;
}

export interface StartRecordingEvent extends BaseEvent {
  recordingId: string;
}

export type StopRecordingEvent = BaseEvent;
export interface ChangeTagEvent extends BaseEvent {
  tag: string;
  room: string;
}
