import {
  ConnectionEvent,
  NetworkQualityLevelChangedEvent,
  OpenVidu,
  Publisher,
  PublisherSpeakingEvent,
  RecordingEvent,
  Session,
  SessionDisconnectedEvent,
  SignalEvent,
  StreamEvent,
  StreamPropertyChangedEvent,
  Subscriber
} from 'openvidu-browser';
import { useEffect, useState } from 'react';
import { selectId, selectMyRole, selectName } from '../../../../../store/auth/authSelectors';
import {
  startRecording as startRecordingAction,
  stopRecording as stopRecordingAction
} from '../../../../../store/openvidu/openviduReducer';
import {
  selectIsRecording,
  selectRecording
} from '../../../../../store/openvidu/openviduSelectors';
import { selectRoom } from '../../../../../store/rooms/roomSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../store/store';
import { ParticipantKind, ParticipantType } from '../../../../enum/participant-type.enum';
import useRecordingEvent from '../../../../hooks/socket/useRecordingEvent';
import { useCreateRecording } from '../../../api/hook-call/recording.hook';
import { Participant } from '../../../models/openvidu/participant.model';
import { RecordingOutputMode, RecordingResponse } from '../../../models/openvidu/recording.model';
import { IOpenviduLocalProvider } from '../../../models/provider/openvidu.provider.model';
import { useProvideOpenviduConnection } from './useProvideOpenviduConnection';

type OpenviduRemoteEvent =
  | StreamEvent
  | SessionDisconnectedEvent
  | SignalEvent
  | ConnectionEvent
  | PublisherSpeakingEvent
  | RecordingEvent
  | NetworkQualityLevelChangedEvent
  | StreamPropertyChangedEvent;

type StreamChangedInfo = {
  property: 'videoActive' | 'audioActive';
  value: boolean;
  connectionId: string;
};

export function useProvideOpenviduLocalStream(
  OV: OpenVidu,
  projectId: string
): IOpenviduLocalProvider {
  OV.enableProdMode();

  const {
    getToken,
    createParticipant,
    startRecording: startRec,
    stopRecording: stopRec,
    getRecordingsOfSession
  } = useProvideOpenviduConnection();

  const dispatcher = useAppDispatch();

  const recordingId: string | null = useAppSelector(selectRecording);
  const isRecording = useAppSelector(selectIsRecording);
  const id: string | null = useAppSelector(selectId);
  const roleId: string | null = useAppSelector(selectMyRole);
  const currentRoom = useAppSelector(selectRoom);
  const publisherName = useAppSelector(selectName);

  const [session, setSession] = useState<{ current: Session; id: string } | null>(null);

  const [subscribers, setSubscribers] = useState<Subscriber[]>([]);
  const [publisher, setPublisher] = useState<Publisher | null>(null);
  const [participants, setParticipants] = useState<Participant[]>([]);

  const [firstParticipant, setFirstParticipant] = useState<Participant | null>(null);
  const [secondParticipant, setSecondParticipant] = useState<Participant | null>(null);
  const [isSingleParticipant, setIsSingleParticipant] = useState<boolean>(true);
  const [pinned, setPinned] = useState<string | null>(null);

  const [streamClosed, setStreamClosed] = useState<string | null>(null);
  const [streamDestroy, setStreamDestroyed] = useState<string | null>(null);
  const [streamCreated, setStreamCreated] = useState<Subscriber | null>(null);
  const [streamChanged, setStreamChanged] = useState<StreamChangedInfo | null>(null);
  const [addParticipant, setAddParticipant] = useState<Participant | null>(null);
  const [replaceParticipant, setReplaceParticipant] = useState<Participant | null>(null);
  const [removeParticipant, setRemoveParticipant] = useState<string | null>(null);
  const [startRecEffect, setStartRec] = useState<boolean>(false);
  const [stopRecEffect, setStopRec] = useState<boolean>(false);
  const [leaveSessionEffect, setLeaveSessionEffect] = useState<boolean>(false);
  const { startRecordingEvent, stopRecordingEvent } = useRecordingEvent();

  const { createRecording } = useCreateRecording();

  const isPrimaryParticipant = (connectionId: string): boolean => {
    return (
      firstParticipant?.connectionId === connectionId ||
      secondParticipant?.connectionId === connectionId
    );
  };

  const putLocalIntoPrimaryParticipant = () => {
    const localParticipant = participants.find(
      (p) => p.connectionId === publisher.stream.connection.connectionId
    );

    if (localParticipant) {
      setFirstParticipant(localParticipant);
      setPinned(localParticipant.connectionId);
    }
  };

  /**
   * Handle if stream have a single participant or not
   */
  useEffect(() => {
    setIsSingleParticipant(firstParticipant === null || secondParticipant === null);
  }, [firstParticipant, secondParticipant]);

  /**
   * A stream is closed: clean up of first or second participant
   */
  useEffect(() => {
    if (streamClosed !== null) {
      if (isPrimaryParticipant(streamClosed)) {
        if (firstParticipant !== null && firstParticipant?.connectionId === streamClosed) {
          setFirstParticipant(null);
          if (secondParticipant !== null) {
            setSecondParticipant({
              ...secondParticipant,
              status: {
                ...secondParticipant.status
              }
            });
            setPinned(secondParticipant.connectionId);
          } else {
            putLocalIntoPrimaryParticipant();
          }
        } else if (secondParticipant !== null && secondParticipant?.connectionId === streamClosed) {
          setSecondParticipant(null);
          if (firstParticipant !== null) {
            setFirstParticipant({
              ...firstParticipant,
              status: {
                ...firstParticipant.status
              }
            });
            setPinned(firstParticipant.connectionId);
          } else {
            putLocalIntoPrimaryParticipant();
          }
        }
      } else {
        console.error('No participant found');
      }
      setStreamClosed(null);
    }
  }, [streamClosed]);

  /**
   * A stream is destroyed: clean up of first or second participant, and remove from global list
   */
  useEffect(() => {
    setRemoveParticipant(streamDestroy);
    setStreamClosed(streamDestroy);
  }, [streamDestroy]);

  /**
   * A new stream is created
   */
  useEffect(() => {
    if (streamCreated !== null) {
      setSubscribers([...subscribers, streamCreated]);
      const participant = createParticipant(streamCreated);
      setAddParticipant(participant);
      if (secondParticipant == null) {
        setSecondParticipant(participant);
      }
    }
  }, [streamCreated]);

  /**
   * A stream changed remote audio or video
   */
  useEffect(() => {
    if (streamChanged !== null) {
      if (firstParticipant?.connectionId === streamChanged.connectionId) {
        setFirstParticipant({
          ...firstParticipant
        });
      } else if (secondParticipant?.connectionId === streamChanged.connectionId) {
        setSecondParticipant({
          ...secondParticipant
        });
      }
    }

    const participant = participants.find((p) => p.connectionId === streamChanged.connectionId);
    if (participant) {
      setReplaceParticipant(participant);
    }
  }, [streamChanged]);

  /**
   * Add a participant in global list
   */
  useEffect(() => {
    if (addParticipant !== null) {
      setParticipants([...participants, addParticipant]);
    }
  }, [addParticipant]);

  /**
   * Remove a participant in global list
   */
  useEffect(() => {
    if (removeParticipant !== null) {
      setParticipants([...participants.filter((p) => p.connectionId !== removeParticipant)]);

      setSubscribers([
        ...subscribers.filter((p) => p.stream.connection.connectionId !== removeParticipant)
      ]);
    }
  }, [removeParticipant]);

  /**
   * Replace a participant in global list
   */
  useEffect(() => {
    if (replaceParticipant !== null) {
      setParticipants([
        ...participants.filter((p) => p.connectionId !== replaceParticipant.connectionId),
        replaceParticipant
      ]);
    }
  }, [
    replaceParticipant,
    replaceParticipant?.manager.stream.videoActive,
    replaceParticipant?.manager.stream.audioActive,
    replaceParticipant?.status.localAudio
  ]);

  /**
   * Ask to remote server about started or starting recording
   */
  const findCurrentRecording = () => {
    if (session !== null) {
      getRecordingsOfSession(session.id)
        .then((results) => {
          if (results.items.length > 0) {
            const recording = results.items[0].id;
            dispatcher(startRecordingAction(recording));
          }
        })
        .catch((e) => {
          console.error(e);
        });
    }
  };

  /**
   * Init of a Session
   */
  useEffect(() => {
    async function init() {
      if (session !== null) {
        // Remote stream is created
        session?.current.on('streamCreated', (event: OpenviduRemoteEvent) => {
          const subscriber = session?.current.subscribe((event as StreamEvent).stream, '');
          setStreamCreated(subscriber);
        });

        // Remote stream is destroyed
        session?.current.on('streamDestroyed', (event: OpenviduRemoteEvent) => {
          setStreamDestroyed((event as StreamEvent).stream.connection.connectionId);
        });

        // Bind when video or audio changes
        session?.current.on('streamPropertyChanged', (event: OpenviduRemoteEvent) => {
          const e = event as StreamPropertyChangedEvent;
          if (e.changedProperty === 'videoActive' || e.changedProperty === 'audioActive') {
            setStreamChanged({
              property: e.changedProperty,
              connectionId: e.stream.connection.connectionId,
              value: e.newValue as boolean
            });
          }
        });

        try {
          const me = OV.initPublisher('', {
            audioSource: undefined,
            videoSource: undefined,
            publishAudio: true,
            publishVideo: true,
            resolution: '640x480',
            frameRate: 30,
            insertMode: 'APPEND',
            mirror: true
          });

          const token = await getToken(session?.id);
          await session?.current.connect(token, {
            clientData: publisherName,
            id,
            roleId,
            type: ParticipantType.WEB,
            projectId
          });
          session?.current.publish(me);

          const participant = createParticipant(me, ParticipantKind.LOCAL);

          setPinned(participant.connectionId);
          setPublisher(me);
          setAddParticipant(participant);
          if (firstParticipant !== null) {
            setSecondParticipant(firstParticipant);
          }
          setFirstParticipant(participant);
          findCurrentRecording();
        } catch (e) {
          console.error('There was an error connecting to the session:', e);
        }
      }
    }

    init();
  }, [session]);

  /**
   * Join to a session
   * @param room Id of room to join
   */
  const join = async (room: string) => {
    setSession({
      current: OV.initSession(),
      id: room
    });
  };

  useEffect(() => {
    if (leaveSessionEffect) {
      session?.current.disconnect();
      setPublisher(null);
      setSubscribers([]);
      setFirstParticipant(null);
      setSecondParticipant(null);
      stopRecording();
    }
    return () => {
      setLeaveSessionEffect(false);
    };
  }, [leaveSessionEffect]);

  /**
   * Leave current session
   */
  const leave = () => {
    setLeaveSessionEffect(true);
  };

  /**
   * Close first or second stream
   * @param connectionId
   */
  const removeStream = (connectionId: string): void => {
    setStreamClosed(connectionId);
  };

  /**
   * Pin first or second stream
   * @param connectionId Id of streamer
   */
  const togglePin = (connectionId: string): void => {
    setPinned(connectionId);
  };

  /**
   * Toggle local audio. If {@link connectionId} is publisher, stop/start publishing audio.
   * Instead, if is a remote participant unsubscribe/subscribe to remote audio (only client affected)
   *
   * @param connectionId Id of connection of streamer
   */
  const toggleMicrophone = (connectionId: string): void => {
    let localAudio = !publisher.stream.audioActive;

    if (connectionId === publisher.stream.connection.connectionId) {
      publisher.publishAudio(localAudio);
      const participant = participants.find(
        (p) => p.manager.stream.connection.connectionId === connectionId
      );
      if (participant) {
        const updatedParticipant: Participant = {
          ...participant,
          status: { ...participant.status, localAudio }
        };
        setReplaceParticipant(updatedParticipant);
      }
    } else {
      const participant = participants.find(
        (p) => p.manager.stream.connection.connectionId === connectionId
      );
      const subscriber = subscribers.find((p) => p.stream.connection.connectionId === connectionId);

      if (subscriber && participant) {
        localAudio = !participant.status.localAudio;

        subscriber.subscribeToAudio(localAudio);
        const updatedParticipant: Participant = {
          ...participant,
          status: { ...participant.status, localAudio }
        };
        setReplaceParticipant(updatedParticipant);
      }
    }

    if (firstParticipant?.connectionId === connectionId) {
      setFirstParticipant({
        ...firstParticipant,
        status: {
          ...firstParticipant.status,
          localAudio
        }
      });
    } else if (secondParticipant?.connectionId === connectionId) {
      setSecondParticipant({
        ...secondParticipant,
        status: {
          ...secondParticipant.status,
          localAudio
        }
      });
    }
  };

  /**
   * Publish / unpublish local video
   * @param connectionId Id of stream
   */
  const toggleLocalCamera = (connectionId: string): void => {
    if (connectionId === publisher.stream.connection.connectionId) {
      publisher.publishVideo(!publisher.stream.videoActive);
      const participant = participants.find((p) => p.connectionId === connectionId);
      if (participant) {
        setReplaceParticipant(participant);
      }
    }
  };

  /**
   * Replace a stream with another
   * @param connectionId Id to use
   */
  const switchParticipant = (connectionId: string): void => {
    const participant = participants.find((p) => p.connectionId === connectionId);

    if (participant) {
      if (firstParticipant?.connectionId !== pinned) {
        setFirstParticipant(participant);
      } else {
        setSecondParticipant(participant);
      }
    }
  };

  useEffect(() => {
    if (startRecEffect && !isRecording) {
      const startRecordingAsync = async (): Promise<RecordingResponse> => {
        const results = await startRec({
          session: session.id,
          name: `${session.id}_${new Date().getTime().toString()}`,
          outputMode: RecordingOutputMode.COMPOSED,
          hasAudio: true,
          hasVideo: true,
          resolution: '640x480'
        });

        dispatcher(startRecordingAction(results.id));
        startRecordingEvent(currentRoom.id, results.id);
        return results;
      };
      startRecordingAsync().then((res) => {
        setStartRec(false);
        console.debug('recording started');
        createRecording({
          session: res.name,
          startedBy: id,
          files: [],
          toUploads: [firstParticipant.manager.stream.streamId]
        });
      });
    }
  }, [startRecEffect, isRecording]);

  useEffect(() => {
    if (stopRecEffect && isRecording) {
      const stopRecordingAsync = async () => {
        await stopRec(recordingId);
        dispatcher(stopRecordingAction());
        stopRecordingEvent(currentRoom.id);
      };

      stopRecordingAsync().then(() => {
        setStopRec(false);
        console.debug('recording stopped');
      });
    }
  }, [stopRecEffect, isRecording]);

  const startRecording = (): void => {
    setStartRec(true);
  };

  const stopRecording = (): void => {
    setStopRec(true);
  };

  return {
    leave,
    join,
    removeStream,
    togglePin,
    toggleLocalCamera,
    toggleMicrophone,
    switchParticipant,
    startRecording,
    stopRecording,
    pinned,
    participants,
    publisher,
    firstParticipant,
    secondParticipant,
    isSingleParticipant
  };
}
