import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ITimer {
  minutes: string;
  seconds: string;
  hours: string;
}

export type TimerState = {
  timeValue: ITimer;
  isActive: boolean;
  showOption: boolean;
};

const initialStateTimer: TimerState = {
  timeValue: {
    minutes: '00',
    seconds: '00',
    hours: '00'
  },
  isActive: false,
  showOption: false
};

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const timerSlice = createSlice({
  name: 'timer',
  initialState: initialStateTimer,
  reducers: {
    setTimer: (state, action: PayloadAction<ITimer>) => {
      state.timeValue = action.payload;
    },
    setActive: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
    setShowOption: (state, action: PayloadAction<boolean>) => {
      state.showOption = action.payload;
    }
  }
});

export const { setTimer } = timerSlice.actions;
export const { setActive } = timerSlice.actions;
export const { setShowOption } = timerSlice.actions;
export default timerSlice.reducer;
