import { EnvType, IConfigProvider } from '../../models/provider/config.provider.model';

export function useProvideConfig(): IConfigProvider {
  return {
    env: process.env.NODE_ENV as EnvType,
    url: {
      base: process.env.REACT_APP_BASE_URL,
      projectBase: process.env.REACT_APP_REMOTE_PATH
    },
    openvidu: {
      url: process.env.REACT_APP_OPENVIDU_SERVER_URL,
      secret: process.env.REACT_APP_OPENVIDU_SERVER_SECRET
    },
    visionApi: {
      url: process.env.REACT_APP_VISION_API_BASE_URI,
      key: process.env.REACT_APP_VISION_API_KEY,
      maxResults: process.env.REACT_APP_VISION_API_MAX_RESULT as unknown as number
    },
    tus: {
      url: process.env.REACT_APP_TUS_URL,
      chunkSize: process.env.REACT_APP_TUS_CHUNK_SIZE as unknown as number
    }
  };
}
