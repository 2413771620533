import { RefObject, useCallback, useEffect, useState } from 'react';
import { fromEvent, pairwise, Subject, switchMap, takeUntil } from 'rxjs';

export type TelestratorType = HTMLOrSVGImageElement | HTMLVideoElement | HTMLCanvasElement;

type PositionCoordinates = { x: number; y: number };

type ITelestratorHook = {
  startDrawing: () => void;
  changeColor: (color: 'red' | 'green' | 'black') => void;
  changeTrait: (trait: 'thick' | 'thin') => void;
  cancelDraw: () => void;
  destroy: () => void;
  getImage: () => string | null;
};

export default function useTelestrator(
  canvasRef: RefObject<HTMLCanvasElement>,
  sourceRef: RefObject<TelestratorType>
): ITelestratorHook {
  const [canvasContext, setCanvasContext] = useState<CanvasRenderingContext2D | null>(null);
  const [originalImage, setOriginalImage] = useState<string | null>(null);
  const [pathColor, setPathColor] = useState<string>('#000000');
  const [pathWidth, setPathWidth] = useState<number>(3);
  const [cursorPosition, setCursorPosition] = useState<{
    prevPosition: PositionCoordinates;
    currentPosition: PositionCoordinates;
  } | null>(null);
  const destroy$ = new Subject<void>();

  const destroy = useCallback(() => {
    destroy$.next();
    setCanvasContext(null);
  }, []);

  useEffect(() => {
    return () => destroy();
  }, []);

  useEffect(() => {
    if (cursorPosition !== null) {
      const { prevPosition, currentPosition } = cursorPosition;
      canvasContext.beginPath();
      canvasContext.strokeStyle = pathColor;
      canvasContext.lineWidth = pathWidth;

      if (prevPosition) {
        canvasContext.moveTo(prevPosition.x, prevPosition.y);
        canvasContext.lineTo(currentPosition.x, currentPosition.y);
        canvasContext.stroke();
      }
    }
  }, [cursorPosition]);

  /**
   * Initial configuration of drawing tools
   */
  useEffect(() => {
    if (canvasContext !== null) {
      canvasRef.current.width = sourceRef.current.clientWidth;
      canvasRef.current.height = sourceRef.current.clientHeight;
      canvasContext.drawImage(
        sourceRef.current,
        0,
        0,
        sourceRef.current.clientWidth,
        sourceRef.current.clientHeight
      );

      setOriginalImage(canvasRef.current.toDataURL('image/png'));

      bindDrawing();
    }
  }, [canvasContext]);

  /**
   * Init of canvas context
   */
  const startDrawing = () => {
    if (canvasRef.current !== null) {
      setCanvasContext(canvasRef.current.getContext('2d'));
    } else {
      console.error('Reference of canvas is null. No element found in DOM');
    }
  };

  const bindDrawing = () => {
    const canvasEl = canvasRef.current;
    fromEvent(canvasEl, 'mousedown')
      .pipe(
        takeUntil(destroy$),
        switchMap(() => {
          return fromEvent(canvasEl, 'mousemove').pipe(
            takeUntil(fromEvent(canvasEl, 'mouseup')),
            takeUntil(fromEvent(canvasEl, 'mouseleave')),
            pairwise()
          );
        })
      )
      .subscribe((ev: [Event, Event]) => {
        const res = ev as [MouseEvent, MouseEvent];
        const rect = canvasEl.getBoundingClientRect();

        // previous and current position with the offset
        const prevPos = {
          x: res[0].clientX - rect.left,
          y: res[0].clientY - rect.top
        };

        const currentPos = {
          x: res[1].clientX - rect.left,
          y: res[1].clientY - rect.top
        };

        setCursorPosition({
          prevPosition: prevPos,
          currentPosition: currentPos
        });
      });
  };

  const changeColor = (color: 'red' | 'green' | 'black') => {
    setPathColor(() => {
      switch (color) {
        case 'red':
          return '#FF0000';
        case 'green':
          return '#95C11F';
        default:
          return '#000000';
      }
    });
  };

  const changeTrait = (trait: 'thick' | 'thin') => {
    setPathWidth(trait === 'thick' ? 3 : 1);
  };

  const cancelDraw = () => {
    if (originalImage !== null && canvasContext !== null) {
      const image = new Image();
      image.src = originalImage;
      image.onload = () => {
        canvasRef.current.width = sourceRef.current.clientWidth;
        canvasRef.current.height = sourceRef.current.clientHeight;
        canvasContext.drawImage(
          image,
          0,
          0,
          sourceRef.current.clientWidth,
          sourceRef.current.clientHeight
        );
      };
    }
  };

  const getImage = (): string | null => {
    if (canvasRef.current !== null) {
      return canvasRef.current.toDataURL('image/png');
    }
    return null;
  };

  return {
    startDrawing,
    changeColor,
    changeTrait,
    cancelDraw,
    destroy,
    getImage
  };
}
