import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ITelestrator {
  readonly isActive: boolean;
}

export type TelestratorState = {
  all: ITelestrator | null;
};

const initialStateTelestrator: TelestratorState = {
  all: null
};

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const telestratorSlice = createSlice({
  name: 'telestrator',
  initialState: initialStateTelestrator,
  reducers: {
    setTelestrator: (state, action: PayloadAction<ITelestrator>) => {
      state.all = action.payload;
    }
  }
});

export const { setTelestrator } = telestratorSlice.actions;
export default telestratorSlice.reducer;
