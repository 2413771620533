import { RefObject, useState } from 'react';
import { TelestratorType } from './useTelestrator';

type ICanvasHook = {
  snapshot: string | null;
  draw: () => void;
};

export default function useCanvas(
  canvasRef: RefObject<HTMLCanvasElement | undefined>,
  sourceRef: RefObject<TelestratorType | undefined>
): ICanvasHook {
  const [snapshot, setSnapshot] = useState<string | null>(null);

  const draw = () => {
    const canvasContext = canvasRef.current.getContext('2d');
    canvasRef.current.width = sourceRef.current.clientWidth;
    canvasRef.current.height = sourceRef.current.clientHeight;
    canvasContext.drawImage(
      sourceRef.current,
      0,
      0,
      sourceRef.current.clientWidth,
      sourceRef.current.clientHeight
    );

    setSnapshot(canvasRef.current.toDataURL('image/png'));
  };

  return {
    draw,
    snapshot
  };
}
