import { RootState } from '../store';
import { IMedia, MediaState } from './mediaReducer';

export const getMediaState = (store: RootState): MediaState => store.media;

/**
 * Select all media
 *
 * @param state Store state
 * @returns List of media {@link IMedia}
 */
export const selectAllMedia = (state: RootState): IMedia[] => getMediaState(state).all;
