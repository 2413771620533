import { ThemeProvider } from '@material-ui/core';
import { OpenVidu } from 'openvidu-browser';
import { Provider } from 'react-redux';
import { useParams, useSearchParams } from 'react-router-dom';
import { ApiClientProvider } from '../shared/core/api/context/apiclient.context';
import { OpenviduLocalProvider } from '../shared/core/context/provider/openvidu/OpenviduLocalProvider';
import { OpenviduScreenShareProvider } from '../shared/core/context/provider/openvidu/OpenviduScreenShareProvider';
import store from '../store/store';
import { themeDark } from '../theme';
import Main from './main/Main';

export default function App() {
  const { roomCode } = useParams<{ roomCode: string }>();
  const [searchParams] = useSearchParams();
  const projectId = searchParams.get('project');
  const OVLocal = new OpenVidu();
  const OVScreenShare = new OpenVidu();

  return (
    <ThemeProvider theme={themeDark}>
      <Provider store={store}>
        <ApiClientProvider>
          <OpenviduLocalProvider OV={OVLocal} projectId={projectId}>
            <OpenviduScreenShareProvider OV={OVScreenShare} projectId={projectId}>
              <Main sessionId={roomCode} projectId={projectId} />
            </OpenviduScreenShareProvider>
          </OpenviduLocalProvider>
        </ApiClientProvider>
      </Provider>
    </ThemeProvider>
  );
}
