import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IRoom {
  readonly id: string;
  readonly code: string;
  readonly name: string;
  readonly organization: string;
  readonly projects: string[];
  readonly groups: string[];
  readonly users: string[];
  readonly storageId: string;
  readonly image: string;
  readonly enabled: boolean;
  readonly expiredAt: Date | null;
}

export type RoomState = {
  selectedRoom: IRoom | null;
  tag: string | null;
};

const initialStateRoom: RoomState = {
  selectedRoom: null,
  tag: null
};

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const roomSlice = createSlice({
  name: 'room',
  initialState: initialStateRoom,
  reducers: {
    setRoom: (state, action: PayloadAction<IRoom>) => {
      state.selectedRoom = action.payload;
    },
    setTag: (state, action: PayloadAction<string>) => {
      state.tag = action.payload;
    }
  }
});

export const { setRoom, setTag } = roomSlice.actions;
export default roomSlice.reducer;
