import React, { useEffect } from 'react';
import usePipScreenSupported from './usePipScreenSupported';

function getBrowserPictureInPictureElementProp(): string {
  if (typeof document.pictureInPictureElement !== 'undefined') {
    return 'pictureInPictureElement';
  } else {
    return 'undefined';
  }
}

/**
 * Usage of Picture in picture.
 * @param elRef Video element ref
 * @note Firefox is not compatible with Picture in Picture
 * https://developer.mozilla.org/en-US/docs/Web/API/HTMLVideoElement/requestPictureInPicture
 */
export default function usePipScreen(
  elRef: React.RefObject<HTMLVideoElement>
): [boolean, boolean, () => void, () => void] {
  const [isPipScreen, setIsPipScreen] = React.useState(
    document[getBrowserPictureInPictureElementProp()] != null
  );

  const [isFeatureSupported] = usePipScreenSupported();

  const setPipScreen = () => {
    if (elRef.current == null || isPipScreen) {
      return;
    }

    elRef.current
      .requestPictureInPicture()
      .then(() => {
        setIsPipScreen(document[getBrowserPictureInPictureElementProp()] != null);
      })
      .catch(() => {
        setIsPipScreen(false);
      });
  };

  const closePipScreen = () => {
    if (elRef.current == null || !isPipScreen) {
      return;
    }

    document
      .exitPictureInPicture()
      .then(() => {
        setIsPipScreen(document[getBrowserPictureInPictureElementProp()] != null);
      })
      .catch(() => {
        setIsPipScreen(false);
      });
  };

  useEffect(() => {
    const video = elRef.current;
    if (!video) {
      return;
    }

    const onEnterPIP = () => setIsPipScreen(true);
    const onLeavePIP = () => setIsPipScreen(false);

    video.addEventListener('enterpictureinpicture', onEnterPIP);
    video.addEventListener('leavepictureinpicture', onLeavePIP);
    return () => {
      video.removeEventListener('enterpictureinpicture', onEnterPIP);
      video.removeEventListener('leavepictureinpicture', onLeavePIP);
    };
  }, []);

  return [isPipScreen, isFeatureSupported, setPipScreen, closePipScreen];
}
