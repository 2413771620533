import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface IMedia {
  readonly createdAt: string;
  readonly updatedAt: string;
  readonly id: string;
  readonly name: string;
  readonly key: string;
  readonly dimension: number;
  readonly type: string;
  readonly path: string;
  readonly storageId: string;
  readonly uploadedBy: string;
  readonly tag: string;
}

export type MediaState = {
  all: IMedia[];
};

const initialStateMedia: MediaState = {
  all: []
};

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const mediaSlice = createSlice({
  name: 'media',
  initialState: initialStateMedia,
  reducers: {
    setAllMedia: (state, action: PayloadAction<IMedia[]>) => {
      state.all = action.payload;
    }
  }
});

export const { setAllMedia } = mediaSlice.actions;
export default mediaSlice.reducer;
