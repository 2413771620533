import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React, { FC } from 'react';

const PinOffIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="-4 -4 42 42">
      <path
        d="M0,5.232 L2.048,3.2 L28.8,29.952 L26.768,32 L17.28,22.512 L17.28,32 L14.72,32 L14.72,22.4 L6.4,22.4 L6.4,19.2 L9.6,16 L9.6,14.832 L0,5.232 M22.4,16 L25.6,19.2 L25.6,22.4 L25.312,22.4 L9.6,6.688 L9.6,3.2 L8,3.2 L8,0 L24,0 L24,3.2 L22.4,3.2 L22.4,16 Z"
        transform="translate(2)"
      />
    </SvgIcon>
  );
};

export default React.memo(PinOffIcon);
