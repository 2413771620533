// eslint-disable-next-line import/no-unresolved
import { joinRoom, leaveRoom, sessionTag } from '@cloudvision/um-global';
import { useEffect } from 'react';
import { Subscription } from 'rxjs';
import { setTag } from '../../../store/rooms/roomReducer';
import { AppDispatch, useAppDispatch } from '../../../store/store';

type ISocket = {
  join: (room: string) => void;
  leave: (room: string) => void;
};

export default function useSocket(): ISocket {
  const dispatch: AppDispatch = useAppDispatch();

  useEffect(() => {
    const subscription: Subscription = sessionTag.subscribe((t: string | null) => {
      dispatch(setTag(t));
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const join = (room: string) => {
    joinRoom(room);
  };

  const leave = (room: string) => {
    leaveRoom(room);
  };

  return {
    join,
    leave
  };
}
