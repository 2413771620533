import { SendMessageChat } from '../../core/models/socket/event.model';
// eslint-disable-next-line import/no-unresolved
import { messages, sendMessage } from '@cloudvision/um-global';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { Subscription } from 'rxjs';
import { selectId, selectName } from '../../../store/auth/authSelectors';
import { addMessage } from '../../../store/chat/chatReducer';
import { selectOrderedMessages } from '../../../store/chat/chatSelectors';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../../store/store';
import { MessageChat } from '../../core/models/message.model';

type IChatMessage = {
  sendChatMessage: (
    body: string,
    recipient: string,
    attachment?: { url: string; name: string }
  ) => void;
  messages: MessageChat[];
};

export default function useChatMessages(context: string, subscribe = true): IChatMessage {
  const dispatch: AppDispatch = useAppDispatch();
  const messagesList = useAppSelector(selectOrderedMessages);
  const [chatContext] = useState<string>(context);
  const name = useAppSelector(selectName);
  const id = useAppSelector(selectId);

  useEffect(() => {
    if (subscribe) {
      const subscription: Subscription = messages.subscribe((m: SendMessageChat) => {
        dispatch(addMessage({ ...m, readed: false }));
      });
      return () => {
        subscription.unsubscribe();
      };
    }
  }, []);

  const sendChatMessage = (
    body: string,
    recipient: string,
    attachment?: { url: string; name: string }
  ) => {
    const contextualMessage: SendMessageChat = {
      context: chatContext,
      message: body,
      recipient,
      sender: id,
      sender_display_name: name,
      timestamp: moment().valueOf(),
      attachment
    };
    sendMessage(contextualMessage);
    dispatch(addMessage({ ...contextualMessage, readed: true }));
  };

  return {
    sendChatMessage,
    messages: messagesList
  };
}
