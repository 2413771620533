import { RootState } from '../store';
import { IRole, RoleState } from './roleReducer';

export const getRoleState = (store: RootState): RoleState => store.role;

/**
 * Select all role
 *
 * @param state Store state
 * @returns List of role {@link IRole}
 */
export const selectAllRole = (state: RootState): IRole[] => getRoleState(state).all;
