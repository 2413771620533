import { Button, Dialog } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { SimpleDialogProps } from '../nav/nav';
import './genericDialog.scss';

export default function GenericDialog(props: SimpleDialogProps) {
  const { onClose, open, text, subText } = props;
  const { t } = useTranslation();

  return (
    <Dialog open={open} className="dialog">
      <div className="size-confirm-dialog">
        <div className="container-version-support">
          <p className="version">{text}</p>
          <p className="email-support">{subText}</p>
        </div>
        <div className="container-button">
          <Button className="button-submit" onClick={onClose}>
            {t('DIALOG_VERSION:close')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
