import { useState } from 'react';
import { Role } from '../../models/role.model';
import { useFetchListClient } from '../api.handle.call';

export function useGetRoles(): {
  roles: Role[] | [];
  fetchRoles: () => void;
  fetchRolesError: Error | null;
  pending: boolean;
} {
  const [roles, setRoles] = useState<Role[]>();

  const [fetchRolesError, setError] = useState<Error | null>(null);

  const [fetchRoles, pending] = useFetchListClient<Role>(`/roles/`, setRoles, setError);

  return { roles, fetchRoles, fetchRolesError, pending };
}
