import React, { FC, ReactNode, useEffect, useState } from 'react';
import { ParticipantType } from '../../../../shared/enum/participant-type.enum';
import './ParticipantControlsHeader.scss';
import CloseIcon from '@mui/icons-material/Close';
import CircleIconButton from '../../../../shared/ui/CircleIconButton/CircleIconButton';
import PinOffIcon from '../../../../shared/ui/Icons/PinOffIcon';
import PinIcon from '../../../../shared/ui/Icons/PinIcon';
import ParticipantName from '../../../../shared/ui/ParticipantName/ParticipantName';

type IParticipantControlsHeaderProps = {
  name: string;
  type: ParticipantType;
  haveControls: boolean;
  showAudioInfo: boolean;
  pinned: boolean;
  onClose: () => void;
  onTogglePin: () => void;
};

const ParticipantControlsHeader: FC<IParticipantControlsHeaderProps> = ({
  name,
  type,
  haveControls,
  showAudioInfo,
  pinned,
  onClose,
  onTogglePin
}): JSX.Element => {
  const [pinnedIcon, setPinnedIcon] = useState<JSX.Element | ReactNode>(<PinOffIcon />);

  useEffect(() => {
    setPinnedIcon(pinned ? <PinOffIcon /> : <PinIcon />);
  }, [pinned]);

  return (
    <div className="participant__container">
      <div className="participant__info">
        <ParticipantName name={name} type={type} showAudioInfo={showAudioInfo} />
      </div>
      <div className="participant__info--grow"></div>
      {haveControls ? (
        <React.Fragment>
          <div>
            <CircleIconButton
              icon={pinnedIcon}
              type="dark-opacity"
              description="Pin"
              size="small"
              onClick={onTogglePin}
            />
          </div>
          <div>
            <CircleIconButton
              icon={<CloseIcon />}
              type="dark-opacity"
              description="Close"
              size="small"
              onClick={onClose}
            />
          </div>
        </React.Fragment>
      ) : null}
    </div>
  );
};

export default React.memo(ParticipantControlsHeader);
