import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React, { FC } from 'react';

const CameraFlipIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="-4 -4 42 42">
      <path
        d="M28.8,3.2 L24,3.2 L20.8,0 L11.2,0 L8,3.2 L3.2,3.2 C1.44,3.2 0,4.64 0,6.4 L0,25.6 C0,27.376 1.44,28.8 3.2,28.8 L28.8,28.8 C30.576,28.8 32,27.376 32,25.6 L32,6.4 C32,4.64 30.576,3.2 28.8,3.2 M4.8,14.4 L8.16,14.4 C9.04,10.064 13.264,7.28 17.6,8.16 C18.816,8.4 19.888,8.944 20.8,9.6 L18.496,11.92 C17.776,11.472 16.928,11.2 16,11.2 C13.984,11.2 12.16,12.48 11.488,14.4 L14.4,14.4 L9.6,19.2 L4.8,14.4 M23.856,17.6 C22.976,21.936 18.752,24.72 14.4,23.84 C13.2,23.584 12.128,23.056 11.2,22.4 L13.504,20.08 C14.24,20.528 15.088,20.8 16,20.8 C18.032,20.8 19.856,19.52 20.528,17.6 L17.6,17.6 L22.4,12.8 L27.2,17.6 L23.856,17.6 Z"
        transform="translate(0 2)"
      />
    </SvgIcon>
  );
};

export default React.memo(CameraFlipIcon);
