// eslint-disable-next-line import/no-unresolved
import { sendRemoteCommandEvent } from '@cloudvision/um-global';
import moment from 'moment';
import { useState } from 'react';
import { selectId, selectName } from '../../../store/auth/authSelectors';
import { useAppSelector } from '../../../store/store';
import { CommandType, RemoteCommandEvent } from '../../core/models/socket/event.model';

type IRemoteCommand = {
  sendRemoteCommand: (command: CommandType, recipient: string) => void;
};

export default function useRemoteCommand(context: string): IRemoteCommand {
  const [chatContext] = useState<string>(context);
  const name = useAppSelector(selectName);
  const id = useAppSelector(selectId);

  const sendRemoteCommand = (command: CommandType, recipient: string) => {
    const contextualRemoteCommand: RemoteCommandEvent = {
      command,
      recipient,
      sender: id,
      sender_display_name: name,
      context: chatContext,
      timestamp: moment().valueOf()
    };
    sendRemoteCommandEvent(contextualRemoteCommand);
  };

  return {
    sendRemoteCommand
  };
}
