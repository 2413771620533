import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Guard from './Guard';
import { ConfigProvider } from './shared/core/context/provider/ConfigProvider';
import { AuthStream } from './shared/core/models/auth.model';
import './shared/styles/_layout.scss';
import './shared/styles/_typography.scss';

export default function Root() {
  const getLocalStorageAuth: AuthStream | null =
    sessionStorage.getItem('coreAuth') !== null
      ? JSON.parse(window.atob(sessionStorage.getItem('coreAuth') as string))
      : null;
  const [isAuth] = useState(getLocalStorageAuth?.token ?? null);

  return (
    <React.StrictMode>
      <ConfigProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/stream/:roomCode" element={<Guard isAuth={isAuth} />} />
          </Routes>
        </BrowserRouter>
      </ConfigProvider>
    </React.StrictMode>
  );
}
