/* eslint-disable import/no-unresolved */
import {
  recording,
  startRecordingEvent as startRecording,
  stopRecordingEvent as stopRecording
} from '@cloudvision/um-global';
import moment from 'moment';
import { useEffect } from 'react';
import { Subscription } from 'rxjs';
import { selectId, selectName } from '../../../store/auth/authSelectors';
import {
  startRecording as startRecordingAction,
  stopRecording as stopRecordingAction
} from '../../../store/openvidu/openviduReducer';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../../store/store';
import { StartRecordingEvent, StopRecordingEvent } from '../../core/models/socket/event.model';

type IUseRecordingEvent = {
  startRecordingEvent: (chatContext: string, recordingId: string) => void;
  stopRecordingEvent: (chatContext: string) => void;
};

export default function useRecordingEvent(): IUseRecordingEvent {
  const dispatch: AppDispatch = useAppDispatch();
  const currentUserName = useAppSelector(selectName);
  const id = useAppSelector(selectId);

  useEffect(() => {
    const subscription: Subscription = recording.subscribe((m: StartRecordingEvent | null) => {
      if (m !== null) {
        dispatch(startRecordingAction(m.recordingId));
      } else {
        dispatch(stopRecordingAction());
      }
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const startRecordingEvent = (chatContext: string, recordingId: string) => {
    const contextualRecordingMessage: StartRecordingEvent = {
      recordingId,
      sender: id,
      sender_display_name: currentUserName,
      recipient: chatContext,
      context: chatContext,
      timestamp: moment().valueOf()
    };
    startRecording(contextualRecordingMessage);
  };
  const stopRecordingEvent = (chatContext: string) => {
    const contextualRecordingMessage: StopRecordingEvent = {
      sender: id,
      sender_display_name: currentUserName,
      recipient: chatContext,
      context: chatContext,
      timestamp: moment().valueOf()
    };
    stopRecording(contextualRecordingMessage);
  };

  return {
    startRecordingEvent,
    stopRecordingEvent
  };
}
