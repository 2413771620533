import { createSlice } from '@reduxjs/toolkit';
import { PolicyType } from '../../shared/enum/policies.enum';
import { UserProvider } from '../../shared/enum/user-provider.enum';

export interface IAuth {
  readonly id: string | null;
  readonly email: string | null;
  readonly token: string | null;
  readonly username: string | null;
  readonly lastName: string | null;
  readonly enabled: boolean;
  readonly firstName: string | null;
  readonly provider: UserProvider;
  readonly organization: string | null;
  readonly policies: Array<PolicyType>;
  readonly role: string | null;
  readonly lastLogin: string | null;
  readonly expiresAt: Date | null;
}

export type AuthState = {
  me: IAuth | null;
};

const initialStateAuth: AuthState = {
  me:
    sessionStorage.getItem('coreAuth') !== null
      ? JSON.parse(window.atob(sessionStorage.getItem('coreAuth') as string))
      : null
};

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const authSlice = createSlice({
  name: 'auth',
  initialState: initialStateAuth,
  reducers: {
    setMe: (state, action) => {
      state.me = action.payload;
    }
  }
});

export const { setMe } = authSlice.actions;
export default authSlice.reducer;
