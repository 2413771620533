import { MutableRefObject, useEffect, useRef } from 'react';

export default function useContainerScroll<T>(dep: T): MutableRefObject<HTMLDivElement> {
  const ref = useRef<HTMLDivElement>();
  useEffect(() => {
    if (ref.current) {
      ref.current.scrollTop = ref.current.scrollHeight;
    }
  }, [dep]);
  return ref;
}
