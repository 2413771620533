import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React, { FC } from 'react';

const ScreenShareOffIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 24 24">
      <g id="icons/screenshare_end">
        <g id="cancel_presentation">
          <path
            id="Shape"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M3 3.74438H21C22.1 3.74438 23 4.64438 23 5.74438V19.7444C23 20.8444 22.1 21.7444 21 21.7444H3C1.9 21.7444 1 20.8444 1 19.7444V5.74438C1 4.64438 1.9 3.74438 3 3.74438ZM3 19.8444H21V5.74438H3V19.8444Z"
          />
          <path
            id="Path"
            d="M14.59 8.74438L12 11.3344L9.41 8.74438L8 10.1544L10.59 12.7444L8 15.3344L9.41 16.7444L12 14.1544L14.59 16.7444L16 15.3344L13.41 12.7444L16 10.1544L14.59 8.74438Z"
          />
        </g>
      </g>
    </SvgIcon>
  );
};

export default React.memo(ScreenShareOffIcon);
