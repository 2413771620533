import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';
import React, { FC } from 'react';

const TraitThinIcon: FC<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 21 13">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-602.000000, -410.000000)" fill="#000000" fillRule="nonzero">
          <g transform="translate(602.000000, 410.000000)">
            <path d="M19.5728581,4.83547005 C20.0828581,3.72547005 20.4128581,2.23547005 19.1528581,1.40547005 C18.2428581,0.795470054 16.9528581,0.815470054 15.2128581,1.46547005 C13.2828581,2.19547005 11.7028581,3.46547005 10.0228581,4.80547005 C8.78285812,5.80547005 7.49285812,6.83547005 6.03285812,7.64547005 C5.25285812,8.09547005 4.33285812,8.62547005 3.32285812,8.75547005 C2.91285812,8.82547005 2.23285812,8.94547005 1.76285812,8.41547005 C1.29285812,7.89547005 1.48285812,7.24547005 1.62285812,6.77547005 C2.26285812,4.53547005 4.63285812,2.37547005 6.14285812,1.02547005 C6.27285812,0.745470054 6.40285812,0.365470054 6.24285812,0.195470054 L6.17285812,0.115470054 C6.01285812,-0.0845299462 5.49285812,0.0154700538 5.17285812,0.115470054 C3.57285812,1.58547005 -0.11714188,5.06547005 0.00285812026,7.99547005 C0.0728581203,8.74547005 0.38285812,9.29547005 0.93285812,9.64547005 C1.79285812,10.1854701 3.26285812,10.1954701 4.66285812,9.67547005 L4.68285812,9.67547005 C6.90285812,8.88547005 8.71285812,7.44547005 10.6928581,5.77547005 C12.1028581,4.52547005 13.7228581,3.48547005 15.3628581,2.78547005 L15.4728581,2.73547005 C16.2128581,2.39547005 17.2228581,1.92547005 18.0128581,2.47547005 C18.2528581,2.64547005 18.6528581,3.04547005 18.5928581,3.83547005 C18.5028581,4.97547005 17.5028581,6.12547005 16.6928581,7.03547005 C16.5328581,7.21547005 16.3928581,7.37547005 16.2828581,7.51547005 C15.1628581,8.91547005 14.0728581,10.1054701 12.9428581,11.1354701 C12.6228581,11.9354701 12.7228581,12.1954701 12.7228581,12.1954701 C12.8528581,12.2954701 13.2228581,12.3154701 13.8628581,12.0954701 C16.3828581,9.90547005 18.4528581,7.26547005 19.5628581,4.81547005 L19.5728581,4.83547005 Z"></path>
          </g>
        </g>
      </g>
    </SvgIcon>
  );
};

export default React.memo(TraitThinIcon);
