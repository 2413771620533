import {
  Button,
  Checkbox,
  Chip,
  Dialog,
  FormControl,
  ListItemText,
  MenuItem,
  Select
} from '@material-ui/core';
import CancelIcon from '@mui/icons-material/Cancel';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateInviatePossibleError } from '../../shared/core/api/hook-call/user.hook';
import useAssetUrl from '../../shared/hooks/useAssetUrl';
import { setSnackbar } from '../../store/snackbar/snackbarReducer';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../store/store';
import { selectAllUsersOrganization } from '../../store/user/userSelectors';
import './ParticipantListContainer.scss';
import { SimpleDialogProps } from './ParticipantListHeader';

export default function DialogInvitation(props: SimpleDialogProps) {
  const handleClose = () => {
    setGuestEmail([]);
    onClose();
  };

  const [idUsers, setIdUsers] = React.useState([]);
  const [userRegisteredSelected, setUserRegisteredSelected] = React.useState<string[]>([]);

  const [guestEmail, setGuestEmail] = React.useState([]);

  const [message, setMessage] = React.useState('');

  const [errorMessage, setErrorMessage] = React.useState(false);

  const { onClose, open } = props;
  const { getAsset } = useAssetUrl();

  const listUserAssociatedOrganization = useAppSelector(selectAllUsersOrganization);

  const { arrayUsers, sendInvite } = useCreateInviatePossibleError();

  const dispatch: AppDispatch = useAppDispatch();

  const { t } = useTranslation();

  const Chips = guestEmail.map((guest) => (
    <Chip
      className={validateEmail(guest) ? 'chip-input' : 'chip-input-error'}
      label={guest}
      onDelete={() => handleDelete(guest)}
      key={guest}
    />
  ));

  const onAddBtnClick = (event) => {
    if (event.key === 'Enter') {
      if (!guestEmail.includes(message)) {
        setGuestEmail((guestEmailUser) => guestEmailUser.concat(message));
      }
      setMessage('');
    }
  };

  function validateEmail(email: string) {
    const regex = new RegExp('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[a-z]+$');

    return regex.test(email);
  }

  const useInvite = () => {
    if (message !== '') {
      setErrorMessage(true);
    } else {
      setErrorMessage(false);
      sendInvite(guestEmail, idUsers);
      setUserRegisteredSelected([]);
      setGuestEmail([]);
      setIdUsers([]);
      onClose();
    }
  };

  useEffect(() => {
    if (arrayUsers && arrayUsers.length > 0) {
      const error = arrayUsers.find((e) => {
        return e === 'Generic error';
      });

      const successWithError = arrayUsers.find((e) => {
        return e === 'noError';
      });

      if (error !== undefined && successWithError !== undefined) {
        dispatch(setSnackbar('success_error'));
      } else if (error === undefined && successWithError !== undefined) {
        dispatch(setSnackbar('success'));
      } else if (error === undefined && successWithError === undefined) {
        dispatch(setSnackbar('error'));
      } else {
        return;
      }
    }
  }, [arrayUsers]);

  const handleDelete = (h) => {
    setGuestEmail((guestEmailUser) => guestEmailUser.filter((user) => user !== h));
  };

  function removeUserSelected(userId: string) {
    const array = [...idUsers];
    const index = array.indexOf(userId);
    if (index !== -1) {
      array.splice(index, 1);
      setIdUsers(array);
    } else {
      setIdUsers([...idUsers, userId]);
    }
  }

  function userSelected(userId: string, userFirstName: string, userLastName: string) {
    const array = [...userRegisteredSelected];
    const index = array.indexOf(`${userFirstName} ${userLastName}`);
    const arrayIdUsers = [...idUsers];
    const indexIdUsers = arrayIdUsers.indexOf(userId);
    if (indexIdUsers !== -1) {
      array.splice(index, 1);
      setUserRegisteredSelected(array);
    } else {
      setUserRegisteredSelected([...userRegisteredSelected, `${userFirstName} ${userLastName}`]);
    }
  }

  return (
    <Dialog open={open}>
      <div className="container-dialog">
        <div className="title-close">
          <span className="invite-people">{t('DIALOG_INVITATION:invite_people')}</span>
          <div onClick={handleClose} className="container-close">
            <CancelIcon className="icon-close" fontSize="medium" />
          </div>
        </div>
        <div className="contianer-registered-select-users">
          <div className="container-registered">
            <img className="icon" src={getAsset('users.svg')} />
            <span className="registered-users-guest">
              {t('DIALOG_INVITATION:registered_users')}
            </span>
          </div>
          <FormControl>
            <Select
              multiple
              value={userRegisteredSelected}
              disableUnderline
              renderValue={(selected: string) => selected + ', '}>
              {listUserAssociatedOrganization.map((user) => (
                <MenuItem
                  onClick={() => {
                    removeUserSelected(user.id);
                    userSelected(user.id, user.firstName, user.lastName);
                  }}
                  key={user.id}
                  value={user.id}>
                  <Checkbox checked={idUsers.indexOf(user.id) > -1} />
                  <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>

        <div className="container-select-guest">
          <div className="container-guests">
            <img className="icon" src={getAsset('projects.svg')} />
            <span className="registered-users-guest">{t('DIALOG_INVITATION:guests')}</span>
          </div>
          <div className="container-chipinput">
            {Chips}
            <input
              value={message}
              onKeyDown={(event) => onAddBtnClick(event)}
              onChange={(event) => setMessage(event.target.value)}
              className="input-guest"
              placeholder={t('DIALOG_INVITATION:type')}
            />
          </div>
          {errorMessage && (
            <span className="error_email_condition">{t('DIALOG_INVITATION:type_condition')}</span>
          )}
        </div>
        <div className="container-cancel-invite">
          <Button onClick={handleClose} className="cancel">
            {t('DIALOG_INVITATION:cancel')}
          </Button>
          <Button onClick={useInvite} className="invite">
            {t('DIALOG_INVITATION:invite')}
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
