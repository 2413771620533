/**
 * Enum of Google Vision Api
 */
export enum FeatureType {
  TYPE_UNSPECIFIED = 'TYPE_UNSPECIFIED',
  FACE_DETECTION = 'FACE_DETECTION',
  LANDMARK_DETECTION = 'LANDMARK_DETECTION',
  LOGO_DETECTION = 'LOGO_DETECTION',
  LABEL_DETECTION = 'LABEL_DETECTION',
  TEXT_DETECTION = 'TEXT_DETECTION',
  DOCUMENT_TEXT_DETECTION = 'DOCUMENT_TEXT_DETECTION',
  SAFE_SEARCH_DETECTION = 'SAFE_SEARCH_DETECTION',
  IMAGE_PROPERTIES = 'IMAGE_PROPERTIES',
  CROP_HINTS = 'CROP_HINTS',
  WEB_DETECTION = 'WEB_DETECTION',
  PRODUCT_SEARCH = 'PRODUCT_SEARCH',
  OBJECT_LOCALIZATION = 'OBJECT_LOCALIZATION'
}
