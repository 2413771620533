import { AxiosError, AxiosResponse } from 'axios';
import { useToggle } from '../../hooks/useToggle';
import { useApiClient } from './context/apiclient.context';
import { ErrorType, handleErrorAPI, ResponseWrapper } from './response/handle.response';

export function useCreateClient<T, K>(
  path: string,
  dataSetter: React.Dispatch<React.SetStateAction<T | null>>,
  errorSetter: React.Dispatch<React.SetStateAction<string | null>>
): [(body: K) => void, boolean] {
  const { client: api } = useApiClient();
  const [pending, setPending] = useToggle();

  const create = async (body: K): Promise<void> => {
    dataSetter(null);
    errorSetter(null);
    setPending(true);
    try {
      const res = await api?.client.post<ResponseWrapper<T>, AxiosResponse<ResponseWrapper<T>>, K>(
        path,
        body
      );
      dataSetter(res.data.data);
    } catch (e) {
      const error = e as Error | AxiosError;
      errorSetter(handleErrorAPI(error, ErrorType.CREATE.valueOf()));
    } finally {
      setPending(false);
    }
  };

  return [create, pending];
}

export function useCreateInviteUser<T, K>(
  path: string,
  dataSetter: React.Dispatch<React.SetStateAction<T | null>>,
  errorSetter: React.Dispatch<React.SetStateAction<Error | string>>
): [(body: K) => void, boolean] {
  const { client: api } = useApiClient();
  const [pending, setPending] = useToggle();

  const create = async (body: K): Promise<void> => {
    dataSetter(null);
    errorSetter(null);
    setPending(true);
    try {
      const res = await api?.client.post<ResponseWrapper<T>, AxiosResponse<ResponseWrapper<T>>, K>(
        path,
        body
      );
      errorSetter('noError');
      dataSetter(res.data.data);
    } catch (e) {
      const error = e as Error | AxiosError;
      errorSetter(handleErrorAPI(error, ErrorType.CREATE.valueOf()));
    } finally {
      setPending(false);
    }
  };

  return [create, pending];
}

export function useCreateRecordingUser<K>(
  path: string,
  dataSetter: React.Dispatch<React.SetStateAction<K | null>>,
  errorSetter: React.Dispatch<React.SetStateAction<Error | string>>
): [(body: K) => void, boolean] {
  const { client: api } = useApiClient();
  const [pending, setPending] = useToggle();

  const create = async (body: K): Promise<void> => {
    dataSetter(null);
    errorSetter(null);
    setPending(true);
    try {
      const res = await api?.client.post<ResponseWrapper<K>, AxiosResponse<ResponseWrapper<K>>, K>(
        path,
        body
      );
      errorSetter('noError');
      dataSetter(res.data.data);
    } catch (e) {
      const error = e as Error | AxiosError;
      errorSetter(handleErrorAPI(error, ErrorType.CREATE.valueOf()));
    } finally {
      setPending(false);
    }
  };

  return [create, pending];
}

export function useFetchListClient<T>(
  path: string,
  responseSetter: React.Dispatch<React.SetStateAction<T[]>>,
  errorSetter: React.Dispatch<React.SetStateAction<Error | null>>
): [() => void, boolean] {
  const { client: api } = useApiClient();
  const [pending, setPending] = useToggle();

  const refresh = async (): Promise<void> => {
    responseSetter([]);
    errorSetter(null);
    setPending(true);
    try {
      const res = await api.client.get<ResponseWrapper<T[]>>(path, { data: {} });
      responseSetter(res.data.data);
    } catch (e) {
      const error = e as Error | AxiosError;
      errorSetter(Error(handleErrorAPI(error, ErrorType.GET.valueOf())));
    } finally {
      setPending(false);
    }
  };
  return [refresh, pending];
}

export function useFetchSingleClient<T>(
  path: string,
  responseSetter: React.Dispatch<React.SetStateAction<T>>,
  errorSetter: React.Dispatch<React.SetStateAction<Error | null>>
): [() => void, boolean] {
  const { client: api } = useApiClient();
  const [pending, setPending] = useToggle();

  const refresh = async (): Promise<void> => {
    responseSetter(null);
    errorSetter(null);
    setPending(true);
    try {
      const res = await api.client.get<ResponseWrapper<T>>(path, { data: {} });
      responseSetter(res.data.data);
    } catch (e) {
      const error = e as Error | AxiosError;
      errorSetter(Error(handleErrorAPI(error, ErrorType.GET.valueOf())));
    } finally {
      setPending(false);
    }
  };
  return [refresh, pending];
}

export function useUpdateClient<T, U, K>(
  path: string,
  setter: React.Dispatch<React.SetStateAction<T | null>>,
  errorSetter: React.Dispatch<React.SetStateAction<string | null>>
): [(id: U, body: K) => void] {
  const { client } = useApiClient();

  const update = (id: U, body: K): void => {
    setter(null);
    errorSetter(null);
    client?.client
      .put<ResponseWrapper<T>, AxiosResponse<ResponseWrapper<T>>, K>(`${path}${id}`, body)
      .then((res) => {
        setter(res.data.data);
      })
      .catch((error: Error | AxiosError<T>) => {
        errorSetter(handleErrorAPI(error, ErrorType.UPDATE.valueOf()));
      });
  };

  return [update];
}
