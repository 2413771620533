import { RouteProps } from 'react-router-dom';
import App from './features/App';
import { useConfig } from './shared/core/context/provider/ConfigProvider';

interface Props extends RouteProps {
  isAuth: string | null;
}

const Guard = ({ isAuth }: Props) => {
  const { url } = useConfig();
  if (isAuth !== null) {
    return <App />;
  } else {
    window.location.replace(url.base);
    return null;
  }
};

export default Guard;
