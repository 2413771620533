import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ParticipantStatus } from '../../shared/core/models/openvidu/participant.model';
import { ParticipantKind, ParticipantType } from '../../shared/enum/participant-type.enum';

export interface IParticipant {
  id: string;
  connectionId: string;
  roleId: string;
  name: string;
  projectId: string;
  kind: ParticipantKind;
  type: ParticipantType;
  status: ParticipantStatus;
}

export type ParticipantState = {
  all: IParticipant[];
};

const initialStateParticipant: ParticipantState = {
  all: []
};

// Redux Toolkit allows us to write "mutating" logic in reducers. It
// doesn't actually mutate the state because it uses the Immer library,
// which detects changes to a "draft state" and produces a brand new
// immutable state based off those changes
export const participantSlice = createSlice({
  name: 'participant',
  initialState: initialStateParticipant,
  reducers: {
    setParticipant: (state, action: PayloadAction<IParticipant[]>) => {
      state.all = action.payload;
    }
  }
});

export const { setParticipant } = participantSlice.actions;

export default participantSlice.reducer;
