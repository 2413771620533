import { Box, Typography } from '@material-ui/core';
import { FC, HTMLAttributes, memo } from 'react';

type ITabPanelProps = {
  children?: React.ReactNode;
  index: number;
  value: number;
};

const TabPanel: FC<ITabPanelProps & HTMLAttributes<HTMLDivElement>> = ({
  children,
  value,
  index,
  className = ''
}): JSX.Element => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      className={className}
      aria-labelledby={`full-width-tab-${index}`}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

export default memo(TabPanel);
