import React, { createContext, useContext } from 'react';
import { IConfigProvider } from '../../models/provider/config.provider.model';
import { useProvideConfig } from '../hooks/useProvideConfig';

const ConfigContext = createContext<IConfigProvider>({
  env: 'none',
  url: {
    base: '',
    projectBase: ''
  },
  openvidu: {
    url: '',
    secret: ''
  },
  visionApi: {
    url: '',
    key: '',
    maxResults: 0
  },
  tus: {
    url: '',
    chunkSize: 0
  }
});

export const useConfig = (): IConfigProvider => {
  return useContext(ConfigContext);
};

export function ConfigProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const config = useProvideConfig();
  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>;
}
