import MicOffIcon from '@mui/icons-material/MicOff';
import { FC, HTMLAttributes, memo, useEffect, useState } from 'react';

import { ParticipantType } from '../../enum/participant-type.enum';
import useAssetUrl from '../../hooks/useAssetUrl';
import './ParticipantName.scss';

type IParticipantNameProps = {
  name: string;
  type: ParticipantType;
  showAudioInfo: boolean;
};

const ParticipantNameProps: FC<IParticipantNameProps & HTMLAttributes<HTMLDivElement>> = ({
  name,
  type,
  showAudioInfo,
  className = ''
}): JSX.Element => {
  const { getAsset } = useAssetUrl();

  const [participantIconClass, setParticipantIconClass] = useState<string>(
    'participant-name__icon--web'
  );
  useEffect(() => {
    switch (type) {
      case ParticipantType.GLASS:
        setParticipantIconClass('participant-name__icon--glass');
        setParticipantIcon('glasses-update.svg');
        break;
      case ParticipantType.MOBILE:
        setParticipantIconClass('participant-name__icon--mobile');
        setParticipantIcon('glasses-update.svg');
        break;
      default:
        setParticipantIconClass('participant-name__icon--web');
        setParticipantIcon('pc-update.svg');
    }
  }, [type]);

  const [participantIcon, setParticipantIcon] = useState<string>('laptop.svg');

  return (
    <div className={'participant-name__info ' + className}>
      <div className={participantIconClass + ' participant-name__icon'}>
        <img src={getAsset(participantIcon)} className="participant-name__icon--image" />
      </div>
      {showAudioInfo && (
        <div className={'participant-name__icon participant-name__icon--muted'}>
          <MicOffIcon fontSize="small" />
        </div>
      )}
      <div className="participant-name__name">{name}</div>
    </div>
  );
};

export default memo(ParticipantNameProps);
