import { FC, memo, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Participant } from '../../shared/core/models/openvidu/participant.model';
import { ParticipantKind } from '../../shared/enum/participant-type.enum';
import SinglePartipant from './single-participant/SinglePartipant';

import './ParticipantList.scss';
import { useAppSelector } from '../../store/store';
import { selectTelestrator } from '../../store/telestrator/telestratorSelector';
import { IconButton, Snackbar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

type IParticipantListProps = {
  participants?: Participant[];
  firstParticipant: string | null;
  secondParticipant: string | null;
  toggleAudio: (connection: string) => void;
  switchParticipant: (connection: string) => void;
  openChat: () => void;
};

const ParticipantList: FC<IParticipantListProps> = ({
  participants = [],
  firstParticipant,
  secondParticipant,
  toggleAudio,
  switchParticipant,
  openChat
}): JSX.Element => {
  const isSwitchable = (connection: string): boolean => {
    return connection !== firstParticipant && connection !== secondParticipant;
  };

  const telestrator = useAppSelector(selectTelestrator);

  const { t } = useTranslation();

  const [activeTelestrator, setActiveTelestrator] = useState(false);

  const handleCloseSnackbarFileError = (_event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setActiveTelestrator(false);
  };

  return (
    <div className="participant-list">
      {participants.map((p) => (
        <SinglePartipant
          key={p.connectionId}
          switchable={isSwitchable(p.connectionId)}
          participant={p}
          hasAudio={p.status.localAudio}
          hasVideo={p.manager.stream.videoActive}
          chattable={p.kind === ParticipantKind.REMOTE}
          haveAudioControl={p.kind === ParticipantKind.REMOTE}
          toggleAudio={() => toggleAudio(p.connectionId)}
          switchParticipant={() => {
            if (telestrator.isActive === false) {
              setActiveTelestrator(false);
              switchParticipant(p.connectionId);
            } else {
              setActiveTelestrator(true);
            }
          }}
          requestOpenChat={openChat}
        />
      ))}
      <Snackbar
        open={activeTelestrator}
        autoHideDuration={6000}
        message={t('PARTICIPANT_LIST:telestrator')}
        action={
          <IconButton
            size="small"
            aria-label="close"
            color="inherit"
            onClick={handleCloseSnackbarFileError}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
};

export default memo(ParticipantList);
