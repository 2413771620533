import { useState } from 'react';
import { Recording } from '../../models/openvidu/recording.model';
import { useCreateRecordingUser } from '../api.handle.call';

export function useCreateRecording(): {
  createRecording: (body: Recording) => void;
  createError: string | null;
} {
  const [_, setRecording] = useState<Recording>();
  const [createError, setError] = useState<string | null>(null);
  const [createRecording] = useCreateRecordingUser<Recording>(
    `/media/recordings/`,
    setRecording,
    setError
  );
  return { createRecording, createError };
}
