import { Button, Grid, IconButton } from '@material-ui/core';
import CloseIcon from '@mui/icons-material/Close';
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import React, { FC, useEffect } from 'react';
import { useGetUserAssociatedOrganization } from '../../shared/core/api/hook-call/user.hook';
import useAssetUrl from '../../shared/hooks/useAssetUrl';
import { selectSelectedOrganization } from '../../store/organizations/organizationSelectors';
import { AppDispatch, useAppDispatch, useAppSelector } from '../../store/store';
import { setActive, setShowOption } from '../../store/timer/timerReducer';
import {
  selectHours,
  selectIsActive,
  selectMinutes,
  selectSeconds,
  selectShowButton
} from '../../store/timer/timerSelectors';
import { setUsersOrganization } from '../../store/user/userReducer';
import './ParticipantListHeader.scss';

import { useTranslation } from 'react-i18next';
import DialogInvitation from './DialogInvitation';

type IParticipantListHeaderProps = {
  onExit: () => void;
};

export interface SimpleDialogProps {
  open: boolean;
  onClose: () => void;
}

const ParticipantListHeader: FC<IParticipantListHeaderProps> = ({ onExit }): JSX.Element => {
  const minutesTimer = useAppSelector(selectMinutes);
  const secondsTimer = useAppSelector(selectSeconds);
  const hoursTimer = useAppSelector(selectHours);
  const getIsActive = useAppSelector(selectIsActive);
  const getShowButton = useAppSelector(selectShowButton);
  const { getAsset } = useAssetUrl();
  const dispatch: AppDispatch = useAppDispatch();
  const organizationId = useAppSelector(selectSelectedOrganization);
  const { usersOrganization, fetchUsersOrganization } =
    useGetUserAssociatedOrganization(organizationId);
  const [open, setOpen] = React.useState(false);
  const { t } = useTranslation();

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchUsersOrganization();
  }, []);

  useEffect(() => {
    if (usersOrganization && usersOrganization.length > 0) {
      dispatch(setUsersOrganization(usersOrganization));
    }
  }, [usersOrganization]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start"
      wrap="nowrap">
      <Grid item xs={8}>
        <Button
          onClick={handleClickOpen}
          startIcon={<GroupAddIcon />}
          size="large"
          className="text-transform--none">
          {t('PARTICIPANT_LIST:add_users')}
        </Button>
        <DialogInvitation open={open} onClose={handleClose} />
      </Grid>
      <Grid item xs={8}>
        {getShowButton === false ? (
          <div className="container-timer">
            <Button
              onClick={() => {
                dispatch(setActive(true));
                dispatch(setShowOption(true));
              }}
              size="large"
              className="text-transform--none">
              <div className="container-time-icon">
                <img className="icon-addusers" src={getAsset('timer.svg')} />
                <span className="start-timer">{t('PARTICIPANT_LIST:start_timer')}</span>
              </div>
            </Button>
          </div>
        ) : (
          <div className="container-timer">
            {getIsActive === true ? (
              <Button
                onClick={() => {
                  dispatch(setActive(false));
                  dispatch(setShowOption(true));
                }}
                className="button-stop-play">
                <img className="icon-addusers" src={getAsset('pause.svg')} />
              </Button>
            ) : (
              <Button
                onClick={() => {
                  dispatch(setActive(true));
                }}
                className="button-stop-play">
                <img className="icon-addusers" src={getAsset('play.svg')} />
              </Button>
            )}
            <div className="time">
              <span className="second">{hoursTimer}</span>
              <span>:</span>
              <span className="minutes">{minutesTimer}</span>
              <span>:</span>
              <span className="second">{secondsTimer}</span>
            </div>
          </div>
        )}
      </Grid>
      <Grid item>
        <IconButton onClick={onExit} size="small" color="primary">
          <CloseIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

export default React.memo(ParticipantListHeader);
