import { Tab, Tabs, Tooltip } from '@material-ui/core';
import { FC, memo, useState } from 'react';
import UserPlaceholder from '../../shared/ui/UserPlaceholder/UserPlaceholder';
import { selectId } from '../../store/auth/authSelectors';
import { selectCountPrivateUnreadOfSender } from '../../store/chat/chatSelectors';
import { IParticipant } from '../../store/participant/participantReducer';
import { useAppSelector } from '../../store/store';
import { getState } from '../../store/storeSelectors';
import './ChatParticipantList.scss';

type IChatParticipantListProps = {
  participants: IParticipant[];
  onParticipantSelect: (participant: string) => void;
};

const ChatParticipantList: FC<IChatParticipantListProps> = ({
  participants,
  onParticipantSelect
}): JSX.Element => {
  const [participantTab, setParticipantTab] = useState<number>(0);

  const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
    setParticipantTab(newValue);
  };
  const store = useAppSelector(getState);
  const me = useAppSelector(selectId);

  return (
    <Tabs
      value={participantTab}
      onChange={handleChange}
      variant="scrollable"
      scrollButtons="auto"
      aria-label="scrollable list of participant"
      className="chat-participants-list">
      {participants.map((p) => (
        <Tooltip title={p.name} placement="top" key={p.connectionId}>
          <Tab
            onClick={(event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
              event.preventDefault();
              onParticipantSelect(p.id);
            }}
            label={
              <div className="chat-participants-list__user-tab">
                {selectCountPrivateUnreadOfSender(store, me, p.id) > 0 ? (
                  <div className="notification-dot"></div>
                ) : null}
                <UserPlaceholder miniature={true} className="chat-participants-list__user-icon" />
                <span className="text-transform--none chat-participants-list__user-label">
                  {p.name}
                </span>
              </div>
            }
            fullWidth={false}
            className="chat-participants-list__user-container"
          />
        </Tooltip>
      ))}
    </Tabs>
  );
};

export default memo(ChatParticipantList);
