import { Divider, Grid, List, ListItem, ListItemText } from '@material-ui/core';
import { FC, memo } from 'react';
import { VisionApiEntityAnnotation } from '../../shared/core/models/vision-api/entities/annotations/entity';
import './ObjectDetectionContainer.scss';
import ObjectDetectionHeader from './ObjectDetectionHeader';

type IObjectDetectionContainerProps = {
  onExit: () => void;
  labels: Array<VisionApiEntityAnnotation>;
};

const ObjectDetectionContainer: FC<IObjectDetectionContainerProps> = ({
  onExit,
  labels
}): JSX.Element => {
  return (
    <Grid
      container
      spacing={1}
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      wrap="nowrap"
      className="object-detection__container">
      <Grid item>
        <ObjectDetectionHeader onExit={onExit} />
      </Grid>
      <Grid item>
        <Divider />
      </Grid>
      <Grid item style={{ flexGrow: 2 }}>
        <List className="object-detection__list">
          {labels.map((l) => (
            <div key={l.mid}>
              <ListItem component="div">
                <ListItemText primary={l.description} />
              </ListItem>
              <Divider />
            </div>
          ))}
        </List>
      </Grid>
    </Grid>
  );
};

export default memo(ObjectDetectionContainer);
