import { RootState } from '../store';
import { SnackbarState } from './snackbarReducer';

export const getSnackbarState = (store: RootState): SnackbarState => store.snackbar;

/**
 * Select all timer
 *
 * @param state Store state
 * @returns List of timer {@link IParticipant}
 */
export const selectSnackbar = (state: RootState): string => getSnackbarState(state).all;
