import { RootState } from '../store';
import { IOrganization, OrganizationState } from './organizationReducer';

export const getOrganizationsState = (store: RootState): OrganizationState => store.organizations;

/**
 * Select all organization
 *
 * @param state Store state
 * @returns List of organizations {@link IOrganization}
 */
export const selectAllOrganizations = (state: RootState): IOrganization[] =>
  getOrganizationsState(state).all;

export const selectSelectedOrganization = (state: RootState): string =>
  getOrganizationsState(state).idSelectedNav;
