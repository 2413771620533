import axios, { AxiosError } from 'axios';

export type ResponseWrapper<T> = {
  data: T;
  status: 'SUCCESS' | 'ERROR';
};

export type RequestStatus<T> = {
  pending: boolean;
  error: Error | null;
  result: T;
};

export enum ErrorType {
  GET = 'get',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete'
}

export enum ErrorCode {
  NOT_AUTORIZED_401 = '401',
  NO_VALID_DATA_400 = '400',
  NO_POSSIBLE_OPERATION_403 = '403',
  RESOURCE_NOT_FOUND_404 = '404',
  RESOURCE_ALREADY_EXIST_409 = '409'
}

export const initialRequestStatus: RequestStatus<unknown> = {
  pending: false,
  error: null,
  result: null
};
export const initialMultipleRequestStatus: RequestStatus<unknown> = {
  pending: false,
  error: null,
  result: []
};

export function handleErrorAPI<T>(error: Error | AxiosError<T>, res: string): string {
  if (axios.isAxiosError(error)) {
    switch (error.code) {
      case ErrorCode.NOT_AUTORIZED_401.valueOf():
        return 'Not authorized';
      case ErrorCode.NO_VALID_DATA_400.valueOf():
        return `${res} not valid`;
      case ErrorCode.NO_POSSIBLE_OPERATION_403.valueOf():
        return `Access denied`;
      case ErrorCode.RESOURCE_NOT_FOUND_404.valueOf():
        return 'Resource not found';
      case ErrorCode.RESOURCE_ALREADY_EXIST_409.valueOf():
        return 'Resource already exist';
      default:
        return 'Generic error';
    }
  } else {
    return 'Generic error';
  }
}
